/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanBaseResponse } from '../models/boolean-base-response';
import { HolidayDto } from '../models/holiday-dto';
import { HolidayDtoBaseResponse } from '../models/holiday-dto-base-response';
import { HolidayDtoListBaseResponse } from '../models/holiday-dto-list-base-response';
import { LeaveType } from '../models/leave-type';

@Injectable({
  providedIn: 'root',
})
export class HolidayService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiHolidayHolidaysGet
   */
  static readonly ApiHolidayHolidaysGetPath = '/api/Holiday/holidays';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHolidayHolidaysGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHolidayHolidaysGet$Plain$Response(params?: {
    userId?: number;

    /**
     * 0 &#x3D; Holiday
     * 
     * 1 &#x3D; Sickness
     * 
     * 2 &#x3D; BankHoliday
     * 
     * 3 &#x3D; Other
     */
    leaveType?: LeaveType;
  }): Observable<StrictHttpResponse<HolidayDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, HolidayService.ApiHolidayHolidaysGetPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
      rb.query('leaveType', params.leaveType, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HolidayDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiHolidayHolidaysGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHolidayHolidaysGet$Plain(params?: {
    userId?: number;

    /**
     * 0 &#x3D; Holiday
     * 
     * 1 &#x3D; Sickness
     * 
     * 2 &#x3D; BankHoliday
     * 
     * 3 &#x3D; Other
     */
    leaveType?: LeaveType;
  }): Observable<HolidayDtoListBaseResponse> {

    return this.apiHolidayHolidaysGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<HolidayDtoListBaseResponse>) => r.body as HolidayDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHolidayHolidaysGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHolidayHolidaysGet$Json$Response(params?: {
    userId?: number;

    /**
     * 0 &#x3D; Holiday
     * 
     * 1 &#x3D; Sickness
     * 
     * 2 &#x3D; BankHoliday
     * 
     * 3 &#x3D; Other
     */
    leaveType?: LeaveType;
  }): Observable<StrictHttpResponse<HolidayDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, HolidayService.ApiHolidayHolidaysGetPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
      rb.query('leaveType', params.leaveType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HolidayDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiHolidayHolidaysGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHolidayHolidaysGet$Json(params?: {
    userId?: number;

    /**
     * 0 &#x3D; Holiday
     * 
     * 1 &#x3D; Sickness
     * 
     * 2 &#x3D; BankHoliday
     * 
     * 3 &#x3D; Other
     */
    leaveType?: LeaveType;
  }): Observable<HolidayDtoListBaseResponse> {

    return this.apiHolidayHolidaysGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<HolidayDtoListBaseResponse>) => r.body as HolidayDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiHolidayHolidayGet
   */
  static readonly ApiHolidayHolidayGetPath = '/api/Holiday/holiday';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHolidayHolidayGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHolidayHolidayGet$Plain$Response(params?: {
    holidayId?: number;
  }): Observable<StrictHttpResponse<HolidayDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, HolidayService.ApiHolidayHolidayGetPath, 'get');
    if (params) {
      rb.query('holidayId', params.holidayId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HolidayDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiHolidayHolidayGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHolidayHolidayGet$Plain(params?: {
    holidayId?: number;
  }): Observable<HolidayDtoBaseResponse> {

    return this.apiHolidayHolidayGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<HolidayDtoBaseResponse>) => r.body as HolidayDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHolidayHolidayGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHolidayHolidayGet$Json$Response(params?: {
    holidayId?: number;
  }): Observable<StrictHttpResponse<HolidayDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, HolidayService.ApiHolidayHolidayGetPath, 'get');
    if (params) {
      rb.query('holidayId', params.holidayId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HolidayDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiHolidayHolidayGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHolidayHolidayGet$Json(params?: {
    holidayId?: number;
  }): Observable<HolidayDtoBaseResponse> {

    return this.apiHolidayHolidayGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<HolidayDtoBaseResponse>) => r.body as HolidayDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiHolidayCreateHolidayPost
   */
  static readonly ApiHolidayCreateHolidayPostPath = '/api/Holiday/create-holiday';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHolidayCreateHolidayPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHolidayCreateHolidayPost$Plain$Response(params?: {
    body?: HolidayDto
  }): Observable<StrictHttpResponse<HolidayDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, HolidayService.ApiHolidayCreateHolidayPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HolidayDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiHolidayCreateHolidayPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHolidayCreateHolidayPost$Plain(params?: {
    body?: HolidayDto
  }): Observable<HolidayDtoBaseResponse> {

    return this.apiHolidayCreateHolidayPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<HolidayDtoBaseResponse>) => r.body as HolidayDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHolidayCreateHolidayPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHolidayCreateHolidayPost$Json$Response(params?: {
    body?: HolidayDto
  }): Observable<StrictHttpResponse<HolidayDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, HolidayService.ApiHolidayCreateHolidayPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HolidayDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiHolidayCreateHolidayPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHolidayCreateHolidayPost$Json(params?: {
    body?: HolidayDto
  }): Observable<HolidayDtoBaseResponse> {

    return this.apiHolidayCreateHolidayPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<HolidayDtoBaseResponse>) => r.body as HolidayDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiHolidayUpdateHolidayPut
   */
  static readonly ApiHolidayUpdateHolidayPutPath = '/api/Holiday/update-holiday';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHolidayUpdateHolidayPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHolidayUpdateHolidayPut$Plain$Response(params?: {
    body?: HolidayDto
  }): Observable<StrictHttpResponse<HolidayDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, HolidayService.ApiHolidayUpdateHolidayPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HolidayDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiHolidayUpdateHolidayPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHolidayUpdateHolidayPut$Plain(params?: {
    body?: HolidayDto
  }): Observable<HolidayDtoBaseResponse> {

    return this.apiHolidayUpdateHolidayPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<HolidayDtoBaseResponse>) => r.body as HolidayDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHolidayUpdateHolidayPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHolidayUpdateHolidayPut$Json$Response(params?: {
    body?: HolidayDto
  }): Observable<StrictHttpResponse<HolidayDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, HolidayService.ApiHolidayUpdateHolidayPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HolidayDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiHolidayUpdateHolidayPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHolidayUpdateHolidayPut$Json(params?: {
    body?: HolidayDto
  }): Observable<HolidayDtoBaseResponse> {

    return this.apiHolidayUpdateHolidayPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<HolidayDtoBaseResponse>) => r.body as HolidayDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiHolidayUpdateHolidaysForUserPut
   */
  static readonly ApiHolidayUpdateHolidaysForUserPutPath = '/api/Holiday/update-holidays-for-user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHolidayUpdateHolidaysForUserPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHolidayUpdateHolidaysForUserPut$Plain$Response(params?: {
    body?: Array<HolidayDto>
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, HolidayService.ApiHolidayUpdateHolidaysForUserPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiHolidayUpdateHolidaysForUserPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHolidayUpdateHolidaysForUserPut$Plain(params?: {
    body?: Array<HolidayDto>
  }): Observable<BooleanBaseResponse> {

    return this.apiHolidayUpdateHolidaysForUserPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHolidayUpdateHolidaysForUserPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHolidayUpdateHolidaysForUserPut$Json$Response(params?: {
    body?: Array<HolidayDto>
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, HolidayService.ApiHolidayUpdateHolidaysForUserPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiHolidayUpdateHolidaysForUserPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHolidayUpdateHolidaysForUserPut$Json(params?: {
    body?: Array<HolidayDto>
  }): Observable<BooleanBaseResponse> {

    return this.apiHolidayUpdateHolidaysForUserPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiHolidayArchiveHolidayDelete
   */
  static readonly ApiHolidayArchiveHolidayDeletePath = '/api/Holiday/archive-holiday';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHolidayArchiveHolidayDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHolidayArchiveHolidayDelete$Plain$Response(params?: {
    holidayId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, HolidayService.ApiHolidayArchiveHolidayDeletePath, 'delete');
    if (params) {
      rb.query('holidayId', params.holidayId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiHolidayArchiveHolidayDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHolidayArchiveHolidayDelete$Plain(params?: {
    holidayId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiHolidayArchiveHolidayDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHolidayArchiveHolidayDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHolidayArchiveHolidayDelete$Json$Response(params?: {
    holidayId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, HolidayService.ApiHolidayArchiveHolidayDeletePath, 'delete');
    if (params) {
      rb.query('holidayId', params.holidayId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiHolidayArchiveHolidayDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHolidayArchiveHolidayDelete$Json(params?: {
    holidayId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiHolidayArchiveHolidayDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

}
