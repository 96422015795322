import { NgIf } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { FuseFullscreenComponent } from '@fuse/components/fullscreen';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { UserImageService } from 'app/api/services';
import { JwtAuthService } from 'app/api/services/cookie-jwt-auth/jwt-auth.service';
import { NavigationService } from 'app/core/services/navigation/navigation.service';
import { Navigation } from 'app/core/services/navigation/navigation.types';
import { ThemeService } from 'app/core/services/theme/theme.service';
import { UserService } from 'app/core/services/user/user.service';
import { User } from 'app/core/services/user/user.types';
import { LanguagesComponent } from 'app/layout/common/languages/languages.component';
import { MessagesComponent } from 'app/layout/common/messages/messages.component';
import { NotificationsComponent } from 'app/layout/common/notifications/notifications.component';
import { QuickChatComponent } from 'app/layout/common/quick-chat/quick-chat.component';
import { SearchComponent } from 'app/layout/common/search/search.component';
import { ShortcutsComponent } from 'app/layout/common/shortcuts/shortcuts.component';
import { UserComponent } from 'app/layout/common/user/user.component';
import { environment } from 'environments/environment';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector     : 'classy-layout',
    templateUrl  : './classy.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone   : true,
    styles       : [`
        .fuse-vertical-navigation-item-title span {white-space: nowrap;}
        .fix-top-margin {margin-top: 0 !important;}
    `],
    imports      : [FuseLoadingBarComponent, FuseVerticalNavigationComponent, NotificationsComponent, UserComponent, NgIf, MatIconModule, MatButtonModule, LanguagesComponent, FuseFullscreenComponent, SearchComponent, ShortcutsComponent, MessagesComponent, RouterOutlet, QuickChatComponent],
})
export class ClassyLayoutComponent implements OnInit, OnDestroy, AfterViewInit
{
    user: User;
    public currentImageId: number;
    public avatarImageUrl: string;

    env = environment;
    isScreenSmall: boolean;
    isBasic: boolean = true;
    navigation: Navigation;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    navigationAppearance: 'default' | 'dense' = 'dense';
    isLocked: boolean = false;
    lockIcon: 'mat_solid:lock_open' | 'mat_solid:lock' = 'mat_solid:lock_open';

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _userService: UserService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private jwtAuth: JwtAuthService,
        public themeService: ThemeService,
        private userImageService: UserImageService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number
    {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) =>
            {
                this.navigation = navigation;
            });

        this.user = this.jwtAuth.getUser();
        this.isBasic = this.jwtAuth.isBasic();

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) =>
            {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });

            this.getAvatar();
    }

    getAvatar(){
        var userId = parseInt(this.user.id);

        this.userImageService.apiUserImageUserPhotosGet$Json({ userId: userId }).subscribe(res => {
          if(!res?.data) return;
    
          var list = res?.data;
          this.currentImageId = list[0]?.userImageId;
    
          if(!this.currentImageId) return;
    
          this.userImageService.apiUserImageGetImageUserImageIdGet({userImageId: this.currentImageId}).subscribe(image => {
            if(!image) return;
            
            const reader = new FileReader();
            reader.onloadend = () => {
              this.avatarImageUrl = reader.result as string;
            };
            reader.readAsDataURL(new Blob([image]));
          })
        })
      }

    ngAfterViewInit(): void {
        const mainNavigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');
        mainNavigation.hoverChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(open => {
                // console.log("nav bar hover", open ? 'open' : 'closed');
                if(!this.isLocked)
                    this.switchDenseNav(!open);
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(): void
    {
        if(!this.isScreenSmall) {
            this.switchLocked(!this.isLocked);
            return;
        }

        // Get the navigation
        const mainNavigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');

        if ( mainNavigation )
        {
            // Toggle the opened status
            this.switchDenseNav(false);
            this.switchLocked(true);
            mainNavigation.toggle();
        }
    }

    switchDenseNav(dense: boolean = false) {
        this.navigationAppearance = dense ? 'dense' : 'default';
    }

    switchLocked(locked: boolean) {
        this.isLocked = locked;
        this.lockIcon = locked ? 'mat_solid:lock' : 'mat_solid:lock_open';
    }
}
