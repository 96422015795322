/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanBaseResponse } from '../models/boolean-base-response';
import { OrderDirection } from '../models/order-direction';
import { ProductCategoryDto } from '../models/product-category-dto';
import { ProductCategoryDtoBaseResponse } from '../models/product-category-dto-base-response';
import { ProductCategoryDtoListBaseResponse } from '../models/product-category-dto-list-base-response';
import { ProductDto } from '../models/product-dto';
import { ProductDtoBaseResponse } from '../models/product-dto-base-response';
import { ProductDtoListBaseResponse } from '../models/product-dto-list-base-response';
import { ProductDtoPagedResponseBaseResponse } from '../models/product-dto-paged-response-base-response';
import { PropertyFilter } from '../models/property-filter';

@Injectable({
  providedIn: 'root',
})
export class ProductService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiProductPaginatedListGet
   */
  static readonly ApiProductPaginatedListGetPath = '/api/Product/paginated-list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductPaginatedListGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductPaginatedListGet$Plain$Response(params?: {
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<ProductDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductPaginatedListGetPath, 'get');
    if (params) {
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('GetAll', params.GetAll, {});
      rb.query('Archived', params.Archived, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('PropertyFilters', params.PropertyFilters, {});
      rb.query('OrderProperty', params.OrderProperty, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductPaginatedListGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductPaginatedListGet$Plain(params?: {
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<ProductDtoPagedResponseBaseResponse> {

    return this.apiProductPaginatedListGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProductDtoPagedResponseBaseResponse>) => r.body as ProductDtoPagedResponseBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductPaginatedListGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductPaginatedListGet$Json$Response(params?: {
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<ProductDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductPaginatedListGetPath, 'get');
    if (params) {
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('GetAll', params.GetAll, {});
      rb.query('Archived', params.Archived, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('PropertyFilters', params.PropertyFilters, {});
      rb.query('OrderProperty', params.OrderProperty, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductPaginatedListGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductPaginatedListGet$Json(params?: {
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<ProductDtoPagedResponseBaseResponse> {

    return this.apiProductPaginatedListGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProductDtoPagedResponseBaseResponse>) => r.body as ProductDtoPagedResponseBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductGetAllGet
   */
  static readonly ApiProductGetAllGetPath = '/api/Product/get-all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductGetAllGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetAllGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<ProductDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductGetAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductGetAllGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetAllGet$Plain(params?: {
  }): Observable<ProductDtoListBaseResponse> {

    return this.apiProductGetAllGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProductDtoListBaseResponse>) => r.body as ProductDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductGetAllGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetAllGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<ProductDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductGetAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductGetAllGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetAllGet$Json(params?: {
  }): Observable<ProductDtoListBaseResponse> {

    return this.apiProductGetAllGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProductDtoListBaseResponse>) => r.body as ProductDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductSearchAllGet
   */
  static readonly ApiProductSearchAllGetPath = '/api/Product/search-all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductSearchAllGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductSearchAllGet$Plain$Response(params?: {
    searchTerm?: string;
    archived?: boolean;
  }): Observable<StrictHttpResponse<ProductDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductSearchAllGetPath, 'get');
    if (params) {
      rb.query('searchTerm', params.searchTerm, {});
      rb.query('archived', params.archived, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductSearchAllGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductSearchAllGet$Plain(params?: {
    searchTerm?: string;
    archived?: boolean;
  }): Observable<ProductDtoListBaseResponse> {

    return this.apiProductSearchAllGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProductDtoListBaseResponse>) => r.body as ProductDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductSearchAllGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductSearchAllGet$Json$Response(params?: {
    searchTerm?: string;
    archived?: boolean;
  }): Observable<StrictHttpResponse<ProductDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductSearchAllGetPath, 'get');
    if (params) {
      rb.query('searchTerm', params.searchTerm, {});
      rb.query('archived', params.archived, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductSearchAllGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductSearchAllGet$Json(params?: {
    searchTerm?: string;
    archived?: boolean;
  }): Observable<ProductDtoListBaseResponse> {

    return this.apiProductSearchAllGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProductDtoListBaseResponse>) => r.body as ProductDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductGetAllByCategoryGet
   */
  static readonly ApiProductGetAllByCategoryGetPath = '/api/Product/get-all-by-category';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductGetAllByCategoryGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetAllByCategoryGet$Plain$Response(params?: {
    category?: string;
  }): Observable<StrictHttpResponse<ProductDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductGetAllByCategoryGetPath, 'get');
    if (params) {
      rb.query('category', params.category, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductGetAllByCategoryGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetAllByCategoryGet$Plain(params?: {
    category?: string;
  }): Observable<ProductDtoListBaseResponse> {

    return this.apiProductGetAllByCategoryGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProductDtoListBaseResponse>) => r.body as ProductDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductGetAllByCategoryGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetAllByCategoryGet$Json$Response(params?: {
    category?: string;
  }): Observable<StrictHttpResponse<ProductDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductGetAllByCategoryGetPath, 'get');
    if (params) {
      rb.query('category', params.category, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductGetAllByCategoryGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetAllByCategoryGet$Json(params?: {
    category?: string;
  }): Observable<ProductDtoListBaseResponse> {

    return this.apiProductGetAllByCategoryGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProductDtoListBaseResponse>) => r.body as ProductDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductGetAllAdditionalGet
   */
  static readonly ApiProductGetAllAdditionalGetPath = '/api/Product/get-all-additional';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductGetAllAdditionalGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetAllAdditionalGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<ProductDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductGetAllAdditionalGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductGetAllAdditionalGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetAllAdditionalGet$Plain(params?: {
  }): Observable<ProductDtoListBaseResponse> {

    return this.apiProductGetAllAdditionalGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProductDtoListBaseResponse>) => r.body as ProductDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductGetAllAdditionalGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetAllAdditionalGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<ProductDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductGetAllAdditionalGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductGetAllAdditionalGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetAllAdditionalGet$Json(params?: {
  }): Observable<ProductDtoListBaseResponse> {

    return this.apiProductGetAllAdditionalGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProductDtoListBaseResponse>) => r.body as ProductDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductGetProductGet
   */
  static readonly ApiProductGetProductGetPath = '/api/Product/get-product';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductGetProductGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetProductGet$Plain$Response(params?: {
    productId?: number;
  }): Observable<StrictHttpResponse<ProductDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductGetProductGetPath, 'get');
    if (params) {
      rb.query('productId', params.productId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductGetProductGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetProductGet$Plain(params?: {
    productId?: number;
  }): Observable<ProductDtoBaseResponse> {

    return this.apiProductGetProductGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProductDtoBaseResponse>) => r.body as ProductDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductGetProductGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetProductGet$Json$Response(params?: {
    productId?: number;
  }): Observable<StrictHttpResponse<ProductDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductGetProductGetPath, 'get');
    if (params) {
      rb.query('productId', params.productId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductGetProductGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetProductGet$Json(params?: {
    productId?: number;
  }): Observable<ProductDtoBaseResponse> {

    return this.apiProductGetProductGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProductDtoBaseResponse>) => r.body as ProductDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductAddProductPost
   */
  static readonly ApiProductAddProductPostPath = '/api/Product/add-product';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductAddProductPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductAddProductPost$Plain$Response(params?: {
    body?: ProductDto
  }): Observable<StrictHttpResponse<ProductDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductAddProductPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductAddProductPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductAddProductPost$Plain(params?: {
    body?: ProductDto
  }): Observable<ProductDtoBaseResponse> {

    return this.apiProductAddProductPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProductDtoBaseResponse>) => r.body as ProductDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductAddProductPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductAddProductPost$Json$Response(params?: {
    body?: ProductDto
  }): Observable<StrictHttpResponse<ProductDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductAddProductPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductAddProductPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductAddProductPost$Json(params?: {
    body?: ProductDto
  }): Observable<ProductDtoBaseResponse> {

    return this.apiProductAddProductPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProductDtoBaseResponse>) => r.body as ProductDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductUpdateProductPut
   */
  static readonly ApiProductUpdateProductPutPath = '/api/Product/update-product';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductUpdateProductPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateProductPut$Plain$Response(params?: {
    body?: ProductDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductUpdateProductPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductUpdateProductPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateProductPut$Plain(params?: {
    body?: ProductDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProductUpdateProductPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductUpdateProductPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateProductPut$Json$Response(params?: {
    body?: ProductDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductUpdateProductPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductUpdateProductPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateProductPut$Json(params?: {
    body?: ProductDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProductUpdateProductPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductToggleArchiveProductPut
   */
  static readonly ApiProductToggleArchiveProductPutPath = '/api/Product/toggle-archive-product';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductToggleArchiveProductPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductToggleArchiveProductPut$Plain$Response(params?: {
    productId?: number;
    isArchive?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductToggleArchiveProductPutPath, 'put');
    if (params) {
      rb.query('productId', params.productId, {});
      rb.query('isArchive', params.isArchive, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductToggleArchiveProductPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductToggleArchiveProductPut$Plain(params?: {
    productId?: number;
    isArchive?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiProductToggleArchiveProductPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductToggleArchiveProductPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductToggleArchiveProductPut$Json$Response(params?: {
    productId?: number;
    isArchive?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductToggleArchiveProductPutPath, 'put');
    if (params) {
      rb.query('productId', params.productId, {});
      rb.query('isArchive', params.isArchive, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductToggleArchiveProductPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductToggleArchiveProductPut$Json(params?: {
    productId?: number;
    isArchive?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiProductToggleArchiveProductPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductAllCategoriesGet
   */
  static readonly ApiProductAllCategoriesGetPath = '/api/Product/all-categories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductAllCategoriesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductAllCategoriesGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<ProductCategoryDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductAllCategoriesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductCategoryDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductAllCategoriesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductAllCategoriesGet$Plain(params?: {
  }): Observable<ProductCategoryDtoListBaseResponse> {

    return this.apiProductAllCategoriesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProductCategoryDtoListBaseResponse>) => r.body as ProductCategoryDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductAllCategoriesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductAllCategoriesGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<ProductCategoryDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductAllCategoriesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductCategoryDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductAllCategoriesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductAllCategoriesGet$Json(params?: {
  }): Observable<ProductCategoryDtoListBaseResponse> {

    return this.apiProductAllCategoriesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProductCategoryDtoListBaseResponse>) => r.body as ProductCategoryDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductGetCategoryGet
   */
  static readonly ApiProductGetCategoryGetPath = '/api/Product/get-category';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductGetCategoryGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetCategoryGet$Plain$Response(params?: {
    productCategoryId?: number;
  }): Observable<StrictHttpResponse<ProductCategoryDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductGetCategoryGetPath, 'get');
    if (params) {
      rb.query('productCategoryId', params.productCategoryId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductCategoryDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductGetCategoryGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetCategoryGet$Plain(params?: {
    productCategoryId?: number;
  }): Observable<ProductCategoryDtoBaseResponse> {

    return this.apiProductGetCategoryGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProductCategoryDtoBaseResponse>) => r.body as ProductCategoryDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductGetCategoryGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetCategoryGet$Json$Response(params?: {
    productCategoryId?: number;
  }): Observable<StrictHttpResponse<ProductCategoryDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductGetCategoryGetPath, 'get');
    if (params) {
      rb.query('productCategoryId', params.productCategoryId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductCategoryDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductGetCategoryGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGetCategoryGet$Json(params?: {
    productCategoryId?: number;
  }): Observable<ProductCategoryDtoBaseResponse> {

    return this.apiProductGetCategoryGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProductCategoryDtoBaseResponse>) => r.body as ProductCategoryDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductAddCategoryPost
   */
  static readonly ApiProductAddCategoryPostPath = '/api/Product/add-category';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductAddCategoryPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductAddCategoryPost$Plain$Response(params?: {
    body?: ProductCategoryDto
  }): Observable<StrictHttpResponse<ProductCategoryDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductAddCategoryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductCategoryDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductAddCategoryPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductAddCategoryPost$Plain(params?: {
    body?: ProductCategoryDto
  }): Observable<ProductCategoryDtoBaseResponse> {

    return this.apiProductAddCategoryPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProductCategoryDtoBaseResponse>) => r.body as ProductCategoryDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductAddCategoryPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductAddCategoryPost$Json$Response(params?: {
    body?: ProductCategoryDto
  }): Observable<StrictHttpResponse<ProductCategoryDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductAddCategoryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductCategoryDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductAddCategoryPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductAddCategoryPost$Json(params?: {
    body?: ProductCategoryDto
  }): Observable<ProductCategoryDtoBaseResponse> {

    return this.apiProductAddCategoryPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProductCategoryDtoBaseResponse>) => r.body as ProductCategoryDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductUpdateCategoryPut
   */
  static readonly ApiProductUpdateCategoryPutPath = '/api/Product/update-category';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductUpdateCategoryPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateCategoryPut$Plain$Response(params?: {
    body?: ProductCategoryDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductUpdateCategoryPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductUpdateCategoryPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateCategoryPut$Plain(params?: {
    body?: ProductCategoryDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProductUpdateCategoryPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductUpdateCategoryPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateCategoryPut$Json$Response(params?: {
    body?: ProductCategoryDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductUpdateCategoryPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductUpdateCategoryPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUpdateCategoryPut$Json(params?: {
    body?: ProductCategoryDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProductUpdateCategoryPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiProductArchiveCategoryPut
   */
  static readonly ApiProductArchiveCategoryPutPath = '/api/Product/archive-category';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductArchiveCategoryPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductArchiveCategoryPut$Plain$Response(params?: {
    productCategoryId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductArchiveCategoryPutPath, 'put');
    if (params) {
      rb.query('productCategoryId', params.productCategoryId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductArchiveCategoryPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductArchiveCategoryPut$Plain(params?: {
    productCategoryId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiProductArchiveCategoryPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductArchiveCategoryPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductArchiveCategoryPut$Json$Response(params?: {
    productCategoryId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProductService.ApiProductArchiveCategoryPutPath, 'put');
    if (params) {
      rb.query('productCategoryId', params.productCategoryId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProductArchiveCategoryPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductArchiveCategoryPut$Json(params?: {
    productCategoryId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiProductArchiveCategoryPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

}
