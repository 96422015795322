import { ResourceDto, StaffRateDto, UserDto, UserListDto, UsersByStaffRateDto } from "app/api/models";

export abstract class ProjectHelper {
    staffRates: StaffRateDto[] = [];
    usersByRole: UsersByStaffRateDto[] = [];
    allUsers: UserDto[] = [];
    costResources: ResourceDto[] = [];

    isUrl(url: string) {
        if(!url) return false;

        url = url.trim();

        // console.log(url, url.startsWith("http") || url.startsWith("www.") ? "is" : "isn't", "a url");
        return url.startsWith("http") || url.startsWith("www.") || (!url.includes(' ') && url.includes('.'));
    }

    GetRoleTitle(staffRateId: number) {
        return this.staffRates?.find(x => x.staffRateId == staffRateId)?.jobTitle ?? '';
    }

    GetRoleUsers(staffRateId: number): UserListDto[] {
        return this.usersByRole?.find(x => x.staffRateId == staffRateId)?.users;
    }
    
    GetUserName(userId: number) {
        if(!this.usersByRole) return '';

        for(var role of this.usersByRole)
            for(var user of role.users)
                if(user.userId == userId) return user.name;

        for(var u of this.allUsers)
            if(u.id == userId) return `${u.firstName} ${u.lastName}`;

        return '';
    }

    GetResourceName(resourceId: number) {
        return this.costResources?.find(x => x.resourceId == resourceId)?.name ?? '';
        // if(!this.resources) return '';

        // for(var resource of this.resources)
        //     if(resource.resourceId == resourceId) return resource.name;

        // return '';
    }
    
    GetResourceJobTitle(resourceId: number) {
        return this.costResources?.find(x => x.resourceId == resourceId)?.jobTitle ?? '';
        // if(!this.resources) return '';

        // for(var resource of this.resources)
        //     if(resource.resourceId == resourceId) return resource.jobTitle;

        // return '';
    }
}
