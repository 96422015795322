/* tslint:disable */
/* eslint-disable */

/**
 * 0 = CompanyLogo
 * 
 * 1 = LoginLogo
 * 
 * 2 = CoverLogo
 * 
 * 3 = CoverBackground
 */
export enum AppThemeImageType {
  CompanyLogo = 0,
  LoginLogo = 1,
  CoverLogo = 2,
  CoverBackground = 3
}
