export const config = {
  apiUrl: "https://localhost:5001",
  // apiUrl: "https://elevated-demo-api.azurewebsites.net",
  devApiUrl: "https://elevated-demo-api.azurewebsites.net",
  prodApiUrl: "https://elevated-demo-api.azurewebsites.net",
  fantasyfloorsApiUrl: "https://fantasyfloors-demo-api.azurewebsites.net",
  nexusApiUrl: "https://nexus-demo-api.azurewebsites.net",
  showcaseApiUrl: "https://showcase-demo-api.azurewebsites.net",
  imagerepublicApiUrl: "https://imagerepublic-demo-api.azurewebsites.net",
  diamondworxApiUrl: "https://diamondworx-demo-api.azurewebsites.net",
  hitechApiUrl: "https://hitech-demo-api.azurewebsites.net",
  hyperionApiUrl: "https://hyperion-demo-api.azurewebsites.net",
  authRoles: {
    sa: ["SA"], // Only Super Admin has access
    admin: ["SA", "Admin"], // Only SA & Admin has access
    editor: ["SA", "Admin", "Editor"], // Only SA & Admin & Editor has access
    user: ["SA", "Admin", "Editor", "User"], // Only SA & Admin & Editor & User has access
    guest: ["SA", "Admin", "Editor", "User", "Guest"], // Everyone has access
  },
  syncFusionLicense: "Mgo+DSMBPh8sVXJ3S0d+X1dPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXdRckdiXHtaeXRUQGM=;Mgo+DSMBMAY9C3t2VlhhQlJCfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5adEFhWHpadH1VRGlf;MjcxOTk0NkAzMjMzMmUzMDJlMzBld1BGbnpoKzFrSEZNUmJYSzJ2cHo3UU9YNTBiTnVXN3lkNXFhRWlhNVlrPQ=="
};
