import { inject } from "@angular/core";
import { Router, CanActivateFn, CanActivateChildFn } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SettingsService } from "../../settings.service";
import { of, switchMap } from "rxjs";
import { Feature } from "app/api/models";

export const FeatureGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
{
    const router: Router = inject(Router);
    const snack: MatSnackBar = inject(MatSnackBar);

    var accessDenied = () => 
      snack.open("You do not have access to this page!", "OK", {
        duration: 4000,
      });

    var getFeaturePath = (feature: Feature) => {
      switch(feature) {
        case Feature.CRM_Contacts: return '/crm/contacts';
        case Feature.CRM_Leads: return '/crm/leads';
        case Feature.CRM_Pipeline: return '/crm/pipeline';
        case Feature.CRM_Emails: return '/crm/emails';
        case Feature.CRM_Reporting: return '/crm/reporting';

        case Feature.PM_Dashboard: return '/project-management/dashboard';
        case Feature.PM_Projects: return '/project-management/projects';
        case Feature.PM_Workload: return '/project-management/workload';
        case Feature.PM_Invoices: return '/project-management/invoicing';
        case Feature.PM_PurchaseOrders: return '/project-management/purchase-orders';
        case Feature.PM_Reporting: return '/project-management/reporting';

        case Feature.ERP_Contacts: return '/erp/contacts';
        case Feature.ERP_Products: return '/erp/products';
        case Feature.ERP_Sales: return '/erp/sales';
        case Feature.ERP_HR: return '/erp/hr';
        case Feature.ERP_Purchases: return '/erp/purchases';
        case Feature.ERP_Invoicing: return '/erp/invoicing';
        case Feature.ERP_GRN: return '/erp/grn';
        case Feature.ERP_Production: return '/erp/production';
        case Feature.ERP_Leads: return '/erp/leads';
        case Feature.ERP_Pipeline: return '/erp/pipeline';
        case Feature.ERP_Suppliers: return '/erp/suppliers';
        case Feature.ERP_Reporting: return '/erp/reports';

        case Feature.Inst_Contacts: return '/installation/contacts';
        case Feature.Inst_Products: return '/installation/products';
        case Feature.Inst_Suppliers: return '/installation/suppliers';
        case Feature.Inst_PurchaseOrders: return '/installation/purchase-orders';
        case Feature.Inst_Invoicing: return '/installation/invoicing';
        case Feature.Inst_Calendar: return '/installation/calendar';
        case Feature.Inst_Documentations: return '/installation/documentations';

        case Feature.ECom_Inventory: return '/ecommerce/inventory';
        case Feature.ECom_Stock: return '/ecommerce/stock';
        case Feature.ECom_History: return '/ecommerce/sales-orders';

        case Feature.Misc_Calendar: return '/apps/calendar';
        case Feature.Misc_Mailbox: return '/apps/mailbox';
        case Feature.Misc_Messaging: return '/apps/messenger';
        case Feature.Misc_CoursesAndTraining: return '/apps/courses';
        case Feature.Misc_TaskList: return '/apps/tasks';
        case Feature.Misc_FileManagement: return '/apps/files';

        case Feature.Form_Quotation: return '/quotation-form';

        default: return 'invalid';
      }
    };

    // Check the authentication status
    return inject(SettingsService).apiSettingsAllowedFeaturesGet$Json$Response().pipe(
        switchMap((res) =>
        {
            var featureList = res?.body?.data;
            // console.log(featureList);

            var allowedPaths = featureList.map(x => getFeaturePath(x));
            // console.log(allowedPaths);
            // console.log(allowedPaths.filter(x => state.url.startsWith(x)))

            if(!featureList || allowedPaths.filter(x => state.url.startsWith(x)).length == 0) {
              accessDenied();

              const urlTree = router.parseUrl(`sign-in`);
              return of(urlTree);
            }

            // Allow the access
            return of(true);
        }),
    );
}
