/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AppThemeImageType } from '../models/app-theme-image-type';
import { ApplicationFeatureDto } from '../models/application-feature-dto';
import { ApplicationInfoDto } from '../models/application-info-dto';
import { ApplicationInfoDtoBaseResponse } from '../models/application-info-dto-base-response';
import { ApplicationThemeDto } from '../models/application-theme-dto';
import { ApplicationThemeDtoBaseResponse } from '../models/application-theme-dto-base-response';
import { BooleanBaseResponse } from '../models/boolean-base-response';
import { FeatureListBaseResponse } from '../models/feature-list-base-response';

@Injectable({
  providedIn: 'root',
})
export class SettingsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiSettingsAllowedFeaturesGet
   */
  static readonly ApiSettingsAllowedFeaturesGetPath = '/api/Settings/allowed-features';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSettingsAllowedFeaturesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSettingsAllowedFeaturesGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<FeatureListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SettingsService.ApiSettingsAllowedFeaturesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FeatureListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSettingsAllowedFeaturesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSettingsAllowedFeaturesGet$Plain(params?: {
  }): Observable<FeatureListBaseResponse> {

    return this.apiSettingsAllowedFeaturesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<FeatureListBaseResponse>) => r.body as FeatureListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSettingsAllowedFeaturesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSettingsAllowedFeaturesGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<FeatureListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SettingsService.ApiSettingsAllowedFeaturesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FeatureListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSettingsAllowedFeaturesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSettingsAllowedFeaturesGet$Json(params?: {
  }): Observable<FeatureListBaseResponse> {

    return this.apiSettingsAllowedFeaturesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<FeatureListBaseResponse>) => r.body as FeatureListBaseResponse)
    );
  }

  /**
   * Path part for operation apiSettingsToggleFeaturePut
   */
  static readonly ApiSettingsToggleFeaturePutPath = '/api/Settings/toggle-feature';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSettingsToggleFeaturePut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSettingsToggleFeaturePut$Plain$Response(params?: {
    body?: ApplicationFeatureDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SettingsService.ApiSettingsToggleFeaturePutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSettingsToggleFeaturePut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSettingsToggleFeaturePut$Plain(params?: {
    body?: ApplicationFeatureDto
  }): Observable<BooleanBaseResponse> {

    return this.apiSettingsToggleFeaturePut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSettingsToggleFeaturePut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSettingsToggleFeaturePut$Json$Response(params?: {
    body?: ApplicationFeatureDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SettingsService.ApiSettingsToggleFeaturePutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSettingsToggleFeaturePut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSettingsToggleFeaturePut$Json(params?: {
    body?: ApplicationFeatureDto
  }): Observable<BooleanBaseResponse> {

    return this.apiSettingsToggleFeaturePut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiSettingsThemeGet
   */
  static readonly ApiSettingsThemeGetPath = '/api/Settings/theme';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSettingsThemeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSettingsThemeGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<ApplicationThemeDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SettingsService.ApiSettingsThemeGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationThemeDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSettingsThemeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSettingsThemeGet$Plain(params?: {
  }): Observable<ApplicationThemeDtoBaseResponse> {

    return this.apiSettingsThemeGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationThemeDtoBaseResponse>) => r.body as ApplicationThemeDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSettingsThemeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSettingsThemeGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<ApplicationThemeDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SettingsService.ApiSettingsThemeGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationThemeDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSettingsThemeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSettingsThemeGet$Json(params?: {
  }): Observable<ApplicationThemeDtoBaseResponse> {

    return this.apiSettingsThemeGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationThemeDtoBaseResponse>) => r.body as ApplicationThemeDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiSettingsUpdateThemePut
   */
  static readonly ApiSettingsUpdateThemePutPath = '/api/Settings/update-theme';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSettingsUpdateThemePut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSettingsUpdateThemePut$Plain$Response(params?: {
    body?: ApplicationThemeDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SettingsService.ApiSettingsUpdateThemePutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSettingsUpdateThemePut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSettingsUpdateThemePut$Plain(params?: {
    body?: ApplicationThemeDto
  }): Observable<BooleanBaseResponse> {

    return this.apiSettingsUpdateThemePut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSettingsUpdateThemePut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSettingsUpdateThemePut$Json$Response(params?: {
    body?: ApplicationThemeDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SettingsService.ApiSettingsUpdateThemePutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSettingsUpdateThemePut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSettingsUpdateThemePut$Json(params?: {
    body?: ApplicationThemeDto
  }): Observable<BooleanBaseResponse> {

    return this.apiSettingsUpdateThemePut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiSettingsUpdateThemeDimCoverBackgroundPut
   */
  static readonly ApiSettingsUpdateThemeDimCoverBackgroundPutPath = '/api/Settings/update-theme-dim-cover-background';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSettingsUpdateThemeDimCoverBackgroundPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSettingsUpdateThemeDimCoverBackgroundPut$Plain$Response(params?: {
    dimCoverBackground?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SettingsService.ApiSettingsUpdateThemeDimCoverBackgroundPutPath, 'put');
    if (params) {
      rb.query('dimCoverBackground', params.dimCoverBackground, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSettingsUpdateThemeDimCoverBackgroundPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSettingsUpdateThemeDimCoverBackgroundPut$Plain(params?: {
    dimCoverBackground?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiSettingsUpdateThemeDimCoverBackgroundPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSettingsUpdateThemeDimCoverBackgroundPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSettingsUpdateThemeDimCoverBackgroundPut$Json$Response(params?: {
    dimCoverBackground?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SettingsService.ApiSettingsUpdateThemeDimCoverBackgroundPutPath, 'put');
    if (params) {
      rb.query('dimCoverBackground', params.dimCoverBackground, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSettingsUpdateThemeDimCoverBackgroundPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSettingsUpdateThemeDimCoverBackgroundPut$Json(params?: {
    dimCoverBackground?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiSettingsUpdateThemeDimCoverBackgroundPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiSettingsUpdateCompanyImagePost
   */
  static readonly ApiSettingsUpdateCompanyImagePostPath = '/api/Settings/update-company-image';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSettingsUpdateCompanyImagePost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiSettingsUpdateCompanyImagePost$Plain$Response(params?: {

    /**
     * 0 &#x3D; CompanyLogo
     * 
     * 1 &#x3D; LoginLogo
     * 
     * 2 &#x3D; CoverLogo
     * 
     * 3 &#x3D; CoverBackground
     */
    imageType?: AppThemeImageType;
    filename?: string;
    body?: { 'formFile'?: Blob }
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SettingsService.ApiSettingsUpdateCompanyImagePostPath, 'post');
    if (params) {
      rb.query('imageType', params.imageType, {});
      rb.query('filename', params.filename, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSettingsUpdateCompanyImagePost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiSettingsUpdateCompanyImagePost$Plain(params?: {

    /**
     * 0 &#x3D; CompanyLogo
     * 
     * 1 &#x3D; LoginLogo
     * 
     * 2 &#x3D; CoverLogo
     * 
     * 3 &#x3D; CoverBackground
     */
    imageType?: AppThemeImageType;
    filename?: string;
    body?: { 'formFile'?: Blob }
  }): Observable<BooleanBaseResponse> {

    return this.apiSettingsUpdateCompanyImagePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSettingsUpdateCompanyImagePost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiSettingsUpdateCompanyImagePost$Json$Response(params?: {

    /**
     * 0 &#x3D; CompanyLogo
     * 
     * 1 &#x3D; LoginLogo
     * 
     * 2 &#x3D; CoverLogo
     * 
     * 3 &#x3D; CoverBackground
     */
    imageType?: AppThemeImageType;
    filename?: string;
    body?: { 'formFile'?: Blob }
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SettingsService.ApiSettingsUpdateCompanyImagePostPath, 'post');
    if (params) {
      rb.query('imageType', params.imageType, {});
      rb.query('filename', params.filename, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSettingsUpdateCompanyImagePost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiSettingsUpdateCompanyImagePost$Json(params?: {

    /**
     * 0 &#x3D; CompanyLogo
     * 
     * 1 &#x3D; LoginLogo
     * 
     * 2 &#x3D; CoverLogo
     * 
     * 3 &#x3D; CoverBackground
     */
    imageType?: AppThemeImageType;
    filename?: string;
    body?: { 'formFile'?: Blob }
  }): Observable<BooleanBaseResponse> {

    return this.apiSettingsUpdateCompanyImagePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiSettingsDeleteCompanyImageDelete
   */
  static readonly ApiSettingsDeleteCompanyImageDeletePath = '/api/Settings/delete-company-image';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSettingsDeleteCompanyImageDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSettingsDeleteCompanyImageDelete$Plain$Response(params?: {

    /**
     * 0 &#x3D; CompanyLogo
     * 
     * 1 &#x3D; LoginLogo
     * 
     * 2 &#x3D; CoverLogo
     * 
     * 3 &#x3D; CoverBackground
     */
    imageType?: AppThemeImageType;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SettingsService.ApiSettingsDeleteCompanyImageDeletePath, 'delete');
    if (params) {
      rb.query('imageType', params.imageType, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSettingsDeleteCompanyImageDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSettingsDeleteCompanyImageDelete$Plain(params?: {

    /**
     * 0 &#x3D; CompanyLogo
     * 
     * 1 &#x3D; LoginLogo
     * 
     * 2 &#x3D; CoverLogo
     * 
     * 3 &#x3D; CoverBackground
     */
    imageType?: AppThemeImageType;
  }): Observable<BooleanBaseResponse> {

    return this.apiSettingsDeleteCompanyImageDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSettingsDeleteCompanyImageDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSettingsDeleteCompanyImageDelete$Json$Response(params?: {

    /**
     * 0 &#x3D; CompanyLogo
     * 
     * 1 &#x3D; LoginLogo
     * 
     * 2 &#x3D; CoverLogo
     * 
     * 3 &#x3D; CoverBackground
     */
    imageType?: AppThemeImageType;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SettingsService.ApiSettingsDeleteCompanyImageDeletePath, 'delete');
    if (params) {
      rb.query('imageType', params.imageType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSettingsDeleteCompanyImageDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSettingsDeleteCompanyImageDelete$Json(params?: {

    /**
     * 0 &#x3D; CompanyLogo
     * 
     * 1 &#x3D; LoginLogo
     * 
     * 2 &#x3D; CoverLogo
     * 
     * 3 &#x3D; CoverBackground
     */
    imageType?: AppThemeImageType;
  }): Observable<BooleanBaseResponse> {

    return this.apiSettingsDeleteCompanyImageDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiSettingsGetCompanyImageGet
   */
  static readonly ApiSettingsGetCompanyImageGetPath = '/api/Settings/get-company-image';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSettingsGetCompanyImageGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSettingsGetCompanyImageGet$Response(params?: {

    /**
     * 0 &#x3D; CompanyLogo
     * 
     * 1 &#x3D; LoginLogo
     * 
     * 2 &#x3D; CoverLogo
     * 
     * 3 &#x3D; CoverBackground
     */
    imageType?: AppThemeImageType;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SettingsService.ApiSettingsGetCompanyImageGetPath, 'get');
    if (params) {
      rb.query('imageType', params.imageType, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSettingsGetCompanyImageGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSettingsGetCompanyImageGet(params?: {

    /**
     * 0 &#x3D; CompanyLogo
     * 
     * 1 &#x3D; LoginLogo
     * 
     * 2 &#x3D; CoverLogo
     * 
     * 3 &#x3D; CoverBackground
     */
    imageType?: AppThemeImageType;
  }): Observable<string> {

    return this.apiSettingsGetCompanyImageGet$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiSettingsAppInfoGet
   */
  static readonly ApiSettingsAppInfoGetPath = '/api/Settings/app-info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSettingsAppInfoGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSettingsAppInfoGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<ApplicationInfoDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SettingsService.ApiSettingsAppInfoGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationInfoDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSettingsAppInfoGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSettingsAppInfoGet$Plain(params?: {
  }): Observable<ApplicationInfoDtoBaseResponse> {

    return this.apiSettingsAppInfoGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationInfoDtoBaseResponse>) => r.body as ApplicationInfoDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSettingsAppInfoGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSettingsAppInfoGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<ApplicationInfoDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SettingsService.ApiSettingsAppInfoGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationInfoDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSettingsAppInfoGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSettingsAppInfoGet$Json(params?: {
  }): Observable<ApplicationInfoDtoBaseResponse> {

    return this.apiSettingsAppInfoGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationInfoDtoBaseResponse>) => r.body as ApplicationInfoDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiSettingsUpdateAppInfoPut
   */
  static readonly ApiSettingsUpdateAppInfoPutPath = '/api/Settings/update-app-info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSettingsUpdateAppInfoPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSettingsUpdateAppInfoPut$Plain$Response(params?: {
    body?: ApplicationInfoDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SettingsService.ApiSettingsUpdateAppInfoPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSettingsUpdateAppInfoPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSettingsUpdateAppInfoPut$Plain(params?: {
    body?: ApplicationInfoDto
  }): Observable<BooleanBaseResponse> {

    return this.apiSettingsUpdateAppInfoPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSettingsUpdateAppInfoPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSettingsUpdateAppInfoPut$Json$Response(params?: {
    body?: ApplicationInfoDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SettingsService.ApiSettingsUpdateAppInfoPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSettingsUpdateAppInfoPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSettingsUpdateAppInfoPut$Json(params?: {
    body?: ApplicationInfoDto
  }): Observable<BooleanBaseResponse> {

    return this.apiSettingsUpdateAppInfoPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

}
