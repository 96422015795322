/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanBaseResponse } from '../models/boolean-base-response';
import { OrderDirection } from '../models/order-direction';
import { ProjectAddressDto } from '../models/project-address-dto';
import { ProjectAddressDtoBaseResponse } from '../models/project-address-dto-base-response';
import { PropertyFilter } from '../models/property-filter';
import { SupplierCommentDto } from '../models/supplier-comment-dto';
import { SupplierCommentDtoBaseResponse } from '../models/supplier-comment-dto-base-response';
import { SupplierCommentDtoListBaseResponse } from '../models/supplier-comment-dto-list-base-response';
import { SupplierContactDto } from '../models/supplier-contact-dto';
import { SupplierContactDtoBaseResponse } from '../models/supplier-contact-dto-base-response';
import { SupplierContactDtoListBaseResponse } from '../models/supplier-contact-dto-list-base-response';
import { SupplierDto } from '../models/supplier-dto';
import { SupplierDtoBaseResponse } from '../models/supplier-dto-base-response';
import { SupplierDtoListBaseResponse } from '../models/supplier-dto-list-base-response';
import { SupplierDtoPagedResponseBaseResponse } from '../models/supplier-dto-paged-response-base-response';

@Injectable({
  providedIn: 'root',
})
export class SupplierService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiSupplierPaginatedListGet
   */
  static readonly ApiSupplierPaginatedListGetPath = '/api/Supplier/paginated-list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupplierPaginatedListGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierPaginatedListGet$Plain$Response(params?: {
    Month?: number;
    Year?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<SupplierDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupplierService.ApiSupplierPaginatedListGetPath, 'get');
    if (params) {
      rb.query('Month', params.Month, {});
      rb.query('Year', params.Year, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('GetAll', params.GetAll, {});
      rb.query('Archived', params.Archived, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('PropertyFilters', params.PropertyFilters, {});
      rb.query('OrderProperty', params.OrderProperty, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SupplierDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSupplierPaginatedListGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierPaginatedListGet$Plain(params?: {
    Month?: number;
    Year?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<SupplierDtoPagedResponseBaseResponse> {

    return this.apiSupplierPaginatedListGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SupplierDtoPagedResponseBaseResponse>) => r.body as SupplierDtoPagedResponseBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupplierPaginatedListGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierPaginatedListGet$Json$Response(params?: {
    Month?: number;
    Year?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<SupplierDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupplierService.ApiSupplierPaginatedListGetPath, 'get');
    if (params) {
      rb.query('Month', params.Month, {});
      rb.query('Year', params.Year, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('GetAll', params.GetAll, {});
      rb.query('Archived', params.Archived, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('PropertyFilters', params.PropertyFilters, {});
      rb.query('OrderProperty', params.OrderProperty, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SupplierDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSupplierPaginatedListGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierPaginatedListGet$Json(params?: {
    Month?: number;
    Year?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<SupplierDtoPagedResponseBaseResponse> {

    return this.apiSupplierPaginatedListGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SupplierDtoPagedResponseBaseResponse>) => r.body as SupplierDtoPagedResponseBaseResponse)
    );
  }

  /**
   * Path part for operation apiSupplierGetAllGet
   */
  static readonly ApiSupplierGetAllGetPath = '/api/Supplier/get-all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupplierGetAllGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierGetAllGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<SupplierDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupplierService.ApiSupplierGetAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SupplierDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSupplierGetAllGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierGetAllGet$Plain(params?: {
  }): Observable<SupplierDtoListBaseResponse> {

    return this.apiSupplierGetAllGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SupplierDtoListBaseResponse>) => r.body as SupplierDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupplierGetAllGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierGetAllGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<SupplierDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupplierService.ApiSupplierGetAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SupplierDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSupplierGetAllGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierGetAllGet$Json(params?: {
  }): Observable<SupplierDtoListBaseResponse> {

    return this.apiSupplierGetAllGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SupplierDtoListBaseResponse>) => r.body as SupplierDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiSupplierSearchAllGet
   */
  static readonly ApiSupplierSearchAllGetPath = '/api/Supplier/search-all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupplierSearchAllGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierSearchAllGet$Plain$Response(params?: {
    searchTerm?: string;
    archived?: boolean;
  }): Observable<StrictHttpResponse<SupplierDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupplierService.ApiSupplierSearchAllGetPath, 'get');
    if (params) {
      rb.query('searchTerm', params.searchTerm, {});
      rb.query('archived', params.archived, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SupplierDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSupplierSearchAllGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierSearchAllGet$Plain(params?: {
    searchTerm?: string;
    archived?: boolean;
  }): Observable<SupplierDtoListBaseResponse> {

    return this.apiSupplierSearchAllGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SupplierDtoListBaseResponse>) => r.body as SupplierDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupplierSearchAllGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierSearchAllGet$Json$Response(params?: {
    searchTerm?: string;
    archived?: boolean;
  }): Observable<StrictHttpResponse<SupplierDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupplierService.ApiSupplierSearchAllGetPath, 'get');
    if (params) {
      rb.query('searchTerm', params.searchTerm, {});
      rb.query('archived', params.archived, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SupplierDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSupplierSearchAllGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierSearchAllGet$Json(params?: {
    searchTerm?: string;
    archived?: boolean;
  }): Observable<SupplierDtoListBaseResponse> {

    return this.apiSupplierSearchAllGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SupplierDtoListBaseResponse>) => r.body as SupplierDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiSupplierGetSupplierPost
   */
  static readonly ApiSupplierGetSupplierPostPath = '/api/Supplier/get-supplier';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupplierGetSupplierPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierGetSupplierPost$Plain$Response(params?: {
    supplierId?: number;
  }): Observable<StrictHttpResponse<SupplierDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupplierService.ApiSupplierGetSupplierPostPath, 'post');
    if (params) {
      rb.query('supplierId', params.supplierId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SupplierDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSupplierGetSupplierPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierGetSupplierPost$Plain(params?: {
    supplierId?: number;
  }): Observable<SupplierDtoBaseResponse> {

    return this.apiSupplierGetSupplierPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SupplierDtoBaseResponse>) => r.body as SupplierDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupplierGetSupplierPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierGetSupplierPost$Json$Response(params?: {
    supplierId?: number;
  }): Observable<StrictHttpResponse<SupplierDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupplierService.ApiSupplierGetSupplierPostPath, 'post');
    if (params) {
      rb.query('supplierId', params.supplierId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SupplierDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSupplierGetSupplierPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierGetSupplierPost$Json(params?: {
    supplierId?: number;
  }): Observable<SupplierDtoBaseResponse> {

    return this.apiSupplierGetSupplierPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SupplierDtoBaseResponse>) => r.body as SupplierDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiSupplierAddSupplierPost
   */
  static readonly ApiSupplierAddSupplierPostPath = '/api/Supplier/add-supplier';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupplierAddSupplierPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupplierAddSupplierPost$Plain$Response(params?: {
    body?: SupplierDto
  }): Observable<StrictHttpResponse<SupplierDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupplierService.ApiSupplierAddSupplierPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SupplierDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSupplierAddSupplierPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupplierAddSupplierPost$Plain(params?: {
    body?: SupplierDto
  }): Observable<SupplierDtoBaseResponse> {

    return this.apiSupplierAddSupplierPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SupplierDtoBaseResponse>) => r.body as SupplierDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupplierAddSupplierPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupplierAddSupplierPost$Json$Response(params?: {
    body?: SupplierDto
  }): Observable<StrictHttpResponse<SupplierDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupplierService.ApiSupplierAddSupplierPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SupplierDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSupplierAddSupplierPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupplierAddSupplierPost$Json(params?: {
    body?: SupplierDto
  }): Observable<SupplierDtoBaseResponse> {

    return this.apiSupplierAddSupplierPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SupplierDtoBaseResponse>) => r.body as SupplierDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiSupplierUpdateSupplierPost
   */
  static readonly ApiSupplierUpdateSupplierPostPath = '/api/Supplier/update-supplier';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupplierUpdateSupplierPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupplierUpdateSupplierPost$Plain$Response(params?: {
    body?: SupplierDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupplierService.ApiSupplierUpdateSupplierPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSupplierUpdateSupplierPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupplierUpdateSupplierPost$Plain(params?: {
    body?: SupplierDto
  }): Observable<BooleanBaseResponse> {

    return this.apiSupplierUpdateSupplierPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupplierUpdateSupplierPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupplierUpdateSupplierPost$Json$Response(params?: {
    body?: SupplierDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupplierService.ApiSupplierUpdateSupplierPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSupplierUpdateSupplierPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupplierUpdateSupplierPost$Json(params?: {
    body?: SupplierDto
  }): Observable<BooleanBaseResponse> {

    return this.apiSupplierUpdateSupplierPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiSupplierToggleArchiveSupplierPost
   */
  static readonly ApiSupplierToggleArchiveSupplierPostPath = '/api/Supplier/toggle-archive-supplier';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupplierToggleArchiveSupplierPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierToggleArchiveSupplierPost$Plain$Response(params?: {
    supplierId?: number;
    isArchive?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupplierService.ApiSupplierToggleArchiveSupplierPostPath, 'post');
    if (params) {
      rb.query('supplierId', params.supplierId, {});
      rb.query('isArchive', params.isArchive, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSupplierToggleArchiveSupplierPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierToggleArchiveSupplierPost$Plain(params?: {
    supplierId?: number;
    isArchive?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiSupplierToggleArchiveSupplierPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupplierToggleArchiveSupplierPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierToggleArchiveSupplierPost$Json$Response(params?: {
    supplierId?: number;
    isArchive?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupplierService.ApiSupplierToggleArchiveSupplierPostPath, 'post');
    if (params) {
      rb.query('supplierId', params.supplierId, {});
      rb.query('isArchive', params.isArchive, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSupplierToggleArchiveSupplierPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierToggleArchiveSupplierPost$Json(params?: {
    supplierId?: number;
    isArchive?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiSupplierToggleArchiveSupplierPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiSupplierAddAddressPost
   */
  static readonly ApiSupplierAddAddressPostPath = '/api/Supplier/add-address';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupplierAddAddressPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupplierAddAddressPost$Plain$Response(params?: {
    body?: ProjectAddressDto
  }): Observable<StrictHttpResponse<ProjectAddressDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupplierService.ApiSupplierAddAddressPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectAddressDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSupplierAddAddressPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupplierAddAddressPost$Plain(params?: {
    body?: ProjectAddressDto
  }): Observable<ProjectAddressDtoBaseResponse> {

    return this.apiSupplierAddAddressPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectAddressDtoBaseResponse>) => r.body as ProjectAddressDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupplierAddAddressPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupplierAddAddressPost$Json$Response(params?: {
    body?: ProjectAddressDto
  }): Observable<StrictHttpResponse<ProjectAddressDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupplierService.ApiSupplierAddAddressPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectAddressDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSupplierAddAddressPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupplierAddAddressPost$Json(params?: {
    body?: ProjectAddressDto
  }): Observable<ProjectAddressDtoBaseResponse> {

    return this.apiSupplierAddAddressPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectAddressDtoBaseResponse>) => r.body as ProjectAddressDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiSupplierUpdateAddressPost
   */
  static readonly ApiSupplierUpdateAddressPostPath = '/api/Supplier/update-address';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupplierUpdateAddressPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupplierUpdateAddressPost$Plain$Response(params?: {
    body?: ProjectAddressDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupplierService.ApiSupplierUpdateAddressPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSupplierUpdateAddressPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupplierUpdateAddressPost$Plain(params?: {
    body?: ProjectAddressDto
  }): Observable<BooleanBaseResponse> {

    return this.apiSupplierUpdateAddressPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupplierUpdateAddressPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupplierUpdateAddressPost$Json$Response(params?: {
    body?: ProjectAddressDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupplierService.ApiSupplierUpdateAddressPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSupplierUpdateAddressPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupplierUpdateAddressPost$Json(params?: {
    body?: ProjectAddressDto
  }): Observable<BooleanBaseResponse> {

    return this.apiSupplierUpdateAddressPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiSupplierDeleteAddressDelete
   */
  static readonly ApiSupplierDeleteAddressDeletePath = '/api/Supplier/delete-address';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupplierDeleteAddressDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierDeleteAddressDelete$Plain$Response(params?: {
    supplierAddressId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupplierService.ApiSupplierDeleteAddressDeletePath, 'delete');
    if (params) {
      rb.query('supplierAddressId', params.supplierAddressId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSupplierDeleteAddressDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierDeleteAddressDelete$Plain(params?: {
    supplierAddressId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiSupplierDeleteAddressDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupplierDeleteAddressDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierDeleteAddressDelete$Json$Response(params?: {
    supplierAddressId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupplierService.ApiSupplierDeleteAddressDeletePath, 'delete');
    if (params) {
      rb.query('supplierAddressId', params.supplierAddressId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSupplierDeleteAddressDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierDeleteAddressDelete$Json(params?: {
    supplierAddressId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiSupplierDeleteAddressDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiSupplierSearchSupplierContactsGet
   */
  static readonly ApiSupplierSearchSupplierContactsGetPath = '/api/Supplier/search-supplier-contacts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupplierSearchSupplierContactsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierSearchSupplierContactsGet$Plain$Response(params?: {
    supplierId?: number;
    searchTerm?: string;
  }): Observable<StrictHttpResponse<SupplierContactDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupplierService.ApiSupplierSearchSupplierContactsGetPath, 'get');
    if (params) {
      rb.query('supplierId', params.supplierId, {});
      rb.query('searchTerm', params.searchTerm, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SupplierContactDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSupplierSearchSupplierContactsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierSearchSupplierContactsGet$Plain(params?: {
    supplierId?: number;
    searchTerm?: string;
  }): Observable<SupplierContactDtoListBaseResponse> {

    return this.apiSupplierSearchSupplierContactsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SupplierContactDtoListBaseResponse>) => r.body as SupplierContactDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupplierSearchSupplierContactsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierSearchSupplierContactsGet$Json$Response(params?: {
    supplierId?: number;
    searchTerm?: string;
  }): Observable<StrictHttpResponse<SupplierContactDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupplierService.ApiSupplierSearchSupplierContactsGetPath, 'get');
    if (params) {
      rb.query('supplierId', params.supplierId, {});
      rb.query('searchTerm', params.searchTerm, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SupplierContactDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSupplierSearchSupplierContactsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierSearchSupplierContactsGet$Json(params?: {
    supplierId?: number;
    searchTerm?: string;
  }): Observable<SupplierContactDtoListBaseResponse> {

    return this.apiSupplierSearchSupplierContactsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SupplierContactDtoListBaseResponse>) => r.body as SupplierContactDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiSupplierGetContactPost
   */
  static readonly ApiSupplierGetContactPostPath = '/api/Supplier/get-contact';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupplierGetContactPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierGetContactPost$Plain$Response(params?: {
    supplierContactId?: number;
  }): Observable<StrictHttpResponse<SupplierContactDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupplierService.ApiSupplierGetContactPostPath, 'post');
    if (params) {
      rb.query('supplierContactId', params.supplierContactId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SupplierContactDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSupplierGetContactPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierGetContactPost$Plain(params?: {
    supplierContactId?: number;
  }): Observable<SupplierContactDtoBaseResponse> {

    return this.apiSupplierGetContactPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SupplierContactDtoBaseResponse>) => r.body as SupplierContactDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupplierGetContactPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierGetContactPost$Json$Response(params?: {
    supplierContactId?: number;
  }): Observable<StrictHttpResponse<SupplierContactDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupplierService.ApiSupplierGetContactPostPath, 'post');
    if (params) {
      rb.query('supplierContactId', params.supplierContactId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SupplierContactDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSupplierGetContactPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierGetContactPost$Json(params?: {
    supplierContactId?: number;
  }): Observable<SupplierContactDtoBaseResponse> {

    return this.apiSupplierGetContactPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SupplierContactDtoBaseResponse>) => r.body as SupplierContactDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiSupplierAddContactPost
   */
  static readonly ApiSupplierAddContactPostPath = '/api/Supplier/add-contact';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupplierAddContactPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupplierAddContactPost$Plain$Response(params?: {
    body?: SupplierContactDto
  }): Observable<StrictHttpResponse<SupplierContactDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupplierService.ApiSupplierAddContactPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SupplierContactDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSupplierAddContactPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupplierAddContactPost$Plain(params?: {
    body?: SupplierContactDto
  }): Observable<SupplierContactDtoBaseResponse> {

    return this.apiSupplierAddContactPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SupplierContactDtoBaseResponse>) => r.body as SupplierContactDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupplierAddContactPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupplierAddContactPost$Json$Response(params?: {
    body?: SupplierContactDto
  }): Observable<StrictHttpResponse<SupplierContactDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupplierService.ApiSupplierAddContactPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SupplierContactDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSupplierAddContactPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupplierAddContactPost$Json(params?: {
    body?: SupplierContactDto
  }): Observable<SupplierContactDtoBaseResponse> {

    return this.apiSupplierAddContactPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SupplierContactDtoBaseResponse>) => r.body as SupplierContactDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiSupplierUpdateContactPost
   */
  static readonly ApiSupplierUpdateContactPostPath = '/api/Supplier/update-contact';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupplierUpdateContactPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupplierUpdateContactPost$Plain$Response(params?: {
    body?: SupplierContactDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupplierService.ApiSupplierUpdateContactPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSupplierUpdateContactPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupplierUpdateContactPost$Plain(params?: {
    body?: SupplierContactDto
  }): Observable<BooleanBaseResponse> {

    return this.apiSupplierUpdateContactPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupplierUpdateContactPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupplierUpdateContactPost$Json$Response(params?: {
    body?: SupplierContactDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupplierService.ApiSupplierUpdateContactPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSupplierUpdateContactPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupplierUpdateContactPost$Json(params?: {
    body?: SupplierContactDto
  }): Observable<BooleanBaseResponse> {

    return this.apiSupplierUpdateContactPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiSupplierDeleteContactPost
   */
  static readonly ApiSupplierDeleteContactPostPath = '/api/Supplier/delete-contact';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupplierDeleteContactPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierDeleteContactPost$Plain$Response(params?: {
    supplierContactId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupplierService.ApiSupplierDeleteContactPostPath, 'post');
    if (params) {
      rb.query('supplierContactId', params.supplierContactId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSupplierDeleteContactPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierDeleteContactPost$Plain(params?: {
    supplierContactId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiSupplierDeleteContactPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupplierDeleteContactPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierDeleteContactPost$Json$Response(params?: {
    supplierContactId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupplierService.ApiSupplierDeleteContactPostPath, 'post');
    if (params) {
      rb.query('supplierContactId', params.supplierContactId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSupplierDeleteContactPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierDeleteContactPost$Json(params?: {
    supplierContactId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiSupplierDeleteContactPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiSupplierCommentsGet
   */
  static readonly ApiSupplierCommentsGetPath = '/api/Supplier/comments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupplierCommentsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierCommentsGet$Plain$Response(params?: {
    supplierId?: number;
  }): Observable<StrictHttpResponse<SupplierCommentDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupplierService.ApiSupplierCommentsGetPath, 'get');
    if (params) {
      rb.query('supplierId', params.supplierId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SupplierCommentDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSupplierCommentsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierCommentsGet$Plain(params?: {
    supplierId?: number;
  }): Observable<SupplierCommentDtoListBaseResponse> {

    return this.apiSupplierCommentsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SupplierCommentDtoListBaseResponse>) => r.body as SupplierCommentDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupplierCommentsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierCommentsGet$Json$Response(params?: {
    supplierId?: number;
  }): Observable<StrictHttpResponse<SupplierCommentDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupplierService.ApiSupplierCommentsGetPath, 'get');
    if (params) {
      rb.query('supplierId', params.supplierId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SupplierCommentDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSupplierCommentsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierCommentsGet$Json(params?: {
    supplierId?: number;
  }): Observable<SupplierCommentDtoListBaseResponse> {

    return this.apiSupplierCommentsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SupplierCommentDtoListBaseResponse>) => r.body as SupplierCommentDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiSupplierAddCommentPost
   */
  static readonly ApiSupplierAddCommentPostPath = '/api/Supplier/add-comment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupplierAddCommentPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupplierAddCommentPost$Plain$Response(params?: {
    body?: SupplierCommentDto
  }): Observable<StrictHttpResponse<SupplierCommentDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupplierService.ApiSupplierAddCommentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SupplierCommentDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSupplierAddCommentPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupplierAddCommentPost$Plain(params?: {
    body?: SupplierCommentDto
  }): Observable<SupplierCommentDtoBaseResponse> {

    return this.apiSupplierAddCommentPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SupplierCommentDtoBaseResponse>) => r.body as SupplierCommentDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupplierAddCommentPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupplierAddCommentPost$Json$Response(params?: {
    body?: SupplierCommentDto
  }): Observable<StrictHttpResponse<SupplierCommentDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupplierService.ApiSupplierAddCommentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SupplierCommentDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSupplierAddCommentPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupplierAddCommentPost$Json(params?: {
    body?: SupplierCommentDto
  }): Observable<SupplierCommentDtoBaseResponse> {

    return this.apiSupplierAddCommentPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SupplierCommentDtoBaseResponse>) => r.body as SupplierCommentDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiSupplierUpdateCommentPut
   */
  static readonly ApiSupplierUpdateCommentPutPath = '/api/Supplier/update-comment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupplierUpdateCommentPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupplierUpdateCommentPut$Plain$Response(params?: {
    body?: SupplierCommentDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupplierService.ApiSupplierUpdateCommentPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSupplierUpdateCommentPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupplierUpdateCommentPut$Plain(params?: {
    body?: SupplierCommentDto
  }): Observable<BooleanBaseResponse> {

    return this.apiSupplierUpdateCommentPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupplierUpdateCommentPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupplierUpdateCommentPut$Json$Response(params?: {
    body?: SupplierCommentDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupplierService.ApiSupplierUpdateCommentPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSupplierUpdateCommentPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupplierUpdateCommentPut$Json(params?: {
    body?: SupplierCommentDto
  }): Observable<BooleanBaseResponse> {

    return this.apiSupplierUpdateCommentPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiSupplierDeleteCommentDelete
   */
  static readonly ApiSupplierDeleteCommentDeletePath = '/api/Supplier/delete-comment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupplierDeleteCommentDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierDeleteCommentDelete$Plain$Response(params?: {
    commentId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupplierService.ApiSupplierDeleteCommentDeletePath, 'delete');
    if (params) {
      rb.query('commentId', params.commentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSupplierDeleteCommentDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierDeleteCommentDelete$Plain(params?: {
    commentId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiSupplierDeleteCommentDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupplierDeleteCommentDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierDeleteCommentDelete$Json$Response(params?: {
    commentId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupplierService.ApiSupplierDeleteCommentDeletePath, 'delete');
    if (params) {
      rb.query('commentId', params.commentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSupplierDeleteCommentDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupplierDeleteCommentDelete$Json(params?: {
    commentId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiSupplierDeleteCommentDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

}
