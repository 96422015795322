import { inject } from "@angular/core";
import { Router, CanActivateFn, CanActivateChildFn } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { of } from "rxjs";
import { JwtAuthService } from "../jwt-auth.service";

export const BasicGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
{
    const router: Router = inject(Router);
    const snack: MatSnackBar = inject(MatSnackBar);
    const jwtAuth: JwtAuthService = inject(JwtAuthService);

    var accessDenied = () => 
        snack.open("You do not have access to this page!", "OK", {
        duration: 4000,
    });

    if(jwtAuth.isBasic()) {
        accessDenied();

        const urlTree = router.parseUrl(`sign-in`);
        return of(urlTree);
    }

    // Allow the access
    return of(true);
}
