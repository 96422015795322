/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        icon : 'heroicons_outline:rectangle-group',
        link : '/dashboard',
    },

    // CRM
    {
        id      : 'crm',
        title   : 'CRM',
        type    : 'collapsable',
        icon    : 'heroicons_outline:rocket-launch',
        children: [
            
            // Contacts
            {
                id   : 'crm.contacts',
                title: 'Clients',
                type : 'basic',
                icon : 'heroicons_outline:user-group',
                link : '/crm/contacts',
            },

            // Lead Management
            {
                id   : 'crm.leads',
                title: 'Lead Management',
                type : 'basic',
                icon : 'feather:user',
                link : '/crm/leads',
            },
            
            // Sales Pipeline
            {
                id   : 'crm.pipeline',
                title: 'Sales Pipeline',
                type : 'basic',
                icon : 'heroicons_outline:presentation-chart-line',
                link : '/crm/pipeline',
            },

            // Email Marketing
            {
                id   : 'crm.emails',
                title: 'Emails',
                type : 'basic',
                icon : 'heroicons_outline:at-symbol',
                link : '/crm/emails',
            },

            // Reporting
            {
                id   : 'crm.reporting',
                title: 'Reporting',
                type : 'basic',
                icon : 'feather:bar-chart-2',
                link : '/crm/reporting',
            },

        ],
    },

    // Project Management
    {
        id      : 'project-management',
        title   : 'Project Management',
        type    : 'collapsable',
        icon    : 'feather:target',
        children: [
            {
                id   : 'project-management.dashboard',
                title: 'Dashboard',
                type : 'basic',
                icon : 'heroicons_outline:home',
                link : '/project-management/dashboard',
            },
            {
                id   : 'project-management.projects',
                title: 'Projects',
                type : 'basic',
                icon : 'mat_outline:bubble_chart',
                link : '/project-management/projects',
            },
            {
                id   : 'project-management.workload',
                title: 'Workload Planner',
                type : 'basic',
                icon : 'mat_outline:table_chart',
                link : '/project-management/workload',
            },
            {
                id   : 'project-management.invoices',
                title: 'Invoicing',
                type : 'basic',
                icon : 'heroicons_outline:document-text',
                link : '/project-management/invoicing',
            },
            {
                id   : 'project-management.purchase-orders',
                title: 'Purchase Orders',
                type : 'basic',
                icon : 'heroicons_outline:document',
                link : '/project-management/purchase-orders',
            },
            {
                id   : 'project-management.reporting',
                title: 'Reporting',
                type : 'basic',
                icon : 'feather:bar-chart-2',
                link : '/project-management/reporting',
            },
        ],
    },  

    // ERP
    {
        id      : 'erp',
        title   : 'ERP',
        type    : 'collapsable',
        icon    : 'feather:hexagon',
        children: [

            // Contacts
            {
                id   : 'erp.contacts',
                title: 'Clients',
                type : 'basic',
                icon : 'heroicons_outline:user-group',
                link : '/erp/contacts',
            },

            // Products
            {
                id   : 'erp.products',
                title: 'Products',
                type : 'basic',
                icon : 'heroicons_outline:cube-transparent',
                link : '/erp/products',
            },

            // Sales
            {
                id   : 'erp.sales',
                title: 'Sales',
                type : 'basic',
                icon : 'heroicons_outline:currency-pound',
                link : '/erp/sales',
            },

            // HR
            {
                id   : 'erp.hr',
                title: 'HR',
                type : 'basic',
                icon : 'heroicons_outline:user-group',
                link : '/erp/hr',
            },

            // Purchases,
            {
                id   : 'erp.purchases',
                title: 'Purchases',
                type : 'basic',
                icon : 'heroicons_outline:circle-stack',
                link : '/erp/purchases',
            },

            // Invoicing
            {
                id   : 'pages.invoicing',
                title: 'Invoicing',
                type : 'basic',
                icon : 'heroicons_outline:document-text',
                link : '/erp/invoicing',
            },

            // GRN
            {
                id   : 'erp.grn',
                title: 'Goods Received',
                type : 'basic',
                icon : 'feather:package',
                link : '/erp/grn',
            },

            // Production
            {
                id   : 'erp.production',
                title: 'Production',
                type : 'basic',
                icon : 'heroicons_outline:wrench-screwdriver',
                link : '/erp/production',
            },

            // Leads
            {
                id   : 'erp.leads',
                title: 'Leads',
                type : 'basic',
                icon : 'feather:user',
                link : '/erp/leads',
            },
            
            // Sales Pipeline
            {
                id   : 'erp.pipeline',
                title: 'Sales Pipeline',
                type : 'basic',
                icon : 'heroicons_outline:presentation-chart-line',
                link : '/erp/pipeline',
            },

            // Suppliers
            {
                id   : 'erp.suppliers',
                title: 'Suppliers',
                type : 'basic',
                icon : 'heroicons_outline:building-office',
                link : '/erp/suppliers',
            },

            // Reporting
            {
                id   : 'erp.reports',
                title: 'Reporting',
                type : 'basic',
                icon : 'feather:bar-chart-2',
                link : '/erp/reports',
            },
            
        ],
    },

    // Installation and Servicing
    {
        id      : 'installation',
        title   : 'Installation & Servicing',
        type    : 'collapsable',
        icon    : 'heroicons_outline:wrench-screwdriver',
        children: [

            // Contacts
            {
                id   : 'installation.contacts',
                title: 'Clients',
                type : 'basic',
                icon : 'heroicons_outline:user-group',
                link : '/installation/contacts',
            },

            // Products
            {
                id   : 'installation.products',
                title: 'Products',
                type : 'basic',
                icon : 'heroicons_outline:cube-transparent',
                link : '/installation/products',
            },

            // Suppliers
            {
                id   : 'installation.suppliers',
                title: 'Suppliers',
                type : 'basic',
                icon : 'heroicons_outline:building-office',
                link : '/installation/suppliers',
            },

            // Purchase Orders
            {
                id   : 'installation.purchase-orders',
                title: 'Purchase Orders',
                type : 'basic',
                icon : 'heroicons_outline:document',
                link : '/installation/purchase-orders',
            },

            // Invoicing
            {
                id   : 'installation.invoices',
                title: 'Invoicing',
                type : 'basic',
                icon : 'heroicons_outline:document-text',
                link : '/installation/invoicing',
            },

            // Calendar
            {
                id   : 'installation.calendar',
                title: 'Calendar',
                type : 'basic',
                icon : 'heroicons_outline:calendar-days',
                link : '/installation/calendar',
            },

            // Documentations
            {
                id   : 'installation.documentations',
                title: 'Documentations',
                type : 'basic',
                icon : 'heroicons_outline:document',
                link : '/installation/documentations',
            },
        ],
    },

    // E-Commerce
    {
        id      : 'eCommerce',
        title   : 'eCommerce',
        type    : 'collapsable',
        icon    : 'heroicons_outline:shopping-cart',
        children: [

            // Inventory
            {
                id   : 'ecommerce.inventory',
                title: 'Products/Inventory',
                type : 'basic',
                icon : 'feather:list',
                link : '/ecommerce/inventory',
            },

            // Stock
            {
                id   : 'ecommerce.stock',
                title: 'Stock',
                type : 'basic',
                icon : 'mat_outline:inventory',
                link : '/ecommerce/stock',
            },

            // Sales History
            {
                id   : 'ecommerce.sales-orders',
                title: 'Sales History',
                type : 'basic',
                icon : 'heroicons_outline:document',
                link : '/ecommerce/sales-orders',
            },
        ],
    },

    //Misc Applications
    {
        id      : 'apps',
        title   : 'Misc Applications',
        type    : 'collapsable',
        icon    : 'heroicons_outline:cube',
        children: [

            // Calendar
            {
                id   : 'apps.calendar',
                title: 'Calendar',
                type : 'basic',
                icon : 'heroicons_outline:calendar-days',
                link : '/apps/calendar',
            },
            
            // Mailbox
            {
                id   : 'apps.mailbox',
                title: 'Mailbox',
                type : 'basic',
                icon : 'heroicons_outline:envelope',
                link : '/apps/mailbox',
                badge: {
                    title  : '27',
                    classes: 'px-2 bg-pink-600 text-white rounded-full',
                },
            },

            // Messenger
            {
                id   : 'apps.messenger',
                title: 'Messaging',
                type : 'basic',
                icon : 'heroicons_outline:chat-bubble-left-right',
                link : '/apps/messenger',
            },

            // Online Courses
            {
                id   : 'apps.courses',
                title: 'Courses & Training',
                type : 'basic',
                icon : 'heroicons_outline:academic-cap',
                link : '/apps/courses',
            },

            // Task List
            {
                id   : 'apps.tasks',
                title: 'Task List',
                type : 'basic',
                icon : 'heroicons_outline:clipboard-document-list',
                link : '/apps/tasks',
            },

            // File Manager
            {
                id   : 'apps.files',
                title: 'File Management',
                type : 'basic',
                icon : 'heroicons_outline:folder',
                link : '/apps/files',
            },
            
        ],
    },

    // Settings
    {
        id      : 'settings',
        title   : 'Settings',
        type    : 'basic',
        icon    : 'heroicons_outline:cog-8-tooth',
        link    : '/settings',
    },
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id      : 'dashboard',
        title   : 'Dashboard',
        tooltip : 'Dashboard',
        type    : 'aside',
        icon    : 'heroicons_outline:home',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'apps',
        title   : 'Apps',
        tooltip : 'Apps',
        type    : 'aside',
        icon    : 'heroicons_outline:qrcode',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'pages',
        title   : 'Pages',
        tooltip : 'Pages',
        type    : 'aside',
        icon    : 'heroicons_outline:document-duplicate',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id      : 'dashboards',
        title   : 'DASHBOARDS',
        type    : 'group',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'apps',
        title   : 'APPS',
        type    : 'group',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id   : 'others',
        title: 'OTHERS',
        type : 'group',
    },
    {
        id      : 'pages',
        title   : 'Pages',
        type    : 'aside',
        icon    : 'heroicons_outline:document-duplicate',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id      : 'dashboards',
        title   : 'Dashboards',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'apps',
        title   : 'Apps',
        type    : 'group',
        icon    : 'heroicons_outline:qrcode',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'pages',
        title   : 'Pages',
        type    : 'group',
        icon    : 'heroicons_outline:document-duplicate',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    }
];
