/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanBaseResponse } from '../models/boolean-base-response';
import { OrderDirection } from '../models/order-direction';
import { PropertyFilter } from '../models/property-filter';
import { StaffRateDto } from '../models/staff-rate-dto';
import { StaffRateDtoBaseResponse } from '../models/staff-rate-dto-base-response';
import { StaffRateDtoListBaseResponse } from '../models/staff-rate-dto-list-base-response';
import { StaffRateDtoPagedResponseBaseResponse } from '../models/staff-rate-dto-paged-response-base-response';

@Injectable({
  providedIn: 'root',
})
export class StaffRateService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiStaffRateGetAllGet
   */
  static readonly ApiStaffRateGetAllGetPath = '/api/StaffRate/get-all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStaffRateGetAllGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStaffRateGetAllGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<StaffRateDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, StaffRateService.ApiStaffRateGetAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StaffRateDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStaffRateGetAllGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStaffRateGetAllGet$Plain(params?: {
  }): Observable<StaffRateDtoListBaseResponse> {

    return this.apiStaffRateGetAllGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StaffRateDtoListBaseResponse>) => r.body as StaffRateDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStaffRateGetAllGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStaffRateGetAllGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<StaffRateDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, StaffRateService.ApiStaffRateGetAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StaffRateDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStaffRateGetAllGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStaffRateGetAllGet$Json(params?: {
  }): Observable<StaffRateDtoListBaseResponse> {

    return this.apiStaffRateGetAllGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StaffRateDtoListBaseResponse>) => r.body as StaffRateDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiStaffRateSearchAllGet
   */
  static readonly ApiStaffRateSearchAllGetPath = '/api/StaffRate/search-all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStaffRateSearchAllGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStaffRateSearchAllGet$Plain$Response(params?: {
    searchTerm?: string;
    archived?: boolean;
  }): Observable<StrictHttpResponse<StaffRateDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, StaffRateService.ApiStaffRateSearchAllGetPath, 'get');
    if (params) {
      rb.query('searchTerm', params.searchTerm, {});
      rb.query('archived', params.archived, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StaffRateDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStaffRateSearchAllGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStaffRateSearchAllGet$Plain(params?: {
    searchTerm?: string;
    archived?: boolean;
  }): Observable<StaffRateDtoListBaseResponse> {

    return this.apiStaffRateSearchAllGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StaffRateDtoListBaseResponse>) => r.body as StaffRateDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStaffRateSearchAllGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStaffRateSearchAllGet$Json$Response(params?: {
    searchTerm?: string;
    archived?: boolean;
  }): Observable<StrictHttpResponse<StaffRateDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, StaffRateService.ApiStaffRateSearchAllGetPath, 'get');
    if (params) {
      rb.query('searchTerm', params.searchTerm, {});
      rb.query('archived', params.archived, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StaffRateDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStaffRateSearchAllGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStaffRateSearchAllGet$Json(params?: {
    searchTerm?: string;
    archived?: boolean;
  }): Observable<StaffRateDtoListBaseResponse> {

    return this.apiStaffRateSearchAllGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StaffRateDtoListBaseResponse>) => r.body as StaffRateDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiStaffRatePaginatedListGet
   */
  static readonly ApiStaffRatePaginatedListGetPath = '/api/StaffRate/paginated-list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStaffRatePaginatedListGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStaffRatePaginatedListGet$Plain$Response(params?: {
    Month?: number;
    Year?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<StaffRateDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, StaffRateService.ApiStaffRatePaginatedListGetPath, 'get');
    if (params) {
      rb.query('Month', params.Month, {});
      rb.query('Year', params.Year, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('GetAll', params.GetAll, {});
      rb.query('Archived', params.Archived, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('PropertyFilters', params.PropertyFilters, {});
      rb.query('OrderProperty', params.OrderProperty, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StaffRateDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStaffRatePaginatedListGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStaffRatePaginatedListGet$Plain(params?: {
    Month?: number;
    Year?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StaffRateDtoPagedResponseBaseResponse> {

    return this.apiStaffRatePaginatedListGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StaffRateDtoPagedResponseBaseResponse>) => r.body as StaffRateDtoPagedResponseBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStaffRatePaginatedListGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStaffRatePaginatedListGet$Json$Response(params?: {
    Month?: number;
    Year?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<StaffRateDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, StaffRateService.ApiStaffRatePaginatedListGetPath, 'get');
    if (params) {
      rb.query('Month', params.Month, {});
      rb.query('Year', params.Year, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('GetAll', params.GetAll, {});
      rb.query('Archived', params.Archived, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('PropertyFilters', params.PropertyFilters, {});
      rb.query('OrderProperty', params.OrderProperty, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StaffRateDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStaffRatePaginatedListGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStaffRatePaginatedListGet$Json(params?: {
    Month?: number;
    Year?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StaffRateDtoPagedResponseBaseResponse> {

    return this.apiStaffRatePaginatedListGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StaffRateDtoPagedResponseBaseResponse>) => r.body as StaffRateDtoPagedResponseBaseResponse)
    );
  }

  /**
   * Path part for operation apiStaffRateGetStaffRatePost
   */
  static readonly ApiStaffRateGetStaffRatePostPath = '/api/StaffRate/get-staff-rate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStaffRateGetStaffRatePost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStaffRateGetStaffRatePost$Plain$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<StaffRateDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, StaffRateService.ApiStaffRateGetStaffRatePostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StaffRateDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStaffRateGetStaffRatePost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStaffRateGetStaffRatePost$Plain(params?: {
    id?: number;
  }): Observable<StaffRateDtoBaseResponse> {

    return this.apiStaffRateGetStaffRatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StaffRateDtoBaseResponse>) => r.body as StaffRateDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStaffRateGetStaffRatePost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStaffRateGetStaffRatePost$Json$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<StaffRateDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, StaffRateService.ApiStaffRateGetStaffRatePostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StaffRateDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStaffRateGetStaffRatePost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStaffRateGetStaffRatePost$Json(params?: {
    id?: number;
  }): Observable<StaffRateDtoBaseResponse> {

    return this.apiStaffRateGetStaffRatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StaffRateDtoBaseResponse>) => r.body as StaffRateDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiStaffRateAddStaffRatePost
   */
  static readonly ApiStaffRateAddStaffRatePostPath = '/api/StaffRate/add-staff-rate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStaffRateAddStaffRatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStaffRateAddStaffRatePost$Plain$Response(params?: {
    body?: StaffRateDto
  }): Observable<StrictHttpResponse<StaffRateDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, StaffRateService.ApiStaffRateAddStaffRatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StaffRateDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStaffRateAddStaffRatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStaffRateAddStaffRatePost$Plain(params?: {
    body?: StaffRateDto
  }): Observable<StaffRateDtoBaseResponse> {

    return this.apiStaffRateAddStaffRatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StaffRateDtoBaseResponse>) => r.body as StaffRateDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStaffRateAddStaffRatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStaffRateAddStaffRatePost$Json$Response(params?: {
    body?: StaffRateDto
  }): Observable<StrictHttpResponse<StaffRateDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, StaffRateService.ApiStaffRateAddStaffRatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StaffRateDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStaffRateAddStaffRatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStaffRateAddStaffRatePost$Json(params?: {
    body?: StaffRateDto
  }): Observable<StaffRateDtoBaseResponse> {

    return this.apiStaffRateAddStaffRatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StaffRateDtoBaseResponse>) => r.body as StaffRateDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiStaffRateUpdateStaffRatePost
   */
  static readonly ApiStaffRateUpdateStaffRatePostPath = '/api/StaffRate/update-staff-rate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStaffRateUpdateStaffRatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStaffRateUpdateStaffRatePost$Plain$Response(params?: {
    body?: StaffRateDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, StaffRateService.ApiStaffRateUpdateStaffRatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStaffRateUpdateStaffRatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStaffRateUpdateStaffRatePost$Plain(params?: {
    body?: StaffRateDto
  }): Observable<BooleanBaseResponse> {

    return this.apiStaffRateUpdateStaffRatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStaffRateUpdateStaffRatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStaffRateUpdateStaffRatePost$Json$Response(params?: {
    body?: StaffRateDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, StaffRateService.ApiStaffRateUpdateStaffRatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStaffRateUpdateStaffRatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStaffRateUpdateStaffRatePost$Json(params?: {
    body?: StaffRateDto
  }): Observable<BooleanBaseResponse> {

    return this.apiStaffRateUpdateStaffRatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiStaffRateToggleArchiveStaffRatePost
   */
  static readonly ApiStaffRateToggleArchiveStaffRatePostPath = '/api/StaffRate/toggle-archive-staff-rate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStaffRateToggleArchiveStaffRatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStaffRateToggleArchiveStaffRatePost$Plain$Response(params?: {
    isArchive?: boolean;
    body?: StaffRateDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, StaffRateService.ApiStaffRateToggleArchiveStaffRatePostPath, 'post');
    if (params) {
      rb.query('isArchive', params.isArchive, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStaffRateToggleArchiveStaffRatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStaffRateToggleArchiveStaffRatePost$Plain(params?: {
    isArchive?: boolean;
    body?: StaffRateDto
  }): Observable<BooleanBaseResponse> {

    return this.apiStaffRateToggleArchiveStaffRatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStaffRateToggleArchiveStaffRatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStaffRateToggleArchiveStaffRatePost$Json$Response(params?: {
    isArchive?: boolean;
    body?: StaffRateDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, StaffRateService.ApiStaffRateToggleArchiveStaffRatePostPath, 'post');
    if (params) {
      rb.query('isArchive', params.isArchive, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStaffRateToggleArchiveStaffRatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStaffRateToggleArchiveStaffRatePost$Json(params?: {
    isArchive?: boolean;
    body?: StaffRateDto
  }): Observable<BooleanBaseResponse> {

    return this.apiStaffRateToggleArchiveStaffRatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

}
