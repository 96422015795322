/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanBaseResponse } from '../models/boolean-base-response';
import { CompanyCommentDto } from '../models/company-comment-dto';
import { CompanyCommentDtoBaseResponse } from '../models/company-comment-dto-base-response';
import { CompanyCommentDtoListBaseResponse } from '../models/company-comment-dto-list-base-response';
import { CompanyDto } from '../models/company-dto';
import { CompanyDtoBaseResponse } from '../models/company-dto-base-response';
import { CompanyDtoListBaseResponse } from '../models/company-dto-list-base-response';
import { CompanyDtoPagedResponseBaseResponse } from '../models/company-dto-paged-response-base-response';
import { OrderDirection } from '../models/order-direction';
import { ProjectAddressDto } from '../models/project-address-dto';
import { PropertyFilter } from '../models/property-filter';

@Injectable({
  providedIn: 'root',
})
export class CompanyService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiCompanyPaginatedListGet
   */
  static readonly ApiCompanyPaginatedListGetPath = '/api/Company/paginated-list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyPaginatedListGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyPaginatedListGet$Plain$Response(params?: {
    Month?: number;
    Year?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<CompanyDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyPaginatedListGetPath, 'get');
    if (params) {
      rb.query('Month', params.Month, {});
      rb.query('Year', params.Year, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('GetAll', params.GetAll, {});
      rb.query('Archived', params.Archived, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('PropertyFilters', params.PropertyFilters, {});
      rb.query('OrderProperty', params.OrderProperty, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyPaginatedListGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyPaginatedListGet$Plain(params?: {
    Month?: number;
    Year?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<CompanyDtoPagedResponseBaseResponse> {

    return this.apiCompanyPaginatedListGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyDtoPagedResponseBaseResponse>) => r.body as CompanyDtoPagedResponseBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyPaginatedListGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyPaginatedListGet$Json$Response(params?: {
    Month?: number;
    Year?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<CompanyDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyPaginatedListGetPath, 'get');
    if (params) {
      rb.query('Month', params.Month, {});
      rb.query('Year', params.Year, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('GetAll', params.GetAll, {});
      rb.query('Archived', params.Archived, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('PropertyFilters', params.PropertyFilters, {});
      rb.query('OrderProperty', params.OrderProperty, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyPaginatedListGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyPaginatedListGet$Json(params?: {
    Month?: number;
    Year?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<CompanyDtoPagedResponseBaseResponse> {

    return this.apiCompanyPaginatedListGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyDtoPagedResponseBaseResponse>) => r.body as CompanyDtoPagedResponseBaseResponse)
    );
  }

  /**
   * Path part for operation apiCompanyGetAllGet
   */
  static readonly ApiCompanyGetAllGetPath = '/api/Company/get-all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyGetAllGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyGetAllGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<CompanyDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyGetAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyGetAllGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyGetAllGet$Plain(params?: {
  }): Observable<CompanyDtoListBaseResponse> {

    return this.apiCompanyGetAllGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyDtoListBaseResponse>) => r.body as CompanyDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyGetAllGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyGetAllGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<CompanyDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyGetAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyGetAllGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyGetAllGet$Json(params?: {
  }): Observable<CompanyDtoListBaseResponse> {

    return this.apiCompanyGetAllGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyDtoListBaseResponse>) => r.body as CompanyDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiCompanySearchAllGet
   */
  static readonly ApiCompanySearchAllGetPath = '/api/Company/search-all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanySearchAllGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanySearchAllGet$Plain$Response(params?: {
    searchTerm?: string;
    archived?: boolean;
  }): Observable<StrictHttpResponse<CompanyDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanySearchAllGetPath, 'get');
    if (params) {
      rb.query('searchTerm', params.searchTerm, {});
      rb.query('archived', params.archived, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanySearchAllGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanySearchAllGet$Plain(params?: {
    searchTerm?: string;
    archived?: boolean;
  }): Observable<CompanyDtoListBaseResponse> {

    return this.apiCompanySearchAllGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyDtoListBaseResponse>) => r.body as CompanyDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanySearchAllGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanySearchAllGet$Json$Response(params?: {
    searchTerm?: string;
    archived?: boolean;
  }): Observable<StrictHttpResponse<CompanyDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanySearchAllGetPath, 'get');
    if (params) {
      rb.query('searchTerm', params.searchTerm, {});
      rb.query('archived', params.archived, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanySearchAllGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanySearchAllGet$Json(params?: {
    searchTerm?: string;
    archived?: boolean;
  }): Observable<CompanyDtoListBaseResponse> {

    return this.apiCompanySearchAllGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyDtoListBaseResponse>) => r.body as CompanyDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiCompanyGetCompanyPost
   */
  static readonly ApiCompanyGetCompanyPostPath = '/api/Company/get-company';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyGetCompanyPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyGetCompanyPost$Plain$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<CompanyDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyGetCompanyPostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyGetCompanyPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyGetCompanyPost$Plain(params?: {
    id?: number;
  }): Observable<CompanyDtoBaseResponse> {

    return this.apiCompanyGetCompanyPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyDtoBaseResponse>) => r.body as CompanyDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyGetCompanyPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyGetCompanyPost$Json$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<CompanyDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyGetCompanyPostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyGetCompanyPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyGetCompanyPost$Json(params?: {
    id?: number;
  }): Observable<CompanyDtoBaseResponse> {

    return this.apiCompanyGetCompanyPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyDtoBaseResponse>) => r.body as CompanyDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiCompanyAddCompanyPost
   */
  static readonly ApiCompanyAddCompanyPostPath = '/api/Company/add-company';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyAddCompanyPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCompanyAddCompanyPost$Plain$Response(params?: {
    body?: CompanyDto
  }): Observable<StrictHttpResponse<CompanyDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyAddCompanyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyAddCompanyPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCompanyAddCompanyPost$Plain(params?: {
    body?: CompanyDto
  }): Observable<CompanyDtoBaseResponse> {

    return this.apiCompanyAddCompanyPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyDtoBaseResponse>) => r.body as CompanyDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyAddCompanyPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCompanyAddCompanyPost$Json$Response(params?: {
    body?: CompanyDto
  }): Observable<StrictHttpResponse<CompanyDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyAddCompanyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyAddCompanyPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCompanyAddCompanyPost$Json(params?: {
    body?: CompanyDto
  }): Observable<CompanyDtoBaseResponse> {

    return this.apiCompanyAddCompanyPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyDtoBaseResponse>) => r.body as CompanyDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiCompanyUpdateCompanyPost
   */
  static readonly ApiCompanyUpdateCompanyPostPath = '/api/Company/update-company';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyUpdateCompanyPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCompanyUpdateCompanyPost$Plain$Response(params?: {
    body?: CompanyDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyUpdateCompanyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyUpdateCompanyPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCompanyUpdateCompanyPost$Plain(params?: {
    body?: CompanyDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCompanyUpdateCompanyPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyUpdateCompanyPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCompanyUpdateCompanyPost$Json$Response(params?: {
    body?: CompanyDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyUpdateCompanyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyUpdateCompanyPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCompanyUpdateCompanyPost$Json(params?: {
    body?: CompanyDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCompanyUpdateCompanyPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCompanyToggleArchiveCompanyPost
   */
  static readonly ApiCompanyToggleArchiveCompanyPostPath = '/api/Company/toggle-archive-company';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyToggleArchiveCompanyPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCompanyToggleArchiveCompanyPost$Plain$Response(params?: {
    isArchive?: boolean;
    body?: CompanyDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyToggleArchiveCompanyPostPath, 'post');
    if (params) {
      rb.query('isArchive', params.isArchive, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyToggleArchiveCompanyPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCompanyToggleArchiveCompanyPost$Plain(params?: {
    isArchive?: boolean;
    body?: CompanyDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCompanyToggleArchiveCompanyPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyToggleArchiveCompanyPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCompanyToggleArchiveCompanyPost$Json$Response(params?: {
    isArchive?: boolean;
    body?: CompanyDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyToggleArchiveCompanyPostPath, 'post');
    if (params) {
      rb.query('isArchive', params.isArchive, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyToggleArchiveCompanyPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCompanyToggleArchiveCompanyPost$Json(params?: {
    isArchive?: boolean;
    body?: CompanyDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCompanyToggleArchiveCompanyPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCompanyAddCompanyAddressPost
   */
  static readonly ApiCompanyAddCompanyAddressPostPath = '/api/Company/add-company-address';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyAddCompanyAddressPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCompanyAddCompanyAddressPost$Plain$Response(params?: {
    body?: ProjectAddressDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyAddCompanyAddressPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyAddCompanyAddressPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCompanyAddCompanyAddressPost$Plain(params?: {
    body?: ProjectAddressDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCompanyAddCompanyAddressPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyAddCompanyAddressPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCompanyAddCompanyAddressPost$Json$Response(params?: {
    body?: ProjectAddressDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyAddCompanyAddressPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyAddCompanyAddressPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCompanyAddCompanyAddressPost$Json(params?: {
    body?: ProjectAddressDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCompanyAddCompanyAddressPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCompanyUpdateCompanyAddressPost
   */
  static readonly ApiCompanyUpdateCompanyAddressPostPath = '/api/Company/update-company-address';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyUpdateCompanyAddressPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCompanyUpdateCompanyAddressPost$Plain$Response(params?: {
    body?: ProjectAddressDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyUpdateCompanyAddressPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyUpdateCompanyAddressPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCompanyUpdateCompanyAddressPost$Plain(params?: {
    body?: ProjectAddressDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCompanyUpdateCompanyAddressPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyUpdateCompanyAddressPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCompanyUpdateCompanyAddressPost$Json$Response(params?: {
    body?: ProjectAddressDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyUpdateCompanyAddressPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyUpdateCompanyAddressPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCompanyUpdateCompanyAddressPost$Json(params?: {
    body?: ProjectAddressDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCompanyUpdateCompanyAddressPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCompanyArchiveCompanyAddressDelete
   */
  static readonly ApiCompanyArchiveCompanyAddressDeletePath = '/api/Company/archive-company-address';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyArchiveCompanyAddressDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyArchiveCompanyAddressDelete$Plain$Response(params?: {
    invoiceAddressId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyArchiveCompanyAddressDeletePath, 'delete');
    if (params) {
      rb.query('invoiceAddressId', params.invoiceAddressId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyArchiveCompanyAddressDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyArchiveCompanyAddressDelete$Plain(params?: {
    invoiceAddressId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiCompanyArchiveCompanyAddressDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyArchiveCompanyAddressDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyArchiveCompanyAddressDelete$Json$Response(params?: {
    invoiceAddressId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyArchiveCompanyAddressDeletePath, 'delete');
    if (params) {
      rb.query('invoiceAddressId', params.invoiceAddressId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyArchiveCompanyAddressDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyArchiveCompanyAddressDelete$Json(params?: {
    invoiceAddressId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiCompanyArchiveCompanyAddressDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCompanyCommentsGet
   */
  static readonly ApiCompanyCommentsGetPath = '/api/Company/comments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyCommentsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyCommentsGet$Plain$Response(params?: {
    companyId?: number;
  }): Observable<StrictHttpResponse<CompanyCommentDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyCommentsGetPath, 'get');
    if (params) {
      rb.query('companyId', params.companyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyCommentDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyCommentsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyCommentsGet$Plain(params?: {
    companyId?: number;
  }): Observable<CompanyCommentDtoListBaseResponse> {

    return this.apiCompanyCommentsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyCommentDtoListBaseResponse>) => r.body as CompanyCommentDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyCommentsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyCommentsGet$Json$Response(params?: {
    companyId?: number;
  }): Observable<StrictHttpResponse<CompanyCommentDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyCommentsGetPath, 'get');
    if (params) {
      rb.query('companyId', params.companyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyCommentDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyCommentsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyCommentsGet$Json(params?: {
    companyId?: number;
  }): Observable<CompanyCommentDtoListBaseResponse> {

    return this.apiCompanyCommentsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyCommentDtoListBaseResponse>) => r.body as CompanyCommentDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiCompanyAddCommentPost
   */
  static readonly ApiCompanyAddCommentPostPath = '/api/Company/add-comment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyAddCommentPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCompanyAddCommentPost$Plain$Response(params?: {
    body?: CompanyCommentDto
  }): Observable<StrictHttpResponse<CompanyCommentDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyAddCommentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyCommentDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyAddCommentPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCompanyAddCommentPost$Plain(params?: {
    body?: CompanyCommentDto
  }): Observable<CompanyCommentDtoBaseResponse> {

    return this.apiCompanyAddCommentPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyCommentDtoBaseResponse>) => r.body as CompanyCommentDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyAddCommentPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCompanyAddCommentPost$Json$Response(params?: {
    body?: CompanyCommentDto
  }): Observable<StrictHttpResponse<CompanyCommentDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyAddCommentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyCommentDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyAddCommentPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCompanyAddCommentPost$Json(params?: {
    body?: CompanyCommentDto
  }): Observable<CompanyCommentDtoBaseResponse> {

    return this.apiCompanyAddCommentPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyCommentDtoBaseResponse>) => r.body as CompanyCommentDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiCompanyUpdateCommentPut
   */
  static readonly ApiCompanyUpdateCommentPutPath = '/api/Company/update-comment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyUpdateCommentPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCompanyUpdateCommentPut$Plain$Response(params?: {
    body?: CompanyCommentDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyUpdateCommentPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyUpdateCommentPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCompanyUpdateCommentPut$Plain(params?: {
    body?: CompanyCommentDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCompanyUpdateCommentPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyUpdateCommentPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCompanyUpdateCommentPut$Json$Response(params?: {
    body?: CompanyCommentDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyUpdateCommentPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyUpdateCommentPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCompanyUpdateCommentPut$Json(params?: {
    body?: CompanyCommentDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCompanyUpdateCommentPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCompanyDeleteCommentDelete
   */
  static readonly ApiCompanyDeleteCommentDeletePath = '/api/Company/delete-comment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyDeleteCommentDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyDeleteCommentDelete$Plain$Response(params?: {
    commentId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyDeleteCommentDeletePath, 'delete');
    if (params) {
      rb.query('commentId', params.commentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyDeleteCommentDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyDeleteCommentDelete$Plain(params?: {
    commentId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiCompanyDeleteCommentDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyDeleteCommentDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyDeleteCommentDelete$Json$Response(params?: {
    commentId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyDeleteCommentDeletePath, 'delete');
    if (params) {
      rb.query('commentId', params.commentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyDeleteCommentDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyDeleteCommentDelete$Json(params?: {
    commentId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiCompanyDeleteCommentDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

}
