import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { EnumViewModel } from "app/api/models";

export class AddItemDialogData {
    name: string;
    icon?: string;
    initialValue?: string;
    type?: string;
    mandatory?: boolean;
    options?: EnumViewModel[]; //for select type only
}

@Component({
    selector       : 'add-item-dialog',
    templateUrl    : './add-item-dialog.component.html'
})
export class AddItemDialogComponent implements OnInit
{
    public addItemForm: FormGroup;

    title: string;
    formConfig: AddItemDialogData[];
    formValues: string[];

    showDeleteButton: boolean;
    overrideSaveText: string;

    constructor(
        public dialogRef: MatDialogRef<AddItemDialogComponent>,
        private _formBuilder: FormBuilder,
        private cdr: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: {title: string, items: AddItemDialogData[], showDeleteButton: boolean, overrideSaveText: string}
    )
    {}

    ngOnInit(): void
    {
        this.title = this.data.title;
        this.formConfig = this.data.items;
        this.showDeleteButton = this.data.showDeleteButton;
        this.overrideSaveText = this.data.overrideSaveText ?? 'Save';
        this.buildForm();
    }

    buildForm() {
        var group = {};

        this.formConfig.forEach(item => {
            group[item.name] = item.initialValue ?? '';
        });

        this.addItemForm = this._formBuilder.group(group);

        this.formConfig.filter(x => x.mandatory).forEach(item => this.addItemForm.controls[item.name].addValidators(Validators.required));

        this.cdr.detectChanges();
    }
    
    save() {
        this.dialogRef.close(this.addItemForm.value);
    }

    close() {
        this.dialogRef.close(null);
    }

    deleteItem() {
        this.dialogRef.close({delete: true});
    }
}