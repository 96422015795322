/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanBaseResponse } from '../models/boolean-base-response';
import { CrmDashboardDtoBaseResponse } from '../models/crm-dashboard-dto-base-response';
import { DealCommentDto } from '../models/deal-comment-dto';
import { DealCommentDtoBaseResponse } from '../models/deal-comment-dto-base-response';
import { DealCommentDtoListBaseResponse } from '../models/deal-comment-dto-list-base-response';
import { DealDto } from '../models/deal-dto';
import { DealDtoBaseResponse } from '../models/deal-dto-base-response';
import { DealDtoListBaseResponse } from '../models/deal-dto-list-base-response';
import { DealStageDetailDto } from '../models/deal-stage-detail-dto';
import { DealStageDetailDtoListBaseResponse } from '../models/deal-stage-detail-dto-list-base-response';
import { DealStageDto } from '../models/deal-stage-dto';
import { DealStageDtoBaseResponse } from '../models/deal-stage-dto-base-response';
import { DealStageDtoListBaseResponse } from '../models/deal-stage-dto-list-base-response';
import { PipelineSectionDto } from '../models/pipeline-section-dto';
import { PipelineSectionDtoBaseResponse } from '../models/pipeline-section-dto-base-response';
import { PipelineSectionDtoListBaseResponse } from '../models/pipeline-section-dto-list-base-response';
import { SalesPipelineForecastDtoBaseResponse } from '../models/sales-pipeline-forecast-dto-base-response';

@Injectable({
  providedIn: 'root',
})
export class DealService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiDealSectionsGet
   */
  static readonly ApiDealSectionsGetPath = '/api/Deal/sections';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealSectionsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealSectionsGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<PipelineSectionDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealSectionsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PipelineSectionDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealSectionsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealSectionsGet$Plain(params?: {
  }): Observable<PipelineSectionDtoListBaseResponse> {

    return this.apiDealSectionsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PipelineSectionDtoListBaseResponse>) => r.body as PipelineSectionDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealSectionsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealSectionsGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<PipelineSectionDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealSectionsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PipelineSectionDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealSectionsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealSectionsGet$Json(params?: {
  }): Observable<PipelineSectionDtoListBaseResponse> {

    return this.apiDealSectionsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PipelineSectionDtoListBaseResponse>) => r.body as PipelineSectionDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiDealCompanyDealsGet
   */
  static readonly ApiDealCompanyDealsGetPath = '/api/Deal/company-deals';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealCompanyDealsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealCompanyDealsGet$Plain$Response(params?: {
    companyId?: number;
  }): Observable<StrictHttpResponse<DealDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealCompanyDealsGetPath, 'get');
    if (params) {
      rb.query('companyId', params.companyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DealDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealCompanyDealsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealCompanyDealsGet$Plain(params?: {
    companyId?: number;
  }): Observable<DealDtoListBaseResponse> {

    return this.apiDealCompanyDealsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DealDtoListBaseResponse>) => r.body as DealDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealCompanyDealsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealCompanyDealsGet$Json$Response(params?: {
    companyId?: number;
  }): Observable<StrictHttpResponse<DealDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealCompanyDealsGetPath, 'get');
    if (params) {
      rb.query('companyId', params.companyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DealDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealCompanyDealsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealCompanyDealsGet$Json(params?: {
    companyId?: number;
  }): Observable<DealDtoListBaseResponse> {

    return this.apiDealCompanyDealsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DealDtoListBaseResponse>) => r.body as DealDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiDealAddSectionPost
   */
  static readonly ApiDealAddSectionPostPath = '/api/Deal/add-section';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealAddSectionPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDealAddSectionPost$Plain$Response(params?: {
    body?: PipelineSectionDto
  }): Observable<StrictHttpResponse<PipelineSectionDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealAddSectionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PipelineSectionDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealAddSectionPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDealAddSectionPost$Plain(params?: {
    body?: PipelineSectionDto
  }): Observable<PipelineSectionDtoBaseResponse> {

    return this.apiDealAddSectionPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PipelineSectionDtoBaseResponse>) => r.body as PipelineSectionDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealAddSectionPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDealAddSectionPost$Json$Response(params?: {
    body?: PipelineSectionDto
  }): Observable<StrictHttpResponse<PipelineSectionDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealAddSectionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PipelineSectionDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealAddSectionPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDealAddSectionPost$Json(params?: {
    body?: PipelineSectionDto
  }): Observable<PipelineSectionDtoBaseResponse> {

    return this.apiDealAddSectionPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PipelineSectionDtoBaseResponse>) => r.body as PipelineSectionDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiDealUpdateSectionPut
   */
  static readonly ApiDealUpdateSectionPutPath = '/api/Deal/update-section';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealUpdateSectionPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDealUpdateSectionPut$Plain$Response(params?: {
    body?: PipelineSectionDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealUpdateSectionPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealUpdateSectionPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDealUpdateSectionPut$Plain(params?: {
    body?: PipelineSectionDto
  }): Observable<BooleanBaseResponse> {

    return this.apiDealUpdateSectionPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealUpdateSectionPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDealUpdateSectionPut$Json$Response(params?: {
    body?: PipelineSectionDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealUpdateSectionPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealUpdateSectionPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDealUpdateSectionPut$Json(params?: {
    body?: PipelineSectionDto
  }): Observable<BooleanBaseResponse> {

    return this.apiDealUpdateSectionPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiDealArchiveSectionDelete
   */
  static readonly ApiDealArchiveSectionDeletePath = '/api/Deal/archive-section';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealArchiveSectionDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealArchiveSectionDelete$Plain$Response(params?: {
    pipelineSectionId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealArchiveSectionDeletePath, 'delete');
    if (params) {
      rb.query('pipelineSectionId', params.pipelineSectionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealArchiveSectionDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealArchiveSectionDelete$Plain(params?: {
    pipelineSectionId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiDealArchiveSectionDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealArchiveSectionDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealArchiveSectionDelete$Json$Response(params?: {
    pipelineSectionId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealArchiveSectionDeletePath, 'delete');
    if (params) {
      rb.query('pipelineSectionId', params.pipelineSectionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealArchiveSectionDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealArchiveSectionDelete$Json(params?: {
    pipelineSectionId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiDealArchiveSectionDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiDealDealGet
   */
  static readonly ApiDealDealGetPath = '/api/Deal/deal';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealDealGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealDealGet$Plain$Response(params?: {
    dealId?: number;
  }): Observable<StrictHttpResponse<DealDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealDealGetPath, 'get');
    if (params) {
      rb.query('dealId', params.dealId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DealDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealDealGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealDealGet$Plain(params?: {
    dealId?: number;
  }): Observable<DealDtoBaseResponse> {

    return this.apiDealDealGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DealDtoBaseResponse>) => r.body as DealDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealDealGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealDealGet$Json$Response(params?: {
    dealId?: number;
  }): Observable<StrictHttpResponse<DealDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealDealGetPath, 'get');
    if (params) {
      rb.query('dealId', params.dealId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DealDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealDealGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealDealGet$Json(params?: {
    dealId?: number;
  }): Observable<DealDtoBaseResponse> {

    return this.apiDealDealGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DealDtoBaseResponse>) => r.body as DealDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiDealAddDealPost
   */
  static readonly ApiDealAddDealPostPath = '/api/Deal/add-deal';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealAddDealPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDealAddDealPost$Plain$Response(params?: {
    body?: DealDto
  }): Observable<StrictHttpResponse<DealDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealAddDealPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DealDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealAddDealPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDealAddDealPost$Plain(params?: {
    body?: DealDto
  }): Observable<DealDtoBaseResponse> {

    return this.apiDealAddDealPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DealDtoBaseResponse>) => r.body as DealDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealAddDealPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDealAddDealPost$Json$Response(params?: {
    body?: DealDto
  }): Observable<StrictHttpResponse<DealDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealAddDealPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DealDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealAddDealPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDealAddDealPost$Json(params?: {
    body?: DealDto
  }): Observable<DealDtoBaseResponse> {

    return this.apiDealAddDealPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DealDtoBaseResponse>) => r.body as DealDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiDealUpdateDealPut
   */
  static readonly ApiDealUpdateDealPutPath = '/api/Deal/update-deal';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealUpdateDealPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDealUpdateDealPut$Plain$Response(params?: {
    body?: DealDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealUpdateDealPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealUpdateDealPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDealUpdateDealPut$Plain(params?: {
    body?: DealDto
  }): Observable<BooleanBaseResponse> {

    return this.apiDealUpdateDealPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealUpdateDealPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDealUpdateDealPut$Json$Response(params?: {
    body?: DealDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealUpdateDealPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealUpdateDealPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDealUpdateDealPut$Json(params?: {
    body?: DealDto
  }): Observable<BooleanBaseResponse> {

    return this.apiDealUpdateDealPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiDealDeleteDealDelete
   */
  static readonly ApiDealDeleteDealDeletePath = '/api/Deal/delete-deal';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealDeleteDealDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealDeleteDealDelete$Plain$Response(params?: {
    dealId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealDeleteDealDeletePath, 'delete');
    if (params) {
      rb.query('dealId', params.dealId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealDeleteDealDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealDeleteDealDelete$Plain(params?: {
    dealId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiDealDeleteDealDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealDeleteDealDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealDeleteDealDelete$Json$Response(params?: {
    dealId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealDeleteDealDeletePath, 'delete');
    if (params) {
      rb.query('dealId', params.dealId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealDeleteDealDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealDeleteDealDelete$Json(params?: {
    dealId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiDealDeleteDealDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiDealStagesGet
   */
  static readonly ApiDealStagesGetPath = '/api/Deal/stages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealStagesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealStagesGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<DealStageDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealStagesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DealStageDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealStagesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealStagesGet$Plain(params?: {
  }): Observable<DealStageDtoListBaseResponse> {

    return this.apiDealStagesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DealStageDtoListBaseResponse>) => r.body as DealStageDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealStagesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealStagesGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<DealStageDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealStagesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DealStageDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealStagesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealStagesGet$Json(params?: {
  }): Observable<DealStageDtoListBaseResponse> {

    return this.apiDealStagesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DealStageDtoListBaseResponse>) => r.body as DealStageDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiDealDealsByStageGet
   */
  static readonly ApiDealDealsByStageGetPath = '/api/Deal/deals-by-stage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealDealsByStageGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealDealsByStageGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<DealStageDetailDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealDealsByStageGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DealStageDetailDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealDealsByStageGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealDealsByStageGet$Plain(params?: {
  }): Observable<DealStageDetailDtoListBaseResponse> {

    return this.apiDealDealsByStageGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DealStageDetailDtoListBaseResponse>) => r.body as DealStageDetailDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealDealsByStageGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealDealsByStageGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<DealStageDetailDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealDealsByStageGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DealStageDetailDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealDealsByStageGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealDealsByStageGet$Json(params?: {
  }): Observable<DealStageDetailDtoListBaseResponse> {

    return this.apiDealDealsByStageGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DealStageDetailDtoListBaseResponse>) => r.body as DealStageDetailDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiDealAddStagePost
   */
  static readonly ApiDealAddStagePostPath = '/api/Deal/add-stage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealAddStagePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDealAddStagePost$Plain$Response(params?: {
    body?: DealStageDto
  }): Observable<StrictHttpResponse<DealStageDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealAddStagePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DealStageDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealAddStagePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDealAddStagePost$Plain(params?: {
    body?: DealStageDto
  }): Observable<DealStageDtoBaseResponse> {

    return this.apiDealAddStagePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DealStageDtoBaseResponse>) => r.body as DealStageDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealAddStagePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDealAddStagePost$Json$Response(params?: {
    body?: DealStageDto
  }): Observable<StrictHttpResponse<DealStageDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealAddStagePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DealStageDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealAddStagePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDealAddStagePost$Json(params?: {
    body?: DealStageDto
  }): Observable<DealStageDtoBaseResponse> {

    return this.apiDealAddStagePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DealStageDtoBaseResponse>) => r.body as DealStageDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiDealUpdateStagePut
   */
  static readonly ApiDealUpdateStagePutPath = '/api/Deal/update-stage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealUpdateStagePut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDealUpdateStagePut$Plain$Response(params?: {
    body?: DealStageDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealUpdateStagePutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealUpdateStagePut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDealUpdateStagePut$Plain(params?: {
    body?: DealStageDto
  }): Observable<BooleanBaseResponse> {

    return this.apiDealUpdateStagePut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealUpdateStagePut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDealUpdateStagePut$Json$Response(params?: {
    body?: DealStageDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealUpdateStagePutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealUpdateStagePut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDealUpdateStagePut$Json(params?: {
    body?: DealStageDto
  }): Observable<BooleanBaseResponse> {

    return this.apiDealUpdateStagePut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiDealDeleteStageDelete
   */
  static readonly ApiDealDeleteStageDeletePath = '/api/Deal/delete-stage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealDeleteStageDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealDeleteStageDelete$Plain$Response(params?: {
    stageId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealDeleteStageDeletePath, 'delete');
    if (params) {
      rb.query('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealDeleteStageDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealDeleteStageDelete$Plain(params?: {
    stageId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiDealDeleteStageDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealDeleteStageDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealDeleteStageDelete$Json$Response(params?: {
    stageId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealDeleteStageDeletePath, 'delete');
    if (params) {
      rb.query('stageId', params.stageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealDeleteStageDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealDeleteStageDelete$Json(params?: {
    stageId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiDealDeleteStageDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiDealForecastGet
   */
  static readonly ApiDealForecastGetPath = '/api/Deal/forecast';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealForecastGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealForecastGet$Plain$Response(params?: {
    startDate?: string;
  }): Observable<StrictHttpResponse<SalesPipelineForecastDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealForecastGetPath, 'get');
    if (params) {
      rb.query('startDate', params.startDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SalesPipelineForecastDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealForecastGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealForecastGet$Plain(params?: {
    startDate?: string;
  }): Observable<SalesPipelineForecastDtoBaseResponse> {

    return this.apiDealForecastGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SalesPipelineForecastDtoBaseResponse>) => r.body as SalesPipelineForecastDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealForecastGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealForecastGet$Json$Response(params?: {
    startDate?: string;
  }): Observable<StrictHttpResponse<SalesPipelineForecastDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealForecastGetPath, 'get');
    if (params) {
      rb.query('startDate', params.startDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SalesPipelineForecastDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealForecastGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealForecastGet$Json(params?: {
    startDate?: string;
  }): Observable<SalesPipelineForecastDtoBaseResponse> {

    return this.apiDealForecastGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SalesPipelineForecastDtoBaseResponse>) => r.body as SalesPipelineForecastDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiDealDashboardStatsGet
   */
  static readonly ApiDealDashboardStatsGetPath = '/api/Deal/dashboard-stats';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealDashboardStatsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealDashboardStatsGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<CrmDashboardDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealDashboardStatsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CrmDashboardDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealDashboardStatsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealDashboardStatsGet$Plain(params?: {
  }): Observable<CrmDashboardDtoBaseResponse> {

    return this.apiDealDashboardStatsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CrmDashboardDtoBaseResponse>) => r.body as CrmDashboardDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealDashboardStatsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealDashboardStatsGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<CrmDashboardDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealDashboardStatsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CrmDashboardDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealDashboardStatsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealDashboardStatsGet$Json(params?: {
  }): Observable<CrmDashboardDtoBaseResponse> {

    return this.apiDealDashboardStatsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CrmDashboardDtoBaseResponse>) => r.body as CrmDashboardDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiDealCommentsGet
   */
  static readonly ApiDealCommentsGetPath = '/api/Deal/comments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealCommentsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealCommentsGet$Plain$Response(params?: {
    dealId?: number;
  }): Observable<StrictHttpResponse<DealCommentDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealCommentsGetPath, 'get');
    if (params) {
      rb.query('dealId', params.dealId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DealCommentDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealCommentsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealCommentsGet$Plain(params?: {
    dealId?: number;
  }): Observable<DealCommentDtoListBaseResponse> {

    return this.apiDealCommentsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DealCommentDtoListBaseResponse>) => r.body as DealCommentDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealCommentsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealCommentsGet$Json$Response(params?: {
    dealId?: number;
  }): Observable<StrictHttpResponse<DealCommentDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealCommentsGetPath, 'get');
    if (params) {
      rb.query('dealId', params.dealId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DealCommentDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealCommentsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealCommentsGet$Json(params?: {
    dealId?: number;
  }): Observable<DealCommentDtoListBaseResponse> {

    return this.apiDealCommentsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DealCommentDtoListBaseResponse>) => r.body as DealCommentDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiDealAddCommentPost
   */
  static readonly ApiDealAddCommentPostPath = '/api/Deal/add-comment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealAddCommentPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDealAddCommentPost$Plain$Response(params?: {
    body?: DealCommentDto
  }): Observable<StrictHttpResponse<DealCommentDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealAddCommentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DealCommentDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealAddCommentPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDealAddCommentPost$Plain(params?: {
    body?: DealCommentDto
  }): Observable<DealCommentDtoBaseResponse> {

    return this.apiDealAddCommentPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DealCommentDtoBaseResponse>) => r.body as DealCommentDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealAddCommentPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDealAddCommentPost$Json$Response(params?: {
    body?: DealCommentDto
  }): Observable<StrictHttpResponse<DealCommentDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealAddCommentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DealCommentDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealAddCommentPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDealAddCommentPost$Json(params?: {
    body?: DealCommentDto
  }): Observable<DealCommentDtoBaseResponse> {

    return this.apiDealAddCommentPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DealCommentDtoBaseResponse>) => r.body as DealCommentDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiDealUpdateCommentPut
   */
  static readonly ApiDealUpdateCommentPutPath = '/api/Deal/update-comment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealUpdateCommentPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDealUpdateCommentPut$Plain$Response(params?: {
    body?: DealCommentDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealUpdateCommentPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealUpdateCommentPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDealUpdateCommentPut$Plain(params?: {
    body?: DealCommentDto
  }): Observable<BooleanBaseResponse> {

    return this.apiDealUpdateCommentPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealUpdateCommentPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDealUpdateCommentPut$Json$Response(params?: {
    body?: DealCommentDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealUpdateCommentPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealUpdateCommentPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDealUpdateCommentPut$Json(params?: {
    body?: DealCommentDto
  }): Observable<BooleanBaseResponse> {

    return this.apiDealUpdateCommentPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiDealDeleteCommentDelete
   */
  static readonly ApiDealDeleteCommentDeletePath = '/api/Deal/delete-comment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealDeleteCommentDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealDeleteCommentDelete$Plain$Response(params?: {
    commentId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealDeleteCommentDeletePath, 'delete');
    if (params) {
      rb.query('commentId', params.commentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealDeleteCommentDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealDeleteCommentDelete$Plain(params?: {
    commentId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiDealDeleteCommentDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealDeleteCommentDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealDeleteCommentDelete$Json$Response(params?: {
    commentId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealDeleteCommentDeletePath, 'delete');
    if (params) {
      rb.query('commentId', params.commentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealDeleteCommentDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDealDeleteCommentDelete$Json(params?: {
    commentId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiDealDeleteCommentDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiDealUpdateSwimlanesPut
   */
  static readonly ApiDealUpdateSwimlanesPutPath = '/api/Deal/update-swimlanes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealUpdateSwimlanesPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDealUpdateSwimlanesPut$Plain$Response(params?: {
    body?: Array<DealStageDetailDto>
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealUpdateSwimlanesPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealUpdateSwimlanesPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDealUpdateSwimlanesPut$Plain(params?: {
    body?: Array<DealStageDetailDto>
  }): Observable<BooleanBaseResponse> {

    return this.apiDealUpdateSwimlanesPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealUpdateSwimlanesPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDealUpdateSwimlanesPut$Json$Response(params?: {
    body?: Array<DealStageDetailDto>
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealUpdateSwimlanesPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealUpdateSwimlanesPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDealUpdateSwimlanesPut$Json(params?: {
    body?: Array<DealStageDetailDto>
  }): Observable<BooleanBaseResponse> {

    return this.apiDealUpdateSwimlanesPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiDealUpdateCardPositionsPut
   */
  static readonly ApiDealUpdateCardPositionsPutPath = '/api/Deal/update-card-positions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealUpdateCardPositionsPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDealUpdateCardPositionsPut$Plain$Response(params?: {
    body?: Array<DealDto>
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealUpdateCardPositionsPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealUpdateCardPositionsPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDealUpdateCardPositionsPut$Plain(params?: {
    body?: Array<DealDto>
  }): Observable<BooleanBaseResponse> {

    return this.apiDealUpdateCardPositionsPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDealUpdateCardPositionsPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDealUpdateCardPositionsPut$Json$Response(params?: {
    body?: Array<DealDto>
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DealService.ApiDealUpdateCardPositionsPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDealUpdateCardPositionsPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDealUpdateCardPositionsPut$Json(params?: {
    body?: Array<DealDto>
  }): Observable<BooleanBaseResponse> {

    return this.apiDealUpdateCardPositionsPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

}
