/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ContactImageDtoBaseResponse } from '../models/contact-image-dto-base-response';
import { ContactImageDtoListBaseResponse } from '../models/contact-image-dto-list-base-response';

@Injectable({
  providedIn: 'root',
})
export class ContactImageService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiContactImageUploadImageContactIdPost
   */
  static readonly ApiContactImageUploadImageContactIdPostPath = '/api/ContactImage/upload-image/{contactId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContactImageUploadImageContactIdPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiContactImageUploadImageContactIdPost$Plain$Response(params: {
    contactId: number;
    filename?: string;
    body?: { 'formFile'?: Blob }
  }): Observable<StrictHttpResponse<ContactImageDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ContactImageService.ApiContactImageUploadImageContactIdPostPath, 'post');
    if (params) {
      rb.path('contactId', params.contactId, {});
      rb.query('filename', params.filename, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContactImageDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiContactImageUploadImageContactIdPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiContactImageUploadImageContactIdPost$Plain(params: {
    contactId: number;
    filename?: string;
    body?: { 'formFile'?: Blob }
  }): Observable<ContactImageDtoBaseResponse> {

    return this.apiContactImageUploadImageContactIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ContactImageDtoBaseResponse>) => r.body as ContactImageDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContactImageUploadImageContactIdPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiContactImageUploadImageContactIdPost$Json$Response(params: {
    contactId: number;
    filename?: string;
    body?: { 'formFile'?: Blob }
  }): Observable<StrictHttpResponse<ContactImageDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ContactImageService.ApiContactImageUploadImageContactIdPostPath, 'post');
    if (params) {
      rb.path('contactId', params.contactId, {});
      rb.query('filename', params.filename, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContactImageDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiContactImageUploadImageContactIdPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiContactImageUploadImageContactIdPost$Json(params: {
    contactId: number;
    filename?: string;
    body?: { 'formFile'?: Blob }
  }): Observable<ContactImageDtoBaseResponse> {

    return this.apiContactImageUploadImageContactIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ContactImageDtoBaseResponse>) => r.body as ContactImageDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiContactImageContactPhotosGet
   */
  static readonly ApiContactImageContactPhotosGetPath = '/api/ContactImage/contact-photos';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContactImageContactPhotosGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContactImageContactPhotosGet$Plain$Response(params?: {
    contactId?: number;
  }): Observable<StrictHttpResponse<ContactImageDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ContactImageService.ApiContactImageContactPhotosGetPath, 'get');
    if (params) {
      rb.query('contactId', params.contactId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContactImageDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiContactImageContactPhotosGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContactImageContactPhotosGet$Plain(params?: {
    contactId?: number;
  }): Observable<ContactImageDtoListBaseResponse> {

    return this.apiContactImageContactPhotosGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ContactImageDtoListBaseResponse>) => r.body as ContactImageDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContactImageContactPhotosGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContactImageContactPhotosGet$Json$Response(params?: {
    contactId?: number;
  }): Observable<StrictHttpResponse<ContactImageDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ContactImageService.ApiContactImageContactPhotosGetPath, 'get');
    if (params) {
      rb.query('contactId', params.contactId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContactImageDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiContactImageContactPhotosGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContactImageContactPhotosGet$Json(params?: {
    contactId?: number;
  }): Observable<ContactImageDtoListBaseResponse> {

    return this.apiContactImageContactPhotosGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ContactImageDtoListBaseResponse>) => r.body as ContactImageDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiContactImageGetImageContactImageIdGet
   */
  static readonly ApiContactImageGetImageContactImageIdGetPath = '/api/ContactImage/get-image/{contactImageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContactImageGetImageContactImageIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContactImageGetImageContactImageIdGet$Response(params: {
    contactImageId: number;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ContactImageService.ApiContactImageGetImageContactImageIdGetPath, 'get');
    if (params) {
      rb.path('contactImageId', params.contactImageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiContactImageGetImageContactImageIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContactImageGetImageContactImageIdGet(params: {
    contactImageId: number;
  }): Observable<string> {

    return this.apiContactImageGetImageContactImageIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiContactImageDeleteImageContactImageIdDelete
   */
  static readonly ApiContactImageDeleteImageContactImageIdDeletePath = '/api/ContactImage/delete-image/{contactImageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContactImageDeleteImageContactImageIdDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContactImageDeleteImageContactImageIdDelete$Plain$Response(params: {
    contactImageId: number;
  }): Observable<StrictHttpResponse<ContactImageDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ContactImageService.ApiContactImageDeleteImageContactImageIdDeletePath, 'delete');
    if (params) {
      rb.path('contactImageId', params.contactImageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContactImageDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiContactImageDeleteImageContactImageIdDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContactImageDeleteImageContactImageIdDelete$Plain(params: {
    contactImageId: number;
  }): Observable<ContactImageDtoBaseResponse> {

    return this.apiContactImageDeleteImageContactImageIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ContactImageDtoBaseResponse>) => r.body as ContactImageDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContactImageDeleteImageContactImageIdDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContactImageDeleteImageContactImageIdDelete$Json$Response(params: {
    contactImageId: number;
  }): Observable<StrictHttpResponse<ContactImageDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ContactImageService.ApiContactImageDeleteImageContactImageIdDeletePath, 'delete');
    if (params) {
      rb.path('contactImageId', params.contactImageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContactImageDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiContactImageDeleteImageContactImageIdDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContactImageDeleteImageContactImageIdDelete$Json(params: {
    contactImageId: number;
  }): Observable<ContactImageDtoBaseResponse> {

    return this.apiContactImageDeleteImageContactImageIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ContactImageDtoBaseResponse>) => r.body as ContactImageDtoBaseResponse)
    );
  }

}
