/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccountDtoPagedResponseBaseResponse } from '../models/account-dto-paged-response-base-response';
import { AuthenticationRequest } from '../models/authentication-request';
import { AuthenticationResponseBaseResponse } from '../models/authentication-response-base-response';
import { BooleanBaseResponse } from '../models/boolean-base-response';
import { BooleanValidatorResponse } from '../models/boolean-validator-response';
import { EnumViewModelListBaseResponse } from '../models/enum-view-model-list-base-response';
import { ForgotPasswordRequest } from '../models/forgot-password-request';
import { OrderDirection } from '../models/order-direction';
import { PropertyFilter } from '../models/property-filter';
import { RefreshTokenRequest } from '../models/refresh-token-request';
import { RegisterRequest } from '../models/register-request';
import { ResetPasswordRequest } from '../models/reset-password-request';
import { UserDto } from '../models/user-dto';
import { UserDtoListBaseResponse } from '../models/user-dto-list-base-response';
import { UserDtoValidatorResponse } from '../models/user-dto-validator-response';
import { UsersByStaffRateDtoListBaseResponse } from '../models/users-by-staff-rate-dto-list-base-response';

@Injectable({
  providedIn: 'root',
})
export class AccountService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiAccountAuthenticatePost
   */
  static readonly ApiAccountAuthenticatePostPath = '/api/Account/authenticate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountAuthenticatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountAuthenticatePost$Plain$Response(params?: {
    body?: AuthenticationRequest
  }): Observable<StrictHttpResponse<AuthenticationResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountAuthenticatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuthenticationResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountAuthenticatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountAuthenticatePost$Plain(params?: {
    body?: AuthenticationRequest
  }): Observable<AuthenticationResponseBaseResponse> {

    return this.apiAccountAuthenticatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AuthenticationResponseBaseResponse>) => r.body as AuthenticationResponseBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountAuthenticatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountAuthenticatePost$Json$Response(params?: {
    body?: AuthenticationRequest
  }): Observable<StrictHttpResponse<AuthenticationResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountAuthenticatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuthenticationResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountAuthenticatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountAuthenticatePost$Json(params?: {
    body?: AuthenticationRequest
  }): Observable<AuthenticationResponseBaseResponse> {

    return this.apiAccountAuthenticatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AuthenticationResponseBaseResponse>) => r.body as AuthenticationResponseBaseResponse)
    );
  }

  /**
   * Path part for operation apiAccountRegisterPost
   */
  static readonly ApiAccountRegisterPostPath = '/api/Account/register';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountRegisterPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountRegisterPost$Plain$Response(params?: {
    body?: RegisterRequest
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountRegisterPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountRegisterPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountRegisterPost$Plain(params?: {
    body?: RegisterRequest
  }): Observable<BooleanBaseResponse> {

    return this.apiAccountRegisterPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountRegisterPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountRegisterPost$Json$Response(params?: {
    body?: RegisterRequest
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountRegisterPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountRegisterPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountRegisterPost$Json(params?: {
    body?: RegisterRequest
  }): Observable<BooleanBaseResponse> {

    return this.apiAccountRegisterPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiAccountConfirmEmailGet
   */
  static readonly ApiAccountConfirmEmailGetPath = '/api/Account/confirm-email';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountConfirmEmailGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountConfirmEmailGet$Plain$Response(params?: {
    userId?: string;
    code?: string;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountConfirmEmailGetPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
      rb.query('code', params.code, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountConfirmEmailGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountConfirmEmailGet$Plain(params?: {
    userId?: string;
    code?: string;
  }): Observable<BooleanBaseResponse> {

    return this.apiAccountConfirmEmailGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountConfirmEmailGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountConfirmEmailGet$Json$Response(params?: {
    userId?: string;
    code?: string;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountConfirmEmailGetPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
      rb.query('code', params.code, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountConfirmEmailGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountConfirmEmailGet$Json(params?: {
    userId?: string;
    code?: string;
  }): Observable<BooleanBaseResponse> {

    return this.apiAccountConfirmEmailGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiAccountResendEmailPost
   */
  static readonly ApiAccountResendEmailPostPath = '/api/Account/resend-email';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountResendEmailPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountResendEmailPost$Plain$Response(params?: {
    code?: string;
    body?: UserDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountResendEmailPostPath, 'post');
    if (params) {
      rb.query('code', params.code, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountResendEmailPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountResendEmailPost$Plain(params?: {
    code?: string;
    body?: UserDto
  }): Observable<BooleanBaseResponse> {

    return this.apiAccountResendEmailPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountResendEmailPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountResendEmailPost$Json$Response(params?: {
    code?: string;
    body?: UserDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountResendEmailPostPath, 'post');
    if (params) {
      rb.query('code', params.code, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountResendEmailPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountResendEmailPost$Json(params?: {
    code?: string;
    body?: UserDto
  }): Observable<BooleanBaseResponse> {

    return this.apiAccountResendEmailPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiAccountForgotPasswordPost
   */
  static readonly ApiAccountForgotPasswordPostPath = '/api/Account/forgot-password';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountForgotPasswordPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountForgotPasswordPost$Plain$Response(params?: {
    body?: ForgotPasswordRequest
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountForgotPasswordPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountForgotPasswordPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountForgotPasswordPost$Plain(params?: {
    body?: ForgotPasswordRequest
  }): Observable<BooleanBaseResponse> {

    return this.apiAccountForgotPasswordPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountForgotPasswordPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountForgotPasswordPost$Json$Response(params?: {
    body?: ForgotPasswordRequest
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountForgotPasswordPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountForgotPasswordPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountForgotPasswordPost$Json(params?: {
    body?: ForgotPasswordRequest
  }): Observable<BooleanBaseResponse> {

    return this.apiAccountForgotPasswordPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiAccountResetPasswordPost
   */
  static readonly ApiAccountResetPasswordPostPath = '/api/Account/reset-password';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountResetPasswordPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountResetPasswordPost$Plain$Response(params?: {
    body?: ResetPasswordRequest
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountResetPasswordPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountResetPasswordPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountResetPasswordPost$Plain(params?: {
    body?: ResetPasswordRequest
  }): Observable<BooleanBaseResponse> {

    return this.apiAccountResetPasswordPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountResetPasswordPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountResetPasswordPost$Json$Response(params?: {
    body?: ResetPasswordRequest
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountResetPasswordPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountResetPasswordPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountResetPasswordPost$Json(params?: {
    body?: ResetPasswordRequest
  }): Observable<BooleanBaseResponse> {

    return this.apiAccountResetPasswordPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiAccountRefreshtokenPost
   */
  static readonly ApiAccountRefreshtokenPostPath = '/api/Account/refreshtoken';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountRefreshtokenPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountRefreshtokenPost$Plain$Response(params?: {
    body?: RefreshTokenRequest
  }): Observable<StrictHttpResponse<AuthenticationResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountRefreshtokenPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuthenticationResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountRefreshtokenPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountRefreshtokenPost$Plain(params?: {
    body?: RefreshTokenRequest
  }): Observable<AuthenticationResponseBaseResponse> {

    return this.apiAccountRefreshtokenPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AuthenticationResponseBaseResponse>) => r.body as AuthenticationResponseBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountRefreshtokenPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountRefreshtokenPost$Json$Response(params?: {
    body?: RefreshTokenRequest
  }): Observable<StrictHttpResponse<AuthenticationResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountRefreshtokenPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuthenticationResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountRefreshtokenPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountRefreshtokenPost$Json(params?: {
    body?: RefreshTokenRequest
  }): Observable<AuthenticationResponseBaseResponse> {

    return this.apiAccountRefreshtokenPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AuthenticationResponseBaseResponse>) => r.body as AuthenticationResponseBaseResponse)
    );
  }

  /**
   * Path part for operation apiAccountLogoutGet
   */
  static readonly ApiAccountLogoutGetPath = '/api/Account/logout';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountLogoutGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountLogoutGet$Plain$Response(params?: {
    userEmail?: string;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountLogoutGetPath, 'get');
    if (params) {
      rb.query('userEmail', params.userEmail, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountLogoutGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountLogoutGet$Plain(params?: {
    userEmail?: string;
  }): Observable<BooleanBaseResponse> {

    return this.apiAccountLogoutGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountLogoutGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountLogoutGet$Json$Response(params?: {
    userEmail?: string;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountLogoutGetPath, 'get');
    if (params) {
      rb.query('userEmail', params.userEmail, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountLogoutGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountLogoutGet$Json(params?: {
    userEmail?: string;
  }): Observable<BooleanBaseResponse> {

    return this.apiAccountLogoutGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiAccountPaginatedListGet
   */
  static readonly ApiAccountPaginatedListGetPath = '/api/Account/paginated-list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountPaginatedListGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountPaginatedListGet$Plain$Response(params?: {
    Month?: number;
    Year?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<AccountDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountPaginatedListGetPath, 'get');
    if (params) {
      rb.query('Month', params.Month, {});
      rb.query('Year', params.Year, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('GetAll', params.GetAll, {});
      rb.query('Archived', params.Archived, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('PropertyFilters', params.PropertyFilters, {});
      rb.query('OrderProperty', params.OrderProperty, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountPaginatedListGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountPaginatedListGet$Plain(params?: {
    Month?: number;
    Year?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<AccountDtoPagedResponseBaseResponse> {

    return this.apiAccountPaginatedListGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountDtoPagedResponseBaseResponse>) => r.body as AccountDtoPagedResponseBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountPaginatedListGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountPaginatedListGet$Json$Response(params?: {
    Month?: number;
    Year?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<AccountDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountPaginatedListGetPath, 'get');
    if (params) {
      rb.query('Month', params.Month, {});
      rb.query('Year', params.Year, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('GetAll', params.GetAll, {});
      rb.query('Archived', params.Archived, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('PropertyFilters', params.PropertyFilters, {});
      rb.query('OrderProperty', params.OrderProperty, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountPaginatedListGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountPaginatedListGet$Json(params?: {
    Month?: number;
    Year?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<AccountDtoPagedResponseBaseResponse> {

    return this.apiAccountPaginatedListGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountDtoPagedResponseBaseResponse>) => r.body as AccountDtoPagedResponseBaseResponse)
    );
  }

  /**
   * Path part for operation apiAccountUserEnumsGet
   */
  static readonly ApiAccountUserEnumsGetPath = '/api/Account/user-enums';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountUserEnumsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountUserEnumsGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<EnumViewModelListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountUserEnumsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EnumViewModelListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountUserEnumsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountUserEnumsGet$Plain(params?: {
  }): Observable<EnumViewModelListBaseResponse> {

    return this.apiAccountUserEnumsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EnumViewModelListBaseResponse>) => r.body as EnumViewModelListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountUserEnumsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountUserEnumsGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<EnumViewModelListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountUserEnumsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EnumViewModelListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountUserEnumsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountUserEnumsGet$Json(params?: {
  }): Observable<EnumViewModelListBaseResponse> {

    return this.apiAccountUserEnumsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EnumViewModelListBaseResponse>) => r.body as EnumViewModelListBaseResponse)
    );
  }

  /**
   * Path part for operation apiAccountSearchAllGet
   */
  static readonly ApiAccountSearchAllGetPath = '/api/Account/search-all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountSearchAllGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountSearchAllGet$Plain$Response(params?: {
    searchTerm?: string;
    archived?: boolean;
  }): Observable<StrictHttpResponse<UserDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountSearchAllGetPath, 'get');
    if (params) {
      rb.query('searchTerm', params.searchTerm, {});
      rb.query('archived', params.archived, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountSearchAllGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountSearchAllGet$Plain(params?: {
    searchTerm?: string;
    archived?: boolean;
  }): Observable<UserDtoListBaseResponse> {

    return this.apiAccountSearchAllGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<UserDtoListBaseResponse>) => r.body as UserDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountSearchAllGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountSearchAllGet$Json$Response(params?: {
    searchTerm?: string;
    archived?: boolean;
  }): Observable<StrictHttpResponse<UserDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountSearchAllGetPath, 'get');
    if (params) {
      rb.query('searchTerm', params.searchTerm, {});
      rb.query('archived', params.archived, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountSearchAllGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountSearchAllGet$Json(params?: {
    searchTerm?: string;
    archived?: boolean;
  }): Observable<UserDtoListBaseResponse> {

    return this.apiAccountSearchAllGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UserDtoListBaseResponse>) => r.body as UserDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiAccountGetUserPost
   */
  static readonly ApiAccountGetUserPostPath = '/api/Account/get-user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountGetUserPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountGetUserPost$Plain$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<UserDtoValidatorResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountGetUserPostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserDtoValidatorResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountGetUserPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountGetUserPost$Plain(params?: {
    id?: number;
  }): Observable<UserDtoValidatorResponse> {

    return this.apiAccountGetUserPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<UserDtoValidatorResponse>) => r.body as UserDtoValidatorResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountGetUserPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountGetUserPost$Json$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<UserDtoValidatorResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountGetUserPostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserDtoValidatorResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountGetUserPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountGetUserPost$Json(params?: {
    id?: number;
  }): Observable<UserDtoValidatorResponse> {

    return this.apiAccountGetUserPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UserDtoValidatorResponse>) => r.body as UserDtoValidatorResponse)
    );
  }

  /**
   * Path part for operation apiAccountAddUserPost
   */
  static readonly ApiAccountAddUserPostPath = '/api/Account/add-user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountAddUserPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountAddUserPost$Plain$Response(params?: {
    body?: UserDto
  }): Observable<StrictHttpResponse<BooleanValidatorResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountAddUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanValidatorResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountAddUserPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountAddUserPost$Plain(params?: {
    body?: UserDto
  }): Observable<BooleanValidatorResponse> {

    return this.apiAccountAddUserPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanValidatorResponse>) => r.body as BooleanValidatorResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountAddUserPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountAddUserPost$Json$Response(params?: {
    body?: UserDto
  }): Observable<StrictHttpResponse<BooleanValidatorResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountAddUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanValidatorResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountAddUserPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountAddUserPost$Json(params?: {
    body?: UserDto
  }): Observable<BooleanValidatorResponse> {

    return this.apiAccountAddUserPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanValidatorResponse>) => r.body as BooleanValidatorResponse)
    );
  }

  /**
   * Path part for operation apiAccountUpdateUserPost
   */
  static readonly ApiAccountUpdateUserPostPath = '/api/Account/update-user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountUpdateUserPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountUpdateUserPost$Plain$Response(params?: {
    currentUsername?: string;
    body?: UserDto
  }): Observable<StrictHttpResponse<BooleanValidatorResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountUpdateUserPostPath, 'post');
    if (params) {
      rb.query('currentUsername', params.currentUsername, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanValidatorResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountUpdateUserPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountUpdateUserPost$Plain(params?: {
    currentUsername?: string;
    body?: UserDto
  }): Observable<BooleanValidatorResponse> {

    return this.apiAccountUpdateUserPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanValidatorResponse>) => r.body as BooleanValidatorResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountUpdateUserPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountUpdateUserPost$Json$Response(params?: {
    currentUsername?: string;
    body?: UserDto
  }): Observable<StrictHttpResponse<BooleanValidatorResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountUpdateUserPostPath, 'post');
    if (params) {
      rb.query('currentUsername', params.currentUsername, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanValidatorResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountUpdateUserPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountUpdateUserPost$Json(params?: {
    currentUsername?: string;
    body?: UserDto
  }): Observable<BooleanValidatorResponse> {

    return this.apiAccountUpdateUserPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanValidatorResponse>) => r.body as BooleanValidatorResponse)
    );
  }

  /**
   * Path part for operation apiAccountArchiveUserPost
   */
  static readonly ApiAccountArchiveUserPostPath = '/api/Account/archive-user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountArchiveUserPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountArchiveUserPost$Plain$Response(params?: {
    currentUsername?: string;
    archived?: boolean;
    body?: UserDto
  }): Observable<StrictHttpResponse<BooleanValidatorResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountArchiveUserPostPath, 'post');
    if (params) {
      rb.query('currentUsername', params.currentUsername, {});
      rb.query('archived', params.archived, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanValidatorResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountArchiveUserPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountArchiveUserPost$Plain(params?: {
    currentUsername?: string;
    archived?: boolean;
    body?: UserDto
  }): Observable<BooleanValidatorResponse> {

    return this.apiAccountArchiveUserPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanValidatorResponse>) => r.body as BooleanValidatorResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountArchiveUserPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountArchiveUserPost$Json$Response(params?: {
    currentUsername?: string;
    archived?: boolean;
    body?: UserDto
  }): Observable<StrictHttpResponse<BooleanValidatorResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountArchiveUserPostPath, 'post');
    if (params) {
      rb.query('currentUsername', params.currentUsername, {});
      rb.query('archived', params.archived, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanValidatorResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountArchiveUserPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountArchiveUserPost$Json(params?: {
    currentUsername?: string;
    archived?: boolean;
    body?: UserDto
  }): Observable<BooleanValidatorResponse> {

    return this.apiAccountArchiveUserPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanValidatorResponse>) => r.body as BooleanValidatorResponse)
    );
  }

  /**
   * Path part for operation apiAccountAllUsersByStaffRateGet
   */
  static readonly ApiAccountAllUsersByStaffRateGetPath = '/api/Account/all-users-by-staff-rate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountAllUsersByStaffRateGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountAllUsersByStaffRateGet$Plain$Response(params?: {
    includeUsersWithoutStaffRate?: boolean;
  }): Observable<StrictHttpResponse<UsersByStaffRateDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountAllUsersByStaffRateGetPath, 'get');
    if (params) {
      rb.query('includeUsersWithoutStaffRate', params.includeUsersWithoutStaffRate, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UsersByStaffRateDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountAllUsersByStaffRateGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountAllUsersByStaffRateGet$Plain(params?: {
    includeUsersWithoutStaffRate?: boolean;
  }): Observable<UsersByStaffRateDtoListBaseResponse> {

    return this.apiAccountAllUsersByStaffRateGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<UsersByStaffRateDtoListBaseResponse>) => r.body as UsersByStaffRateDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountAllUsersByStaffRateGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountAllUsersByStaffRateGet$Json$Response(params?: {
    includeUsersWithoutStaffRate?: boolean;
  }): Observable<StrictHttpResponse<UsersByStaffRateDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountAllUsersByStaffRateGetPath, 'get');
    if (params) {
      rb.query('includeUsersWithoutStaffRate', params.includeUsersWithoutStaffRate, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UsersByStaffRateDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountAllUsersByStaffRateGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountAllUsersByStaffRateGet$Json(params?: {
    includeUsersWithoutStaffRate?: boolean;
  }): Observable<UsersByStaffRateDtoListBaseResponse> {

    return this.apiAccountAllUsersByStaffRateGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UsersByStaffRateDtoListBaseResponse>) => r.body as UsersByStaffRateDtoListBaseResponse)
    );
  }

}
