import { Injectable } from "@angular/core";
import { SettingsService } from '../../../../app/api/services';
import { ApplicationThemeDto } from "../../../api/models/application-theme-dto";
import { firstValueFrom } from "rxjs";
import { generatePalette } from './generate-palete'
import { environment } from "environments/environment";
import { ApplicationInfoDto, AppThemeImageType } from "app/api/models";

@Injectable({providedIn: 'root'})
export class ThemeService
{
    env = environment;
    theme: ApplicationThemeDto;
    appInfo: ApplicationInfoDto;

    hasLoadedTheme: boolean = false;
    hasLoadedAppInfo: boolean = false;

    constructor(private settingsService: SettingsService)
    {
        this.reloadTheme();
        // if(!this.env.company) //uncomment this to make environment site info override any user-set info
        this.reloadAppInfo();
    }

    public async reloadTheme() {
        console.log("reloading theme");
        var res = await firstValueFrom(this.settingsService.apiSettingsThemeGet$Json$Response());
        if(!res?.body?.data) return null;

        this.theme = res.body.data;
        this.hasLoadedTheme = true;

        // if(!this.env.theme) //uncomment this to make environment theme override any user-chosen colours
        this.changeThemeConfig(this.theme.primaryColour, this.theme.menuColour);

        if(this.theme.hasCompanyLogo)
          this.getCompanyImage(AppThemeImageType.CompanyLogo);
        if(this.theme.hasLoginLogo)
          this.getCompanyImage(AppThemeImageType.LoginLogo);
        if(this.theme.hasCoverLogo)
          this.getCompanyImage(AppThemeImageType.CoverLogo);
        if(this.theme.hasCoverBackground)
          this.getCompanyImage(AppThemeImageType.CoverBackground);

        return this.theme;
    }

    public async reloadAppInfo() {
        console.log("reloading app info");
        var res = await firstValueFrom(this.settingsService.apiSettingsAppInfoGet$Json$Response());
        if(!res?.body?.data) return null;

        this.appInfo = res.body.data;
        this.hasLoadedAppInfo = true;

        return this.appInfo;
    }

    public getCompanyLogo() {
        return this.theme?.companyLogo;
    }

    public getCompanyLogoDataUrl() {
        return this.theme?.companyLogoDataUrl;
    }

    public getLoginLogo() {
        return this.theme?.loginLogo;
    }

    public getCoverLogo() {
        return this.theme?.coverLogo;
    }

    public getCoverBackground() {
        return this.theme?.coverBackground;
    }

    public getDimCoverBackground() {
        return this.theme?.dimCoverBackground;
    }

    public getPrimaryColour() {
        return this.theme?.primaryColour;
    }

    public getMenuColour() {
        return this.theme?.menuColour;
    }

    public getCompanyName() {
        return this.appInfo?.companyName;
    }

    public getSiteName() {
        return this.appInfo?.siteName;
    }

    public isThemeLoaded() {
        return this.hasLoadedTheme;
    }

    public isAppInfoLoaded() {
        return this.hasLoadedAppInfo;
    }

    public changeThemeConfig(primaryColour: string, menuColour: string) {
      var newPalette = generatePalette(this.theme.primaryColour);
      console.log("palette", newPalette);

      var themeRoot = document.body;//<HTMLElement>document.getElementsByClassName(this.env.theme)[0];

      for(var col of [50, 100, 200, 300, 500, 600, 700, 800, 900]) {
        themeRoot.style.setProperty(`--fuse-primary-${col}`, newPalette[col]);
        themeRoot.style.setProperty(`--fuse-primary-${col}-rgb`, this.hexToRGB(newPalette[col]));
      }
      themeRoot.style.setProperty("--fuse-primary", this.theme.primaryColour);
      themeRoot.style.setProperty("--fuse-primary-400", this.theme.menuColour);
      themeRoot.style.setProperty("--fuse-primary-rgb", this.hexToRGB(this.theme.primaryColour));
      themeRoot.style.setProperty("--fuse-primary-400-rgb", this.hexToRGB(this.theme.menuColour));
    }

    getCompanyImage(type: AppThemeImageType) {
      this.settingsService.apiSettingsGetCompanyImageGet$Response({imageType: type}).subscribe(res => {
        if(!res?.body) return;

        var image = res.body;

        var imageUrl : string;
        const reader = new FileReader();
        reader.onloadend = () => {
          imageUrl = reader.result as string;

          switch(type) {
            case AppThemeImageType.CompanyLogo:
              this.theme.companyLogo = imageUrl;
              break;
            case AppThemeImageType.LoginLogo:
              this.theme.loginLogo = imageUrl;
              break;
            case AppThemeImageType.CoverLogo:
              this.theme.coverLogo = imageUrl;
              break;
            case AppThemeImageType.CoverBackground:
              this.theme.coverBackground = imageUrl;
              break;
          }
        };
        reader.readAsDataURL(new Blob([image]));
      });

    }

    private hexToRGB(hex: string) {
      var r = parseInt(hex.slice(1,3), 16);
      var g = parseInt(hex.slice(3,5), 16);
      var b = parseInt(hex.slice(5,7), 16);
      return [r,g,b].join(',');
    }

    private base64ImageToFile(uri: string): File {
      if(!uri) return null;

      const parts = uri.split(';base64,');
      const imageType = parts[0].split(':')[1];
      const byteString = window.atob(parts[1]);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([int8Array], { type: imageType });
      return new File([blob], 'logo.png', { type: imageType });
    }

}
