/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Int64ImageDataDtoDictionaryBaseResponse } from '../models/int-64-image-data-dto-dictionary-base-response';
import { UserImageDtoBaseResponse } from '../models/user-image-dto-base-response';
import { UserImageDtoListBaseResponse } from '../models/user-image-dto-list-base-response';

@Injectable({
  providedIn: 'root',
})
export class UserImageService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiUserImageUploadImageUserIdPost
   */
  static readonly ApiUserImageUploadImageUserIdPostPath = '/api/UserImage/upload-image/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserImageUploadImageUserIdPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiUserImageUploadImageUserIdPost$Plain$Response(params: {
    userId: number;
    filename?: string;
    body?: { 'formFile'?: Blob }
  }): Observable<StrictHttpResponse<UserImageDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserImageService.ApiUserImageUploadImageUserIdPostPath, 'post');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.query('filename', params.filename, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserImageDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserImageUploadImageUserIdPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiUserImageUploadImageUserIdPost$Plain(params: {
    userId: number;
    filename?: string;
    body?: { 'formFile'?: Blob }
  }): Observable<UserImageDtoBaseResponse> {

    return this.apiUserImageUploadImageUserIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<UserImageDtoBaseResponse>) => r.body as UserImageDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserImageUploadImageUserIdPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiUserImageUploadImageUserIdPost$Json$Response(params: {
    userId: number;
    filename?: string;
    body?: { 'formFile'?: Blob }
  }): Observable<StrictHttpResponse<UserImageDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserImageService.ApiUserImageUploadImageUserIdPostPath, 'post');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.query('filename', params.filename, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserImageDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserImageUploadImageUserIdPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiUserImageUploadImageUserIdPost$Json(params: {
    userId: number;
    filename?: string;
    body?: { 'formFile'?: Blob }
  }): Observable<UserImageDtoBaseResponse> {

    return this.apiUserImageUploadImageUserIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UserImageDtoBaseResponse>) => r.body as UserImageDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiUserImageUserPhotosGet
   */
  static readonly ApiUserImageUserPhotosGetPath = '/api/UserImage/user-photos';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserImageUserPhotosGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserImageUserPhotosGet$Plain$Response(params?: {
    userId?: number;
  }): Observable<StrictHttpResponse<UserImageDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserImageService.ApiUserImageUserPhotosGetPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserImageDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserImageUserPhotosGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserImageUserPhotosGet$Plain(params?: {
    userId?: number;
  }): Observable<UserImageDtoListBaseResponse> {

    return this.apiUserImageUserPhotosGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<UserImageDtoListBaseResponse>) => r.body as UserImageDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserImageUserPhotosGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserImageUserPhotosGet$Json$Response(params?: {
    userId?: number;
  }): Observable<StrictHttpResponse<UserImageDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserImageService.ApiUserImageUserPhotosGetPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserImageDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserImageUserPhotosGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserImageUserPhotosGet$Json(params?: {
    userId?: number;
  }): Observable<UserImageDtoListBaseResponse> {

    return this.apiUserImageUserPhotosGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UserImageDtoListBaseResponse>) => r.body as UserImageDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiUserImageAllUserPhotosPost
   */
  static readonly ApiUserImageAllUserPhotosPostPath = '/api/UserImage/all-user-photos';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserImageAllUserPhotosPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserImageAllUserPhotosPost$Plain$Response(params?: {
    body?: Array<number>
  }): Observable<StrictHttpResponse<Int64ImageDataDtoDictionaryBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserImageService.ApiUserImageAllUserPhotosPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Int64ImageDataDtoDictionaryBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserImageAllUserPhotosPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserImageAllUserPhotosPost$Plain(params?: {
    body?: Array<number>
  }): Observable<Int64ImageDataDtoDictionaryBaseResponse> {

    return this.apiUserImageAllUserPhotosPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Int64ImageDataDtoDictionaryBaseResponse>) => r.body as Int64ImageDataDtoDictionaryBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserImageAllUserPhotosPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserImageAllUserPhotosPost$Json$Response(params?: {
    body?: Array<number>
  }): Observable<StrictHttpResponse<Int64ImageDataDtoDictionaryBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserImageService.ApiUserImageAllUserPhotosPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Int64ImageDataDtoDictionaryBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserImageAllUserPhotosPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserImageAllUserPhotosPost$Json(params?: {
    body?: Array<number>
  }): Observable<Int64ImageDataDtoDictionaryBaseResponse> {

    return this.apiUserImageAllUserPhotosPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Int64ImageDataDtoDictionaryBaseResponse>) => r.body as Int64ImageDataDtoDictionaryBaseResponse)
    );
  }

  /**
   * Path part for operation apiUserImageGetImageUserImageIdGet
   */
  static readonly ApiUserImageGetImageUserImageIdGetPath = '/api/UserImage/get-image/{userImageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserImageGetImageUserImageIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserImageGetImageUserImageIdGet$Response(params: {
    userImageId: number;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, UserImageService.ApiUserImageGetImageUserImageIdGetPath, 'get');
    if (params) {
      rb.path('userImageId', params.userImageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserImageGetImageUserImageIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserImageGetImageUserImageIdGet(params: {
    userImageId: number;
  }): Observable<string> {

    return this.apiUserImageGetImageUserImageIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiUserImageDeleteImageDelete
   */
  static readonly ApiUserImageDeleteImageDeletePath = '/api/UserImage/delete-image';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserImageDeleteImageDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserImageDeleteImageDelete$Plain$Response(params?: {
    userImageId?: number;
  }): Observable<StrictHttpResponse<UserImageDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserImageService.ApiUserImageDeleteImageDeletePath, 'delete');
    if (params) {
      rb.query('userImageId', params.userImageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserImageDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserImageDeleteImageDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserImageDeleteImageDelete$Plain(params?: {
    userImageId?: number;
  }): Observable<UserImageDtoBaseResponse> {

    return this.apiUserImageDeleteImageDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<UserImageDtoBaseResponse>) => r.body as UserImageDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserImageDeleteImageDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserImageDeleteImageDelete$Json$Response(params?: {
    userImageId?: number;
  }): Observable<StrictHttpResponse<UserImageDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserImageService.ApiUserImageDeleteImageDeletePath, 'delete');
    if (params) {
      rb.query('userImageId', params.userImageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserImageDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserImageDeleteImageDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserImageDeleteImageDelete$Json(params?: {
    userImageId?: number;
  }): Observable<UserImageDtoBaseResponse> {

    return this.apiUserImageDeleteImageDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UserImageDtoBaseResponse>) => r.body as UserImageDtoBaseResponse)
    );
  }

}
