<div fxLayout="column">
  <h1 matDialogTitle class="mb-8">{{ data.title }}</h1>
  <div mat-dialog-content class="mb-16">{{ data.message }}</div>

  <div
    fxLayout="row"
    fxLayoutAlign="center"
    fxLayoutGap="3rem"
    class="dialog-button-row"
  >
    <button
      mat-stroked-button
      color="primary"
      type="button"
      (click)="dialogRef.close(false)"
    >
      Cancel
    </button>
    <button mat-flat-button color="primary" (click)="dialogRef.close(true)">
      OK
    </button>
  </div>
</div>
