/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FileItemDto } from '../models/file-item-dto';
import { FileItemDtoBaseResponse } from '../models/file-item-dto-base-response';
import { FolderDto } from '../models/folder-dto';
import { FolderDtoBaseResponse } from '../models/folder-dto-base-response';

@Injectable({
  providedIn: 'root',
})
export class FileManagerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiFileManagerGetFolderGet
   */
  static readonly ApiFileManagerGetFolderGetPath = '/api/FileManager/get-folder';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFileManagerGetFolderGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFileManagerGetFolderGet$Plain$Response(params?: {
    folderId?: number;
  }): Observable<StrictHttpResponse<FolderDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FileManagerService.ApiFileManagerGetFolderGetPath, 'get');
    if (params) {
      rb.query('folderId', params.folderId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FolderDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFileManagerGetFolderGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFileManagerGetFolderGet$Plain(params?: {
    folderId?: number;
  }): Observable<FolderDtoBaseResponse> {

    return this.apiFileManagerGetFolderGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<FolderDtoBaseResponse>) => r.body as FolderDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFileManagerGetFolderGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFileManagerGetFolderGet$Json$Response(params?: {
    folderId?: number;
  }): Observable<StrictHttpResponse<FolderDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FileManagerService.ApiFileManagerGetFolderGetPath, 'get');
    if (params) {
      rb.query('folderId', params.folderId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FolderDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFileManagerGetFolderGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFileManagerGetFolderGet$Json(params?: {
    folderId?: number;
  }): Observable<FolderDtoBaseResponse> {

    return this.apiFileManagerGetFolderGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<FolderDtoBaseResponse>) => r.body as FolderDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiFileManagerCreateFolderPost
   */
  static readonly ApiFileManagerCreateFolderPostPath = '/api/FileManager/create-folder';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFileManagerCreateFolderPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFileManagerCreateFolderPost$Plain$Response(params?: {
    body?: FolderDto
  }): Observable<StrictHttpResponse<FolderDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FileManagerService.ApiFileManagerCreateFolderPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FolderDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFileManagerCreateFolderPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFileManagerCreateFolderPost$Plain(params?: {
    body?: FolderDto
  }): Observable<FolderDtoBaseResponse> {

    return this.apiFileManagerCreateFolderPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<FolderDtoBaseResponse>) => r.body as FolderDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFileManagerCreateFolderPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFileManagerCreateFolderPost$Json$Response(params?: {
    body?: FolderDto
  }): Observable<StrictHttpResponse<FolderDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FileManagerService.ApiFileManagerCreateFolderPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FolderDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFileManagerCreateFolderPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFileManagerCreateFolderPost$Json(params?: {
    body?: FolderDto
  }): Observable<FolderDtoBaseResponse> {

    return this.apiFileManagerCreateFolderPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<FolderDtoBaseResponse>) => r.body as FolderDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiFileManagerUpdateFolderPost
   */
  static readonly ApiFileManagerUpdateFolderPostPath = '/api/FileManager/update-folder';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFileManagerUpdateFolderPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFileManagerUpdateFolderPost$Plain$Response(params?: {
    body?: FolderDto
  }): Observable<StrictHttpResponse<FolderDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FileManagerService.ApiFileManagerUpdateFolderPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FolderDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFileManagerUpdateFolderPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFileManagerUpdateFolderPost$Plain(params?: {
    body?: FolderDto
  }): Observable<FolderDtoBaseResponse> {

    return this.apiFileManagerUpdateFolderPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<FolderDtoBaseResponse>) => r.body as FolderDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFileManagerUpdateFolderPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFileManagerUpdateFolderPost$Json$Response(params?: {
    body?: FolderDto
  }): Observable<StrictHttpResponse<FolderDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FileManagerService.ApiFileManagerUpdateFolderPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FolderDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFileManagerUpdateFolderPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFileManagerUpdateFolderPost$Json(params?: {
    body?: FolderDto
  }): Observable<FolderDtoBaseResponse> {

    return this.apiFileManagerUpdateFolderPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<FolderDtoBaseResponse>) => r.body as FolderDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiFileManagerDeleteFolderPost
   */
  static readonly ApiFileManagerDeleteFolderPostPath = '/api/FileManager/delete-folder';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFileManagerDeleteFolderPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFileManagerDeleteFolderPost$Plain$Response(params?: {
    body?: FolderDto
  }): Observable<StrictHttpResponse<FolderDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FileManagerService.ApiFileManagerDeleteFolderPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FolderDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFileManagerDeleteFolderPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFileManagerDeleteFolderPost$Plain(params?: {
    body?: FolderDto
  }): Observable<FolderDtoBaseResponse> {

    return this.apiFileManagerDeleteFolderPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<FolderDtoBaseResponse>) => r.body as FolderDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFileManagerDeleteFolderPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFileManagerDeleteFolderPost$Json$Response(params?: {
    body?: FolderDto
  }): Observable<StrictHttpResponse<FolderDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FileManagerService.ApiFileManagerDeleteFolderPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FolderDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFileManagerDeleteFolderPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFileManagerDeleteFolderPost$Json(params?: {
    body?: FolderDto
  }): Observable<FolderDtoBaseResponse> {

    return this.apiFileManagerDeleteFolderPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<FolderDtoBaseResponse>) => r.body as FolderDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiFileManagerGetFileGet
   */
  static readonly ApiFileManagerGetFileGetPath = '/api/FileManager/get-file';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFileManagerGetFileGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFileManagerGetFileGet$Plain$Response(params?: {
    fileId?: number;
  }): Observable<StrictHttpResponse<FileItemDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FileManagerService.ApiFileManagerGetFileGetPath, 'get');
    if (params) {
      rb.query('fileId', params.fileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FileItemDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFileManagerGetFileGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFileManagerGetFileGet$Plain(params?: {
    fileId?: number;
  }): Observable<FileItemDtoBaseResponse> {

    return this.apiFileManagerGetFileGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<FileItemDtoBaseResponse>) => r.body as FileItemDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFileManagerGetFileGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFileManagerGetFileGet$Json$Response(params?: {
    fileId?: number;
  }): Observable<StrictHttpResponse<FileItemDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FileManagerService.ApiFileManagerGetFileGetPath, 'get');
    if (params) {
      rb.query('fileId', params.fileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FileItemDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFileManagerGetFileGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFileManagerGetFileGet$Json(params?: {
    fileId?: number;
  }): Observable<FileItemDtoBaseResponse> {

    return this.apiFileManagerGetFileGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<FileItemDtoBaseResponse>) => r.body as FileItemDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiFileManagerUploadFileFolderIdPost
   */
  static readonly ApiFileManagerUploadFileFolderIdPostPath = '/api/FileManager/upload-file/{folderId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFileManagerUploadFileFolderIdPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiFileManagerUploadFileFolderIdPost$Plain$Response(params: {
    folderId: number;
    filename?: string;
    userId?: number;
    body?: { 'formFile'?: Blob }
  }): Observable<StrictHttpResponse<FileItemDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FileManagerService.ApiFileManagerUploadFileFolderIdPostPath, 'post');
    if (params) {
      rb.path('folderId', params.folderId, {});
      rb.query('filename', params.filename, {});
      rb.query('userId', params.userId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FileItemDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFileManagerUploadFileFolderIdPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiFileManagerUploadFileFolderIdPost$Plain(params: {
    folderId: number;
    filename?: string;
    userId?: number;
    body?: { 'formFile'?: Blob }
  }): Observable<FileItemDtoBaseResponse> {

    return this.apiFileManagerUploadFileFolderIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<FileItemDtoBaseResponse>) => r.body as FileItemDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFileManagerUploadFileFolderIdPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiFileManagerUploadFileFolderIdPost$Json$Response(params: {
    folderId: number;
    filename?: string;
    userId?: number;
    body?: { 'formFile'?: Blob }
  }): Observable<StrictHttpResponse<FileItemDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FileManagerService.ApiFileManagerUploadFileFolderIdPostPath, 'post');
    if (params) {
      rb.path('folderId', params.folderId, {});
      rb.query('filename', params.filename, {});
      rb.query('userId', params.userId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FileItemDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFileManagerUploadFileFolderIdPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiFileManagerUploadFileFolderIdPost$Json(params: {
    folderId: number;
    filename?: string;
    userId?: number;
    body?: { 'formFile'?: Blob }
  }): Observable<FileItemDtoBaseResponse> {

    return this.apiFileManagerUploadFileFolderIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<FileItemDtoBaseResponse>) => r.body as FileItemDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiFileManagerUpdateFilePost
   */
  static readonly ApiFileManagerUpdateFilePostPath = '/api/FileManager/update-file';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFileManagerUpdateFilePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFileManagerUpdateFilePost$Plain$Response(params?: {
    body?: FileItemDto
  }): Observable<StrictHttpResponse<FileItemDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FileManagerService.ApiFileManagerUpdateFilePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FileItemDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFileManagerUpdateFilePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFileManagerUpdateFilePost$Plain(params?: {
    body?: FileItemDto
  }): Observable<FileItemDtoBaseResponse> {

    return this.apiFileManagerUpdateFilePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<FileItemDtoBaseResponse>) => r.body as FileItemDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFileManagerUpdateFilePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFileManagerUpdateFilePost$Json$Response(params?: {
    body?: FileItemDto
  }): Observable<StrictHttpResponse<FileItemDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FileManagerService.ApiFileManagerUpdateFilePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FileItemDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFileManagerUpdateFilePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFileManagerUpdateFilePost$Json(params?: {
    body?: FileItemDto
  }): Observable<FileItemDtoBaseResponse> {

    return this.apiFileManagerUpdateFilePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<FileItemDtoBaseResponse>) => r.body as FileItemDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiFileManagerDownloadFileFileItemIdGet
   */
  static readonly ApiFileManagerDownloadFileFileItemIdGetPath = '/api/FileManager/download-file/{fileItemId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFileManagerDownloadFileFileItemIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFileManagerDownloadFileFileItemIdGet$Response(params: {
    fileItemId: number;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, FileManagerService.ApiFileManagerDownloadFileFileItemIdGetPath, 'get');
    if (params) {
      rb.path('fileItemId', params.fileItemId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFileManagerDownloadFileFileItemIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFileManagerDownloadFileFileItemIdGet(params: {
    fileItemId: number;
  }): Observable<string> {

    return this.apiFileManagerDownloadFileFileItemIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiFileManagerDeleteFileFileItemIdDelete
   */
  static readonly ApiFileManagerDeleteFileFileItemIdDeletePath = '/api/FileManager/delete-file/{fileItemId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFileManagerDeleteFileFileItemIdDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFileManagerDeleteFileFileItemIdDelete$Plain$Response(params: {
    fileItemId: number;
  }): Observable<StrictHttpResponse<FileItemDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FileManagerService.ApiFileManagerDeleteFileFileItemIdDeletePath, 'delete');
    if (params) {
      rb.path('fileItemId', params.fileItemId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FileItemDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFileManagerDeleteFileFileItemIdDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFileManagerDeleteFileFileItemIdDelete$Plain(params: {
    fileItemId: number;
  }): Observable<FileItemDtoBaseResponse> {

    return this.apiFileManagerDeleteFileFileItemIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<FileItemDtoBaseResponse>) => r.body as FileItemDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFileManagerDeleteFileFileItemIdDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFileManagerDeleteFileFileItemIdDelete$Json$Response(params: {
    fileItemId: number;
  }): Observable<StrictHttpResponse<FileItemDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FileManagerService.ApiFileManagerDeleteFileFileItemIdDeletePath, 'delete');
    if (params) {
      rb.path('fileItemId', params.fileItemId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FileItemDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFileManagerDeleteFileFileItemIdDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFileManagerDeleteFileFileItemIdDelete$Json(params: {
    fileItemId: number;
  }): Observable<FileItemDtoBaseResponse> {

    return this.apiFileManagerDeleteFileFileItemIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<FileItemDtoBaseResponse>) => r.body as FileItemDtoBaseResponse)
    );
  }

}
