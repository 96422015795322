import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CookieStoreService {
  constructor() {}

  public getCookie(name: string) {
    let ca: Array<string> = document.cookie.split(";");
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i++) {
      c = ca[i].replace(/^\s+/g, "");
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return undefined;
  }

  public deleteCookie(name) {
    this.setCookie(name, "", null);
  }

  public setCookie(name: string, value: string, expiresDate: Date | null) {
    let expires: string = "Session";

    if (expiresDate) {
      expires = `${expiresDate.toUTCString()}`;
    }

    document.cookie = `${name}=${value}; path=/; sameSite=strict; expires='${expires}';`;
  }

  public clearNotTodayReminderCookies() {
    let ca: Array<string> = document.cookie.split(";");
    let caLen: number = ca.length;
    let c: string;
    
    var now = new Date();
    var today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    console.log("before delete previouses", ca);

    for (let i: number = 0; i < caLen; i++) {
      c = ca[i].replace(/^\s+/g, "");

      const dregex = /\d{2}-\d{2}-\d{4}/g;
      const dfound = c.match(dregex);

      if (c.includes(`endOfDayDismissed-`) && dfound?.length == 0) {
        this.deleteCookie(c);
      }
    }
    
    console.log("after delete", ca);
  }
}
