import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { AppComfirmComponent } from "../services/app-confirm/app-confirm.component";
import { AppLoaderComponent } from "../services/app-loader/app-loader.component";
// import { CustomPagingFooter } from "./custom-paging-footer/custom-paging-footer.component";
import { SharedMaterialModule } from "../shared-material.module";
import { SharedDirectivesModule } from "../directives/shared-directives.module";
import { TextFieldModule } from "@angular/cdk/text-field";
import { CdkScrollableModule } from "@angular/cdk/scrolling";
import { CdkDrag, CdkDragHandle, CdkDropList, CdkDropListGroup } from "@angular/cdk/drag-drop";
import { CommentsComponent } from "./comments/comments.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { AddItemDialogComponent } from "./add-item-dialog/add-item-dialog.component";
import { SortChevronsComponent } from "./sort-chevrons/sort-chevrons.component";
// import { NgxDatatableModule } from "@swimlane/ngx-datatable";

const components = [
  // CustomPagingFooter,
  AppComfirmComponent,
  AppLoaderComponent,
  CommentsComponent,
  AddItemDialogComponent,
  SortChevronsComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    RouterModule,
    SharedMaterialModule,
    SharedDirectivesModule,
    TextFieldModule,
    CdkScrollableModule,
    CdkDropList,
    CdkDropListGroup,
    CdkDrag,
    CdkDragHandle,
    // NgxDatatableModule,
  ],
  declarations: components,
  exports: components,
})
export class SharedComponentsModule {}
