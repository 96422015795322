/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanBaseResponse } from '../models/boolean-base-response';
import { HcTeamMemberDto } from '../models/hc-team-member-dto';
import { JobSheetDto } from '../models/job-sheet-dto';
import { JobSheetDtoBaseResponse } from '../models/job-sheet-dto-base-response';
import { OrderDirection } from '../models/order-direction';
import { PaginationRequest } from '../models/pagination-request';
import { PointOfContactDto } from '../models/point-of-contact-dto';
import { ProjectAddressDto } from '../models/project-address-dto';
import { ProjectDashboardDto } from '../models/project-dashboard-dto';
import { ProjectDashboardDtoBaseResponse } from '../models/project-dashboard-dto-base-response';
import { ProjectDtoBaseResponse } from '../models/project-dto-base-response';
import { ProjectListDto } from '../models/project-list-dto';
import { ProjectListDtoBaseResponse } from '../models/project-list-dto-base-response';
import { ProjectListDtoListBaseResponse } from '../models/project-list-dto-list-base-response';
import { ProjectListDtoPagedResponseBaseResponse } from '../models/project-list-dto-paged-response-base-response';
import { ProjectStatusDto } from '../models/project-status-dto';
import { ProjectStatusDtoBaseResponse } from '../models/project-status-dto-base-response';
import { ProjectStatusDtoListBaseResponse } from '../models/project-status-dto-list-base-response';
import { ProjectsByClientDtoListBaseResponse } from '../models/projects-by-client-dto-list-base-response';
import { PropertyFilter } from '../models/property-filter';
import { TechSpecDto } from '../models/tech-spec-dto';
import { TechSpecDtoBaseResponse } from '../models/tech-spec-dto-base-response';
import { TechSpecLinkDto } from '../models/tech-spec-link-dto';
import { WorkloadMilestoneDto } from '../models/workload-milestone-dto';

@Injectable({
  providedIn: 'root',
})
export class ProjectService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiProjectListProjectsPost
   */
  static readonly ApiProjectListProjectsPostPath = '/api/Project/list-projects';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectListProjectsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectListProjectsPost$Plain$Response(params?: {
    body?: PaginationRequest
  }): Observable<StrictHttpResponse<ProjectListDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectListProjectsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectListDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectListProjectsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectListProjectsPost$Plain(params?: {
    body?: PaginationRequest
  }): Observable<ProjectListDtoPagedResponseBaseResponse> {

    return this.apiProjectListProjectsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectListDtoPagedResponseBaseResponse>) => r.body as ProjectListDtoPagedResponseBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectListProjectsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectListProjectsPost$Json$Response(params?: {
    body?: PaginationRequest
  }): Observable<StrictHttpResponse<ProjectListDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectListProjectsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectListDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectListProjectsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectListProjectsPost$Json(params?: {
    body?: PaginationRequest
  }): Observable<ProjectListDtoPagedResponseBaseResponse> {

    return this.apiProjectListProjectsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectListDtoPagedResponseBaseResponse>) => r.body as ProjectListDtoPagedResponseBaseResponse)
    );
  }

  /**
   * Path part for operation apiProjectListProjectsForCompanyGet
   */
  static readonly ApiProjectListProjectsForCompanyGetPath = '/api/Project/list-projects-for-company';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectListProjectsForCompanyGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectListProjectsForCompanyGet$Plain$Response(params?: {
    CompanyId?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<ProjectListDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectListProjectsForCompanyGetPath, 'get');
    if (params) {
      rb.query('CompanyId', params.CompanyId, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('GetAll', params.GetAll, {});
      rb.query('Archived', params.Archived, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('PropertyFilters', params.PropertyFilters, {});
      rb.query('OrderProperty', params.OrderProperty, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectListDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectListProjectsForCompanyGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectListProjectsForCompanyGet$Plain(params?: {
    CompanyId?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<ProjectListDtoPagedResponseBaseResponse> {

    return this.apiProjectListProjectsForCompanyGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectListDtoPagedResponseBaseResponse>) => r.body as ProjectListDtoPagedResponseBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectListProjectsForCompanyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectListProjectsForCompanyGet$Json$Response(params?: {
    CompanyId?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<ProjectListDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectListProjectsForCompanyGetPath, 'get');
    if (params) {
      rb.query('CompanyId', params.CompanyId, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('GetAll', params.GetAll, {});
      rb.query('Archived', params.Archived, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('PropertyFilters', params.PropertyFilters, {});
      rb.query('OrderProperty', params.OrderProperty, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectListDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectListProjectsForCompanyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectListProjectsForCompanyGet$Json(params?: {
    CompanyId?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<ProjectListDtoPagedResponseBaseResponse> {

    return this.apiProjectListProjectsForCompanyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectListDtoPagedResponseBaseResponse>) => r.body as ProjectListDtoPagedResponseBaseResponse)
    );
  }

  /**
   * Path part for operation apiProjectAllListProjectsGet
   */
  static readonly ApiProjectAllListProjectsGetPath = '/api/Project/all-list-projects';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectAllListProjectsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectAllListProjectsGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<ProjectListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectAllListProjectsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectAllListProjectsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectAllListProjectsGet$Plain(params?: {
  }): Observable<ProjectListDtoListBaseResponse> {

    return this.apiProjectAllListProjectsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectListDtoListBaseResponse>) => r.body as ProjectListDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectAllListProjectsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectAllListProjectsGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<ProjectListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectAllListProjectsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectAllListProjectsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectAllListProjectsGet$Json(params?: {
  }): Observable<ProjectListDtoListBaseResponse> {

    return this.apiProjectAllListProjectsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectListDtoListBaseResponse>) => r.body as ProjectListDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiProjectProjectGet
   */
  static readonly ApiProjectProjectGetPath = '/api/Project/project';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectProjectGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectProjectGet$Plain$Response(params?: {
    projectId?: number;
  }): Observable<StrictHttpResponse<ProjectDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectProjectGetPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectProjectGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectProjectGet$Plain(params?: {
    projectId?: number;
  }): Observable<ProjectDtoBaseResponse> {

    return this.apiProjectProjectGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectDtoBaseResponse>) => r.body as ProjectDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectProjectGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectProjectGet$Json$Response(params?: {
    projectId?: number;
  }): Observable<StrictHttpResponse<ProjectDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectProjectGetPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectProjectGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectProjectGet$Json(params?: {
    projectId?: number;
  }): Observable<ProjectDtoBaseResponse> {

    return this.apiProjectProjectGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectDtoBaseResponse>) => r.body as ProjectDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiProjectProjectPut
   */
  static readonly ApiProjectProjectPutPath = '/api/Project/project';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectProjectPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectProjectPut$Plain$Response(params?: {
    body?: ProjectListDto
  }): Observable<StrictHttpResponse<ProjectListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectProjectPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectProjectPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectProjectPut$Plain(params?: {
    body?: ProjectListDto
  }): Observable<ProjectListDtoBaseResponse> {

    return this.apiProjectProjectPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectListDtoBaseResponse>) => r.body as ProjectListDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectProjectPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectProjectPut$Json$Response(params?: {
    body?: ProjectListDto
  }): Observable<StrictHttpResponse<ProjectListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectProjectPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectProjectPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectProjectPut$Json(params?: {
    body?: ProjectListDto
  }): Observable<ProjectListDtoBaseResponse> {

    return this.apiProjectProjectPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectListDtoBaseResponse>) => r.body as ProjectListDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiProjectProjectPost
   */
  static readonly ApiProjectProjectPostPath = '/api/Project/project';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectProjectPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectProjectPost$Plain$Response(params?: {
    body?: ProjectListDto
  }): Observable<StrictHttpResponse<ProjectListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectProjectPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectProjectPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectProjectPost$Plain(params?: {
    body?: ProjectListDto
  }): Observable<ProjectListDtoBaseResponse> {

    return this.apiProjectProjectPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectListDtoBaseResponse>) => r.body as ProjectListDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectProjectPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectProjectPost$Json$Response(params?: {
    body?: ProjectListDto
  }): Observable<StrictHttpResponse<ProjectListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectProjectPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectProjectPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectProjectPost$Json(params?: {
    body?: ProjectListDto
  }): Observable<ProjectListDtoBaseResponse> {

    return this.apiProjectProjectPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectListDtoBaseResponse>) => r.body as ProjectListDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiProjectProjectListItemGet
   */
  static readonly ApiProjectProjectListItemGetPath = '/api/Project/project-list-item';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectProjectListItemGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectProjectListItemGet$Plain$Response(params?: {
    projectId?: number;
  }): Observable<StrictHttpResponse<ProjectListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectProjectListItemGetPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectProjectListItemGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectProjectListItemGet$Plain(params?: {
    projectId?: number;
  }): Observable<ProjectListDtoBaseResponse> {

    return this.apiProjectProjectListItemGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectListDtoBaseResponse>) => r.body as ProjectListDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectProjectListItemGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectProjectListItemGet$Json$Response(params?: {
    projectId?: number;
  }): Observable<StrictHttpResponse<ProjectListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectProjectListItemGetPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectProjectListItemGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectProjectListItemGet$Json(params?: {
    projectId?: number;
  }): Observable<ProjectListDtoBaseResponse> {

    return this.apiProjectProjectListItemGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectListDtoBaseResponse>) => r.body as ProjectListDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiProjectFavouriteProjectPut
   */
  static readonly ApiProjectFavouriteProjectPutPath = '/api/Project/favourite-project';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectFavouriteProjectPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectFavouriteProjectPut$Plain$Response(params?: {
    projectId?: number;
    userId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectFavouriteProjectPutPath, 'put');
    if (params) {
      rb.query('projectId', params.projectId, {});
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectFavouriteProjectPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectFavouriteProjectPut$Plain(params?: {
    projectId?: number;
    userId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectFavouriteProjectPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectFavouriteProjectPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectFavouriteProjectPut$Json$Response(params?: {
    projectId?: number;
    userId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectFavouriteProjectPutPath, 'put');
    if (params) {
      rb.query('projectId', params.projectId, {});
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectFavouriteProjectPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectFavouriteProjectPut$Json(params?: {
    projectId?: number;
    userId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectFavouriteProjectPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiProjectUpdateClosingNotesPut
   */
  static readonly ApiProjectUpdateClosingNotesPutPath = '/api/Project/update-closing-notes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectUpdateClosingNotesPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectUpdateClosingNotesPut$Plain$Response(params?: {
    projectId?: number;
    closingNotes?: string;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectUpdateClosingNotesPutPath, 'put');
    if (params) {
      rb.query('projectId', params.projectId, {});
      rb.query('closingNotes', params.closingNotes, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectUpdateClosingNotesPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectUpdateClosingNotesPut$Plain(params?: {
    projectId?: number;
    closingNotes?: string;
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectUpdateClosingNotesPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectUpdateClosingNotesPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectUpdateClosingNotesPut$Json$Response(params?: {
    projectId?: number;
    closingNotes?: string;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectUpdateClosingNotesPutPath, 'put');
    if (params) {
      rb.query('projectId', params.projectId, {});
      rb.query('closingNotes', params.closingNotes, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectUpdateClosingNotesPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectUpdateClosingNotesPut$Json(params?: {
    projectId?: number;
    closingNotes?: string;
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectUpdateClosingNotesPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiProjectUpdateProjectColourPut
   */
  static readonly ApiProjectUpdateProjectColourPutPath = '/api/Project/update-project-colour';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectUpdateProjectColourPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectUpdateProjectColourPut$Plain$Response(params?: {
    projectId?: number;
    colour?: string;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectUpdateProjectColourPutPath, 'put');
    if (params) {
      rb.query('projectId', params.projectId, {});
      rb.query('colour', params.colour, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectUpdateProjectColourPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectUpdateProjectColourPut$Plain(params?: {
    projectId?: number;
    colour?: string;
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectUpdateProjectColourPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectUpdateProjectColourPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectUpdateProjectColourPut$Json$Response(params?: {
    projectId?: number;
    colour?: string;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectUpdateProjectColourPutPath, 'put');
    if (params) {
      rb.query('projectId', params.projectId, {});
      rb.query('colour', params.colour, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectUpdateProjectColourPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectUpdateProjectColourPut$Json(params?: {
    projectId?: number;
    colour?: string;
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectUpdateProjectColourPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiProjectToggleArchiveProjectDelete
   */
  static readonly ApiProjectToggleArchiveProjectDeletePath = '/api/Project/toggle-archive-project';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectToggleArchiveProjectDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectToggleArchiveProjectDelete$Plain$Response(params?: {
    projectId?: number;
    isArchive?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectToggleArchiveProjectDeletePath, 'delete');
    if (params) {
      rb.query('projectId', params.projectId, {});
      rb.query('isArchive', params.isArchive, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectToggleArchiveProjectDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectToggleArchiveProjectDelete$Plain(params?: {
    projectId?: number;
    isArchive?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectToggleArchiveProjectDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectToggleArchiveProjectDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectToggleArchiveProjectDelete$Json$Response(params?: {
    projectId?: number;
    isArchive?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectToggleArchiveProjectDeletePath, 'delete');
    if (params) {
      rb.query('projectId', params.projectId, {});
      rb.query('isArchive', params.isArchive, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectToggleArchiveProjectDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectToggleArchiveProjectDelete$Json(params?: {
    projectId?: number;
    isArchive?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectToggleArchiveProjectDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiProjectProjectsByClientGet
   */
  static readonly ApiProjectProjectsByClientGetPath = '/api/Project/projects-by-client';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectProjectsByClientGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectProjectsByClientGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<ProjectsByClientDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectProjectsByClientGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectsByClientDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectProjectsByClientGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectProjectsByClientGet$Plain(params?: {
  }): Observable<ProjectsByClientDtoListBaseResponse> {

    return this.apiProjectProjectsByClientGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectsByClientDtoListBaseResponse>) => r.body as ProjectsByClientDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectProjectsByClientGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectProjectsByClientGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<ProjectsByClientDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectProjectsByClientGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectsByClientDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectProjectsByClientGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectProjectsByClientGet$Json(params?: {
  }): Observable<ProjectsByClientDtoListBaseResponse> {

    return this.apiProjectProjectsByClientGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectsByClientDtoListBaseResponse>) => r.body as ProjectsByClientDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiProjectDashboardGet
   */
  static readonly ApiProjectDashboardGetPath = '/api/Project/dashboard';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectDashboardGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectDashboardGet$Plain$Response(params?: {
    projectId?: number;
  }): Observable<StrictHttpResponse<ProjectDashboardDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectDashboardGetPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectDashboardDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectDashboardGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectDashboardGet$Plain(params?: {
    projectId?: number;
  }): Observable<ProjectDashboardDtoBaseResponse> {

    return this.apiProjectDashboardGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectDashboardDtoBaseResponse>) => r.body as ProjectDashboardDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectDashboardGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectDashboardGet$Json$Response(params?: {
    projectId?: number;
  }): Observable<StrictHttpResponse<ProjectDashboardDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectDashboardGetPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectDashboardDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectDashboardGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectDashboardGet$Json(params?: {
    projectId?: number;
  }): Observable<ProjectDashboardDtoBaseResponse> {

    return this.apiProjectDashboardGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectDashboardDtoBaseResponse>) => r.body as ProjectDashboardDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiProjectDashboardPut
   */
  static readonly ApiProjectDashboardPutPath = '/api/Project/dashboard';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectDashboardPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectDashboardPut$Plain$Response(params?: {
    body?: ProjectDashboardDto
  }): Observable<StrictHttpResponse<ProjectDashboardDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectDashboardPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectDashboardDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectDashboardPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectDashboardPut$Plain(params?: {
    body?: ProjectDashboardDto
  }): Observable<ProjectDashboardDtoBaseResponse> {

    return this.apiProjectDashboardPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectDashboardDtoBaseResponse>) => r.body as ProjectDashboardDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectDashboardPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectDashboardPut$Json$Response(params?: {
    body?: ProjectDashboardDto
  }): Observable<StrictHttpResponse<ProjectDashboardDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectDashboardPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectDashboardDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectDashboardPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectDashboardPut$Json(params?: {
    body?: ProjectDashboardDto
  }): Observable<ProjectDashboardDtoBaseResponse> {

    return this.apiProjectDashboardPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectDashboardDtoBaseResponse>) => r.body as ProjectDashboardDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiProjectListProjectStatusesGet
   */
  static readonly ApiProjectListProjectStatusesGetPath = '/api/Project/list-project-statuses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectListProjectStatusesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectListProjectStatusesGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<ProjectStatusDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectListProjectStatusesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectStatusDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectListProjectStatusesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectListProjectStatusesGet$Plain(params?: {
  }): Observable<ProjectStatusDtoListBaseResponse> {

    return this.apiProjectListProjectStatusesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectStatusDtoListBaseResponse>) => r.body as ProjectStatusDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectListProjectStatusesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectListProjectStatusesGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<ProjectStatusDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectListProjectStatusesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectStatusDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectListProjectStatusesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectListProjectStatusesGet$Json(params?: {
  }): Observable<ProjectStatusDtoListBaseResponse> {

    return this.apiProjectListProjectStatusesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectStatusDtoListBaseResponse>) => r.body as ProjectStatusDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiProjectAddProjectStatusPost
   */
  static readonly ApiProjectAddProjectStatusPostPath = '/api/Project/add-project-status';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectAddProjectStatusPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectAddProjectStatusPost$Plain$Response(params?: {
    body?: ProjectStatusDto
  }): Observable<StrictHttpResponse<ProjectStatusDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectAddProjectStatusPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectStatusDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectAddProjectStatusPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectAddProjectStatusPost$Plain(params?: {
    body?: ProjectStatusDto
  }): Observable<ProjectStatusDtoBaseResponse> {

    return this.apiProjectAddProjectStatusPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectStatusDtoBaseResponse>) => r.body as ProjectStatusDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectAddProjectStatusPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectAddProjectStatusPost$Json$Response(params?: {
    body?: ProjectStatusDto
  }): Observable<StrictHttpResponse<ProjectStatusDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectAddProjectStatusPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectStatusDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectAddProjectStatusPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectAddProjectStatusPost$Json(params?: {
    body?: ProjectStatusDto
  }): Observable<ProjectStatusDtoBaseResponse> {

    return this.apiProjectAddProjectStatusPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectStatusDtoBaseResponse>) => r.body as ProjectStatusDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiProjectUpdateProjectStatusPut
   */
  static readonly ApiProjectUpdateProjectStatusPutPath = '/api/Project/update-project-status';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectUpdateProjectStatusPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectUpdateProjectStatusPut$Plain$Response(params?: {
    body?: ProjectStatusDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectUpdateProjectStatusPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectUpdateProjectStatusPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectUpdateProjectStatusPut$Plain(params?: {
    body?: ProjectStatusDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectUpdateProjectStatusPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectUpdateProjectStatusPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectUpdateProjectStatusPut$Json$Response(params?: {
    body?: ProjectStatusDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectUpdateProjectStatusPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectUpdateProjectStatusPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectUpdateProjectStatusPut$Json(params?: {
    body?: ProjectStatusDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectUpdateProjectStatusPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiProjectArchiveProjectStatusDelete
   */
  static readonly ApiProjectArchiveProjectStatusDeletePath = '/api/Project/archive-project-status';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectArchiveProjectStatusDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectArchiveProjectStatusDelete$Plain$Response(params?: {
    statusId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectArchiveProjectStatusDeletePath, 'delete');
    if (params) {
      rb.query('statusId', params.statusId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectArchiveProjectStatusDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectArchiveProjectStatusDelete$Plain(params?: {
    statusId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectArchiveProjectStatusDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectArchiveProjectStatusDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectArchiveProjectStatusDelete$Json$Response(params?: {
    statusId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectArchiveProjectStatusDeletePath, 'delete');
    if (params) {
      rb.query('statusId', params.statusId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectArchiveProjectStatusDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectArchiveProjectStatusDelete$Json(params?: {
    statusId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectArchiveProjectStatusDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiProjectJobSheetGet
   */
  static readonly ApiProjectJobSheetGetPath = '/api/Project/job-sheet';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectJobSheetGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectJobSheetGet$Plain$Response(params?: {
    projectId?: number;
  }): Observable<StrictHttpResponse<JobSheetDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectJobSheetGetPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobSheetDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectJobSheetGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectJobSheetGet$Plain(params?: {
    projectId?: number;
  }): Observable<JobSheetDtoBaseResponse> {

    return this.apiProjectJobSheetGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<JobSheetDtoBaseResponse>) => r.body as JobSheetDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectJobSheetGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectJobSheetGet$Json$Response(params?: {
    projectId?: number;
  }): Observable<StrictHttpResponse<JobSheetDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectJobSheetGetPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobSheetDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectJobSheetGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectJobSheetGet$Json(params?: {
    projectId?: number;
  }): Observable<JobSheetDtoBaseResponse> {

    return this.apiProjectJobSheetGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<JobSheetDtoBaseResponse>) => r.body as JobSheetDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiProjectJobSheetPut
   */
  static readonly ApiProjectJobSheetPutPath = '/api/Project/job-sheet';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectJobSheetPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectJobSheetPut$Plain$Response(params?: {
    body?: JobSheetDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectJobSheetPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectJobSheetPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectJobSheetPut$Plain(params?: {
    body?: JobSheetDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectJobSheetPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectJobSheetPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectJobSheetPut$Json$Response(params?: {
    body?: JobSheetDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectJobSheetPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectJobSheetPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectJobSheetPut$Json(params?: {
    body?: JobSheetDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectJobSheetPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiProjectAddJobSheetPointOfContactPost
   */
  static readonly ApiProjectAddJobSheetPointOfContactPostPath = '/api/Project/add-job-sheet-point-of-contact';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectAddJobSheetPointOfContactPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectAddJobSheetPointOfContactPost$Plain$Response(params?: {
    body?: PointOfContactDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectAddJobSheetPointOfContactPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectAddJobSheetPointOfContactPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectAddJobSheetPointOfContactPost$Plain(params?: {
    body?: PointOfContactDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectAddJobSheetPointOfContactPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectAddJobSheetPointOfContactPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectAddJobSheetPointOfContactPost$Json$Response(params?: {
    body?: PointOfContactDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectAddJobSheetPointOfContactPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectAddJobSheetPointOfContactPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectAddJobSheetPointOfContactPost$Json(params?: {
    body?: PointOfContactDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectAddJobSheetPointOfContactPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiProjectUpdateJobSheetPointOfContactPost
   */
  static readonly ApiProjectUpdateJobSheetPointOfContactPostPath = '/api/Project/update-job-sheet-point-of-contact';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectUpdateJobSheetPointOfContactPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectUpdateJobSheetPointOfContactPost$Plain$Response(params?: {
    body?: PointOfContactDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectUpdateJobSheetPointOfContactPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectUpdateJobSheetPointOfContactPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectUpdateJobSheetPointOfContactPost$Plain(params?: {
    body?: PointOfContactDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectUpdateJobSheetPointOfContactPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectUpdateJobSheetPointOfContactPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectUpdateJobSheetPointOfContactPost$Json$Response(params?: {
    body?: PointOfContactDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectUpdateJobSheetPointOfContactPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectUpdateJobSheetPointOfContactPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectUpdateJobSheetPointOfContactPost$Json(params?: {
    body?: PointOfContactDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectUpdateJobSheetPointOfContactPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiProjectArchiveJobSheetPointOfContactDelete
   */
  static readonly ApiProjectArchiveJobSheetPointOfContactDeletePath = '/api/Project/archive-job-sheet-point-of-contact';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectArchiveJobSheetPointOfContactDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectArchiveJobSheetPointOfContactDelete$Plain$Response(params?: {
    contactId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectArchiveJobSheetPointOfContactDeletePath, 'delete');
    if (params) {
      rb.query('contactId', params.contactId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectArchiveJobSheetPointOfContactDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectArchiveJobSheetPointOfContactDelete$Plain(params?: {
    contactId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectArchiveJobSheetPointOfContactDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectArchiveJobSheetPointOfContactDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectArchiveJobSheetPointOfContactDelete$Json$Response(params?: {
    contactId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectArchiveJobSheetPointOfContactDeletePath, 'delete');
    if (params) {
      rb.query('contactId', params.contactId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectArchiveJobSheetPointOfContactDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectArchiveJobSheetPointOfContactDelete$Json(params?: {
    contactId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectArchiveJobSheetPointOfContactDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiProjectAddJobSheetTeamMemberPost
   */
  static readonly ApiProjectAddJobSheetTeamMemberPostPath = '/api/Project/add-job-sheet-team-member';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectAddJobSheetTeamMemberPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectAddJobSheetTeamMemberPost$Plain$Response(params?: {
    body?: HcTeamMemberDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectAddJobSheetTeamMemberPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectAddJobSheetTeamMemberPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectAddJobSheetTeamMemberPost$Plain(params?: {
    body?: HcTeamMemberDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectAddJobSheetTeamMemberPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectAddJobSheetTeamMemberPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectAddJobSheetTeamMemberPost$Json$Response(params?: {
    body?: HcTeamMemberDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectAddJobSheetTeamMemberPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectAddJobSheetTeamMemberPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectAddJobSheetTeamMemberPost$Json(params?: {
    body?: HcTeamMemberDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectAddJobSheetTeamMemberPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiProjectUpdateJobSheetTeamMemberPost
   */
  static readonly ApiProjectUpdateJobSheetTeamMemberPostPath = '/api/Project/update-job-sheet-team-member';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectUpdateJobSheetTeamMemberPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectUpdateJobSheetTeamMemberPost$Plain$Response(params?: {
    body?: HcTeamMemberDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectUpdateJobSheetTeamMemberPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectUpdateJobSheetTeamMemberPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectUpdateJobSheetTeamMemberPost$Plain(params?: {
    body?: HcTeamMemberDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectUpdateJobSheetTeamMemberPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectUpdateJobSheetTeamMemberPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectUpdateJobSheetTeamMemberPost$Json$Response(params?: {
    body?: HcTeamMemberDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectUpdateJobSheetTeamMemberPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectUpdateJobSheetTeamMemberPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectUpdateJobSheetTeamMemberPost$Json(params?: {
    body?: HcTeamMemberDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectUpdateJobSheetTeamMemberPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiProjectArchiveJobSheetTeamMemberDelete
   */
  static readonly ApiProjectArchiveJobSheetTeamMemberDeletePath = '/api/Project/archive-job-sheet-team-member';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectArchiveJobSheetTeamMemberDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectArchiveJobSheetTeamMemberDelete$Plain$Response(params?: {
    teamMemberId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectArchiveJobSheetTeamMemberDeletePath, 'delete');
    if (params) {
      rb.query('teamMemberId', params.teamMemberId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectArchiveJobSheetTeamMemberDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectArchiveJobSheetTeamMemberDelete$Plain(params?: {
    teamMemberId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectArchiveJobSheetTeamMemberDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectArchiveJobSheetTeamMemberDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectArchiveJobSheetTeamMemberDelete$Json$Response(params?: {
    teamMemberId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectArchiveJobSheetTeamMemberDeletePath, 'delete');
    if (params) {
      rb.query('teamMemberId', params.teamMemberId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectArchiveJobSheetTeamMemberDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectArchiveJobSheetTeamMemberDelete$Json(params?: {
    teamMemberId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectArchiveJobSheetTeamMemberDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiProjectAddJobSheetAddressPost
   */
  static readonly ApiProjectAddJobSheetAddressPostPath = '/api/Project/add-job-sheet-address';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectAddJobSheetAddressPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectAddJobSheetAddressPost$Plain$Response(params?: {
    body?: ProjectAddressDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectAddJobSheetAddressPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectAddJobSheetAddressPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectAddJobSheetAddressPost$Plain(params?: {
    body?: ProjectAddressDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectAddJobSheetAddressPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectAddJobSheetAddressPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectAddJobSheetAddressPost$Json$Response(params?: {
    body?: ProjectAddressDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectAddJobSheetAddressPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectAddJobSheetAddressPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectAddJobSheetAddressPost$Json(params?: {
    body?: ProjectAddressDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectAddJobSheetAddressPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiProjectUpdateJobSheetAddressPost
   */
  static readonly ApiProjectUpdateJobSheetAddressPostPath = '/api/Project/update-job-sheet-address';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectUpdateJobSheetAddressPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectUpdateJobSheetAddressPost$Plain$Response(params?: {
    body?: ProjectAddressDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectUpdateJobSheetAddressPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectUpdateJobSheetAddressPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectUpdateJobSheetAddressPost$Plain(params?: {
    body?: ProjectAddressDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectUpdateJobSheetAddressPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectUpdateJobSheetAddressPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectUpdateJobSheetAddressPost$Json$Response(params?: {
    body?: ProjectAddressDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectUpdateJobSheetAddressPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectUpdateJobSheetAddressPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectUpdateJobSheetAddressPost$Json(params?: {
    body?: ProjectAddressDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectUpdateJobSheetAddressPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiProjectArchiveJobSheetAddressDelete
   */
  static readonly ApiProjectArchiveJobSheetAddressDeletePath = '/api/Project/archive-job-sheet-address';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectArchiveJobSheetAddressDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectArchiveJobSheetAddressDelete$Plain$Response(params?: {
    projectAddressId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectArchiveJobSheetAddressDeletePath, 'delete');
    if (params) {
      rb.query('projectAddressId', params.projectAddressId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectArchiveJobSheetAddressDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectArchiveJobSheetAddressDelete$Plain(params?: {
    projectAddressId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectArchiveJobSheetAddressDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectArchiveJobSheetAddressDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectArchiveJobSheetAddressDelete$Json$Response(params?: {
    projectAddressId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectArchiveJobSheetAddressDeletePath, 'delete');
    if (params) {
      rb.query('projectAddressId', params.projectAddressId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectArchiveJobSheetAddressDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectArchiveJobSheetAddressDelete$Json(params?: {
    projectAddressId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectArchiveJobSheetAddressDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiProjectAddJobSheetWorkloadMilestonePost
   */
  static readonly ApiProjectAddJobSheetWorkloadMilestonePostPath = '/api/Project/add-job-sheet-workload-milestone';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectAddJobSheetWorkloadMilestonePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectAddJobSheetWorkloadMilestonePost$Plain$Response(params?: {
    body?: WorkloadMilestoneDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectAddJobSheetWorkloadMilestonePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectAddJobSheetWorkloadMilestonePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectAddJobSheetWorkloadMilestonePost$Plain(params?: {
    body?: WorkloadMilestoneDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectAddJobSheetWorkloadMilestonePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectAddJobSheetWorkloadMilestonePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectAddJobSheetWorkloadMilestonePost$Json$Response(params?: {
    body?: WorkloadMilestoneDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectAddJobSheetWorkloadMilestonePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectAddJobSheetWorkloadMilestonePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectAddJobSheetWorkloadMilestonePost$Json(params?: {
    body?: WorkloadMilestoneDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectAddJobSheetWorkloadMilestonePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiProjectUpdateJobSheetWorkloadMilestonePost
   */
  static readonly ApiProjectUpdateJobSheetWorkloadMilestonePostPath = '/api/Project/update-job-sheet-workload-milestone';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectUpdateJobSheetWorkloadMilestonePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectUpdateJobSheetWorkloadMilestonePost$Plain$Response(params?: {
    body?: WorkloadMilestoneDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectUpdateJobSheetWorkloadMilestonePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectUpdateJobSheetWorkloadMilestonePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectUpdateJobSheetWorkloadMilestonePost$Plain(params?: {
    body?: WorkloadMilestoneDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectUpdateJobSheetWorkloadMilestonePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectUpdateJobSheetWorkloadMilestonePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectUpdateJobSheetWorkloadMilestonePost$Json$Response(params?: {
    body?: WorkloadMilestoneDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectUpdateJobSheetWorkloadMilestonePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectUpdateJobSheetWorkloadMilestonePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectUpdateJobSheetWorkloadMilestonePost$Json(params?: {
    body?: WorkloadMilestoneDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectUpdateJobSheetWorkloadMilestonePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiProjectArchiveJobSheetWorkloadMilestoneDelete
   */
  static readonly ApiProjectArchiveJobSheetWorkloadMilestoneDeletePath = '/api/Project/archive-job-sheet-workload-milestone';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectArchiveJobSheetWorkloadMilestoneDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectArchiveJobSheetWorkloadMilestoneDelete$Plain$Response(params?: {
    workloadMilestoneId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectArchiveJobSheetWorkloadMilestoneDeletePath, 'delete');
    if (params) {
      rb.query('workloadMilestoneId', params.workloadMilestoneId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectArchiveJobSheetWorkloadMilestoneDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectArchiveJobSheetWorkloadMilestoneDelete$Plain(params?: {
    workloadMilestoneId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectArchiveJobSheetWorkloadMilestoneDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectArchiveJobSheetWorkloadMilestoneDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectArchiveJobSheetWorkloadMilestoneDelete$Json$Response(params?: {
    workloadMilestoneId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectArchiveJobSheetWorkloadMilestoneDeletePath, 'delete');
    if (params) {
      rb.query('workloadMilestoneId', params.workloadMilestoneId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectArchiveJobSheetWorkloadMilestoneDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectArchiveJobSheetWorkloadMilestoneDelete$Json(params?: {
    workloadMilestoneId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectArchiveJobSheetWorkloadMilestoneDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiProjectTechSpecGet
   */
  static readonly ApiProjectTechSpecGetPath = '/api/Project/tech-spec';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectTechSpecGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectTechSpecGet$Plain$Response(params?: {
    techSpecId?: number;
  }): Observable<StrictHttpResponse<TechSpecDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectTechSpecGetPath, 'get');
    if (params) {
      rb.query('techSpecId', params.techSpecId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TechSpecDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectTechSpecGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectTechSpecGet$Plain(params?: {
    techSpecId?: number;
  }): Observable<TechSpecDtoBaseResponse> {

    return this.apiProjectTechSpecGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TechSpecDtoBaseResponse>) => r.body as TechSpecDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectTechSpecGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectTechSpecGet$Json$Response(params?: {
    techSpecId?: number;
  }): Observable<StrictHttpResponse<TechSpecDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectTechSpecGetPath, 'get');
    if (params) {
      rb.query('techSpecId', params.techSpecId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TechSpecDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectTechSpecGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectTechSpecGet$Json(params?: {
    techSpecId?: number;
  }): Observable<TechSpecDtoBaseResponse> {

    return this.apiProjectTechSpecGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TechSpecDtoBaseResponse>) => r.body as TechSpecDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiProjectUpdateTechSpecPut
   */
  static readonly ApiProjectUpdateTechSpecPutPath = '/api/Project/update-tech-spec';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectUpdateTechSpecPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectUpdateTechSpecPut$Plain$Response(params?: {
    body?: TechSpecDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectUpdateTechSpecPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectUpdateTechSpecPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectUpdateTechSpecPut$Plain(params?: {
    body?: TechSpecDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectUpdateTechSpecPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectUpdateTechSpecPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectUpdateTechSpecPut$Json$Response(params?: {
    body?: TechSpecDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectUpdateTechSpecPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectUpdateTechSpecPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectUpdateTechSpecPut$Json(params?: {
    body?: TechSpecDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectUpdateTechSpecPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiProjectAddTechSpecPost
   */
  static readonly ApiProjectAddTechSpecPostPath = '/api/Project/add-tech-spec';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectAddTechSpecPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectAddTechSpecPost$Plain$Response(params?: {
    body?: TechSpecDto
  }): Observable<StrictHttpResponse<TechSpecDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectAddTechSpecPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TechSpecDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectAddTechSpecPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectAddTechSpecPost$Plain(params?: {
    body?: TechSpecDto
  }): Observable<TechSpecDtoBaseResponse> {

    return this.apiProjectAddTechSpecPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TechSpecDtoBaseResponse>) => r.body as TechSpecDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectAddTechSpecPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectAddTechSpecPost$Json$Response(params?: {
    body?: TechSpecDto
  }): Observable<StrictHttpResponse<TechSpecDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectAddTechSpecPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TechSpecDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectAddTechSpecPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectAddTechSpecPost$Json(params?: {
    body?: TechSpecDto
  }): Observable<TechSpecDtoBaseResponse> {

    return this.apiProjectAddTechSpecPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TechSpecDtoBaseResponse>) => r.body as TechSpecDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiProjectAddTechSpecLinkPost
   */
  static readonly ApiProjectAddTechSpecLinkPostPath = '/api/Project/add-tech-spec-link';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectAddTechSpecLinkPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectAddTechSpecLinkPost$Plain$Response(params?: {
    body?: TechSpecLinkDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectAddTechSpecLinkPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectAddTechSpecLinkPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectAddTechSpecLinkPost$Plain(params?: {
    body?: TechSpecLinkDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectAddTechSpecLinkPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectAddTechSpecLinkPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectAddTechSpecLinkPost$Json$Response(params?: {
    body?: TechSpecLinkDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectAddTechSpecLinkPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectAddTechSpecLinkPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectAddTechSpecLinkPost$Json(params?: {
    body?: TechSpecLinkDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectAddTechSpecLinkPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiProjectUpdateTechSpecLinkPost
   */
  static readonly ApiProjectUpdateTechSpecLinkPostPath = '/api/Project/update-tech-spec-link';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectUpdateTechSpecLinkPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectUpdateTechSpecLinkPost$Plain$Response(params?: {
    body?: TechSpecLinkDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectUpdateTechSpecLinkPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectUpdateTechSpecLinkPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectUpdateTechSpecLinkPost$Plain(params?: {
    body?: TechSpecLinkDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectUpdateTechSpecLinkPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectUpdateTechSpecLinkPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectUpdateTechSpecLinkPost$Json$Response(params?: {
    body?: TechSpecLinkDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectUpdateTechSpecLinkPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectUpdateTechSpecLinkPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectUpdateTechSpecLinkPost$Json(params?: {
    body?: TechSpecLinkDto
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectUpdateTechSpecLinkPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiProjectArchiveTechSpecLinkDelete
   */
  static readonly ApiProjectArchiveTechSpecLinkDeletePath = '/api/Project/archive-tech-spec-link';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectArchiveTechSpecLinkDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectArchiveTechSpecLinkDelete$Plain$Response(params?: {
    techSpecLinkId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectArchiveTechSpecLinkDeletePath, 'delete');
    if (params) {
      rb.query('techSpecLinkId', params.techSpecLinkId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectArchiveTechSpecLinkDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectArchiveTechSpecLinkDelete$Plain(params?: {
    techSpecLinkId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectArchiveTechSpecLinkDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectArchiveTechSpecLinkDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectArchiveTechSpecLinkDelete$Json$Response(params?: {
    techSpecLinkId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectService.ApiProjectArchiveTechSpecLinkDeletePath, 'delete');
    if (params) {
      rb.query('techSpecLinkId', params.techSpecLinkId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectArchiveTechSpecLinkDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectArchiveTechSpecLinkDelete$Json(params?: {
    techSpecLinkId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiProjectArchiveTechSpecLinkDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

}
