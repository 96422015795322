/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanBaseResponse } from '../models/boolean-base-response';
import { CostManagerDto } from '../models/cost-manager-dto';
import { CostManagerDtoBaseResponse } from '../models/cost-manager-dto-base-response';
import { CostManagerMilestoneDto } from '../models/cost-manager-milestone-dto';
import { CostManagerMilestoneDtoBaseResponse } from '../models/cost-manager-milestone-dto-base-response';
import { CostManagerMilestoneDtoListBaseResponse } from '../models/cost-manager-milestone-dto-list-base-response';
import { CostSectionDto } from '../models/cost-section-dto';
import { CostSectionDtoBaseResponse } from '../models/cost-section-dto-base-response';
import { CostSectionItemDto } from '../models/cost-section-item-dto';
import { CostSectionItemDtoBaseResponse } from '../models/cost-section-item-dto-base-response';
import { LongTermForecastDtoBaseResponse } from '../models/long-term-forecast-dto-base-response';
import { ProductCostSectionDto } from '../models/product-cost-section-dto';
import { ProductCostSectionDtoBaseResponse } from '../models/product-cost-section-dto-base-response';
import { ProductCostSectionItemDto } from '../models/product-cost-section-item-dto';
import { ProductCostSectionItemDtoBaseResponse } from '../models/product-cost-section-item-dto-base-response';
import { SimpleCostSectionDto } from '../models/simple-cost-section-dto';
import { SimpleCostSectionDtoBaseResponse } from '../models/simple-cost-section-dto-base-response';
import { SimpleCostSectionItemDto } from '../models/simple-cost-section-item-dto';
import { SimpleCostSectionItemDtoBaseResponse } from '../models/simple-cost-section-item-dto-base-response';

@Injectable({
  providedIn: 'root',
})
export class CostManagerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiCostManagerCostManagerGet
   */
  static readonly ApiCostManagerCostManagerGetPath = '/api/CostManager/cost-manager';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerCostManagerGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerCostManagerGet$Plain$Response(params?: {
    costManagerId?: number;
  }): Observable<StrictHttpResponse<CostManagerDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerCostManagerGetPath, 'get');
    if (params) {
      rb.query('costManagerId', params.costManagerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CostManagerDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerCostManagerGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerCostManagerGet$Plain(params?: {
    costManagerId?: number;
  }): Observable<CostManagerDtoBaseResponse> {

    return this.apiCostManagerCostManagerGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CostManagerDtoBaseResponse>) => r.body as CostManagerDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerCostManagerGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerCostManagerGet$Json$Response(params?: {
    costManagerId?: number;
  }): Observable<StrictHttpResponse<CostManagerDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerCostManagerGetPath, 'get');
    if (params) {
      rb.query('costManagerId', params.costManagerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CostManagerDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerCostManagerGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerCostManagerGet$Json(params?: {
    costManagerId?: number;
  }): Observable<CostManagerDtoBaseResponse> {

    return this.apiCostManagerCostManagerGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CostManagerDtoBaseResponse>) => r.body as CostManagerDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerCostManagerDelete
   */
  static readonly ApiCostManagerCostManagerDeletePath = '/api/CostManager/cost-manager';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerCostManagerDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerCostManagerDelete$Plain$Response(params?: {
    costManagerId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerCostManagerDeletePath, 'delete');
    if (params) {
      rb.query('costManagerId', params.costManagerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerCostManagerDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerCostManagerDelete$Plain(params?: {
    costManagerId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerCostManagerDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerCostManagerDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerCostManagerDelete$Json$Response(params?: {
    costManagerId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerCostManagerDeletePath, 'delete');
    if (params) {
      rb.query('costManagerId', params.costManagerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerCostManagerDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerCostManagerDelete$Json(params?: {
    costManagerId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerCostManagerDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerCreateCostManagerPost
   */
  static readonly ApiCostManagerCreateCostManagerPostPath = '/api/CostManager/create-cost-manager';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerCreateCostManagerPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerCreateCostManagerPost$Plain$Response(params?: {
    body?: CostManagerDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerCreateCostManagerPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerCreateCostManagerPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerCreateCostManagerPost$Plain(params?: {
    body?: CostManagerDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerCreateCostManagerPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerCreateCostManagerPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerCreateCostManagerPost$Json$Response(params?: {
    body?: CostManagerDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerCreateCostManagerPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerCreateCostManagerPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerCreateCostManagerPost$Json(params?: {
    body?: CostManagerDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerCreateCostManagerPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerUpdateCostManagerPut
   */
  static readonly ApiCostManagerUpdateCostManagerPutPath = '/api/CostManager/update-cost-manager';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerUpdateCostManagerPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateCostManagerPut$Plain$Response(params?: {
    body?: CostManagerDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerUpdateCostManagerPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerUpdateCostManagerPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateCostManagerPut$Plain(params?: {
    body?: CostManagerDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerUpdateCostManagerPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerUpdateCostManagerPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateCostManagerPut$Json$Response(params?: {
    body?: CostManagerDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerUpdateCostManagerPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerUpdateCostManagerPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateCostManagerPut$Json(params?: {
    body?: CostManagerDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerUpdateCostManagerPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerAddSectionPost
   */
  static readonly ApiCostManagerAddSectionPostPath = '/api/CostManager/add-section';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerAddSectionPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddSectionPost$Plain$Response(params?: {
    body?: CostSectionDto
  }): Observable<StrictHttpResponse<CostSectionDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerAddSectionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CostSectionDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerAddSectionPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddSectionPost$Plain(params?: {
    body?: CostSectionDto
  }): Observable<CostSectionDtoBaseResponse> {

    return this.apiCostManagerAddSectionPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CostSectionDtoBaseResponse>) => r.body as CostSectionDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerAddSectionPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddSectionPost$Json$Response(params?: {
    body?: CostSectionDto
  }): Observable<StrictHttpResponse<CostSectionDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerAddSectionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CostSectionDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerAddSectionPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddSectionPost$Json(params?: {
    body?: CostSectionDto
  }): Observable<CostSectionDtoBaseResponse> {

    return this.apiCostManagerAddSectionPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CostSectionDtoBaseResponse>) => r.body as CostSectionDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerAddSimpleSectionPost
   */
  static readonly ApiCostManagerAddSimpleSectionPostPath = '/api/CostManager/add-simple-section';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerAddSimpleSectionPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddSimpleSectionPost$Plain$Response(params?: {
    body?: SimpleCostSectionDto
  }): Observable<StrictHttpResponse<SimpleCostSectionDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerAddSimpleSectionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SimpleCostSectionDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerAddSimpleSectionPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddSimpleSectionPost$Plain(params?: {
    body?: SimpleCostSectionDto
  }): Observable<SimpleCostSectionDtoBaseResponse> {

    return this.apiCostManagerAddSimpleSectionPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SimpleCostSectionDtoBaseResponse>) => r.body as SimpleCostSectionDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerAddSimpleSectionPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddSimpleSectionPost$Json$Response(params?: {
    body?: SimpleCostSectionDto
  }): Observable<StrictHttpResponse<SimpleCostSectionDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerAddSimpleSectionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SimpleCostSectionDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerAddSimpleSectionPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddSimpleSectionPost$Json(params?: {
    body?: SimpleCostSectionDto
  }): Observable<SimpleCostSectionDtoBaseResponse> {

    return this.apiCostManagerAddSimpleSectionPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SimpleCostSectionDtoBaseResponse>) => r.body as SimpleCostSectionDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerAddProductSectionPost
   */
  static readonly ApiCostManagerAddProductSectionPostPath = '/api/CostManager/add-product-section';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerAddProductSectionPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddProductSectionPost$Plain$Response(params?: {
    body?: ProductCostSectionDto
  }): Observable<StrictHttpResponse<ProductCostSectionDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerAddProductSectionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductCostSectionDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerAddProductSectionPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddProductSectionPost$Plain(params?: {
    body?: ProductCostSectionDto
  }): Observable<ProductCostSectionDtoBaseResponse> {

    return this.apiCostManagerAddProductSectionPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProductCostSectionDtoBaseResponse>) => r.body as ProductCostSectionDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerAddProductSectionPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddProductSectionPost$Json$Response(params?: {
    body?: ProductCostSectionDto
  }): Observable<StrictHttpResponse<ProductCostSectionDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerAddProductSectionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductCostSectionDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerAddProductSectionPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddProductSectionPost$Json(params?: {
    body?: ProductCostSectionDto
  }): Observable<ProductCostSectionDtoBaseResponse> {

    return this.apiCostManagerAddProductSectionPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProductCostSectionDtoBaseResponse>) => r.body as ProductCostSectionDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerUpdateSectionPut
   */
  static readonly ApiCostManagerUpdateSectionPutPath = '/api/CostManager/update-section';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerUpdateSectionPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateSectionPut$Plain$Response(params?: {
    body?: CostSectionDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerUpdateSectionPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerUpdateSectionPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateSectionPut$Plain(params?: {
    body?: CostSectionDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerUpdateSectionPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerUpdateSectionPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateSectionPut$Json$Response(params?: {
    body?: CostSectionDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerUpdateSectionPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerUpdateSectionPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateSectionPut$Json(params?: {
    body?: CostSectionDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerUpdateSectionPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerUpdateSimpleSectionPut
   */
  static readonly ApiCostManagerUpdateSimpleSectionPutPath = '/api/CostManager/update-simple-section';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerUpdateSimpleSectionPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateSimpleSectionPut$Plain$Response(params?: {
    body?: SimpleCostSectionDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerUpdateSimpleSectionPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerUpdateSimpleSectionPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateSimpleSectionPut$Plain(params?: {
    body?: SimpleCostSectionDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerUpdateSimpleSectionPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerUpdateSimpleSectionPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateSimpleSectionPut$Json$Response(params?: {
    body?: SimpleCostSectionDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerUpdateSimpleSectionPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerUpdateSimpleSectionPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateSimpleSectionPut$Json(params?: {
    body?: SimpleCostSectionDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerUpdateSimpleSectionPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerUpdateProductSectionPut
   */
  static readonly ApiCostManagerUpdateProductSectionPutPath = '/api/CostManager/update-product-section';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerUpdateProductSectionPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateProductSectionPut$Plain$Response(params?: {
    body?: ProductCostSectionDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerUpdateProductSectionPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerUpdateProductSectionPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateProductSectionPut$Plain(params?: {
    body?: ProductCostSectionDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerUpdateProductSectionPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerUpdateProductSectionPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateProductSectionPut$Json$Response(params?: {
    body?: ProductCostSectionDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerUpdateProductSectionPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerUpdateProductSectionPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateProductSectionPut$Json(params?: {
    body?: ProductCostSectionDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerUpdateProductSectionPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerArchiveSectionDelete
   */
  static readonly ApiCostManagerArchiveSectionDeletePath = '/api/CostManager/archive-section';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerArchiveSectionDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerArchiveSectionDelete$Plain$Response(params?: {
    costSectionId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerArchiveSectionDeletePath, 'delete');
    if (params) {
      rb.query('costSectionId', params.costSectionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerArchiveSectionDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerArchiveSectionDelete$Plain(params?: {
    costSectionId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerArchiveSectionDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerArchiveSectionDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerArchiveSectionDelete$Json$Response(params?: {
    costSectionId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerArchiveSectionDeletePath, 'delete');
    if (params) {
      rb.query('costSectionId', params.costSectionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerArchiveSectionDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerArchiveSectionDelete$Json(params?: {
    costSectionId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerArchiveSectionDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerArchiveSimpleSectionDelete
   */
  static readonly ApiCostManagerArchiveSimpleSectionDeletePath = '/api/CostManager/archive-simple-section';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerArchiveSimpleSectionDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerArchiveSimpleSectionDelete$Plain$Response(params?: {
    simpleCostSectionId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerArchiveSimpleSectionDeletePath, 'delete');
    if (params) {
      rb.query('simpleCostSectionId', params.simpleCostSectionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerArchiveSimpleSectionDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerArchiveSimpleSectionDelete$Plain(params?: {
    simpleCostSectionId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerArchiveSimpleSectionDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerArchiveSimpleSectionDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerArchiveSimpleSectionDelete$Json$Response(params?: {
    simpleCostSectionId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerArchiveSimpleSectionDeletePath, 'delete');
    if (params) {
      rb.query('simpleCostSectionId', params.simpleCostSectionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerArchiveSimpleSectionDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerArchiveSimpleSectionDelete$Json(params?: {
    simpleCostSectionId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerArchiveSimpleSectionDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerArchiveProductSectionDelete
   */
  static readonly ApiCostManagerArchiveProductSectionDeletePath = '/api/CostManager/archive-product-section';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerArchiveProductSectionDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerArchiveProductSectionDelete$Plain$Response(params?: {
    productCostSectionId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerArchiveProductSectionDeletePath, 'delete');
    if (params) {
      rb.query('productCostSectionId', params.productCostSectionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerArchiveProductSectionDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerArchiveProductSectionDelete$Plain(params?: {
    productCostSectionId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerArchiveProductSectionDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerArchiveProductSectionDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerArchiveProductSectionDelete$Json$Response(params?: {
    productCostSectionId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerArchiveProductSectionDeletePath, 'delete');
    if (params) {
      rb.query('productCostSectionId', params.productCostSectionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerArchiveProductSectionDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerArchiveProductSectionDelete$Json(params?: {
    productCostSectionId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerArchiveProductSectionDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerAddSectionItemPost
   */
  static readonly ApiCostManagerAddSectionItemPostPath = '/api/CostManager/add-section-item';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerAddSectionItemPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddSectionItemPost$Plain$Response(params?: {
    body?: CostSectionItemDto
  }): Observable<StrictHttpResponse<CostSectionItemDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerAddSectionItemPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CostSectionItemDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerAddSectionItemPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddSectionItemPost$Plain(params?: {
    body?: CostSectionItemDto
  }): Observable<CostSectionItemDtoBaseResponse> {

    return this.apiCostManagerAddSectionItemPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CostSectionItemDtoBaseResponse>) => r.body as CostSectionItemDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerAddSectionItemPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddSectionItemPost$Json$Response(params?: {
    body?: CostSectionItemDto
  }): Observable<StrictHttpResponse<CostSectionItemDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerAddSectionItemPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CostSectionItemDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerAddSectionItemPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddSectionItemPost$Json(params?: {
    body?: CostSectionItemDto
  }): Observable<CostSectionItemDtoBaseResponse> {

    return this.apiCostManagerAddSectionItemPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CostSectionItemDtoBaseResponse>) => r.body as CostSectionItemDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerUpdateSectionItemPut
   */
  static readonly ApiCostManagerUpdateSectionItemPutPath = '/api/CostManager/update-section-item';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerUpdateSectionItemPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateSectionItemPut$Plain$Response(params?: {
    body?: CostSectionItemDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerUpdateSectionItemPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerUpdateSectionItemPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateSectionItemPut$Plain(params?: {
    body?: CostSectionItemDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerUpdateSectionItemPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerUpdateSectionItemPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateSectionItemPut$Json$Response(params?: {
    body?: CostSectionItemDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerUpdateSectionItemPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerUpdateSectionItemPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateSectionItemPut$Json(params?: {
    body?: CostSectionItemDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerUpdateSectionItemPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerDeleteSectionItemDelete
   */
  static readonly ApiCostManagerDeleteSectionItemDeletePath = '/api/CostManager/delete-section-item';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerDeleteSectionItemDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerDeleteSectionItemDelete$Plain$Response(params?: {
    costSectionItemId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerDeleteSectionItemDeletePath, 'delete');
    if (params) {
      rb.query('costSectionItemId', params.costSectionItemId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerDeleteSectionItemDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerDeleteSectionItemDelete$Plain(params?: {
    costSectionItemId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerDeleteSectionItemDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerDeleteSectionItemDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerDeleteSectionItemDelete$Json$Response(params?: {
    costSectionItemId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerDeleteSectionItemDeletePath, 'delete');
    if (params) {
      rb.query('costSectionItemId', params.costSectionItemId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerDeleteSectionItemDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerDeleteSectionItemDelete$Json(params?: {
    costSectionItemId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerDeleteSectionItemDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerAddSimpleSectionItemPost
   */
  static readonly ApiCostManagerAddSimpleSectionItemPostPath = '/api/CostManager/add-simple-section-item';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerAddSimpleSectionItemPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddSimpleSectionItemPost$Plain$Response(params?: {
    body?: SimpleCostSectionItemDto
  }): Observable<StrictHttpResponse<SimpleCostSectionItemDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerAddSimpleSectionItemPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SimpleCostSectionItemDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerAddSimpleSectionItemPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddSimpleSectionItemPost$Plain(params?: {
    body?: SimpleCostSectionItemDto
  }): Observable<SimpleCostSectionItemDtoBaseResponse> {

    return this.apiCostManagerAddSimpleSectionItemPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SimpleCostSectionItemDtoBaseResponse>) => r.body as SimpleCostSectionItemDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerAddSimpleSectionItemPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddSimpleSectionItemPost$Json$Response(params?: {
    body?: SimpleCostSectionItemDto
  }): Observable<StrictHttpResponse<SimpleCostSectionItemDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerAddSimpleSectionItemPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SimpleCostSectionItemDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerAddSimpleSectionItemPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddSimpleSectionItemPost$Json(params?: {
    body?: SimpleCostSectionItemDto
  }): Observable<SimpleCostSectionItemDtoBaseResponse> {

    return this.apiCostManagerAddSimpleSectionItemPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SimpleCostSectionItemDtoBaseResponse>) => r.body as SimpleCostSectionItemDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerUpdateSimpleSectionItemPut
   */
  static readonly ApiCostManagerUpdateSimpleSectionItemPutPath = '/api/CostManager/update-simple-section-item';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerUpdateSimpleSectionItemPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateSimpleSectionItemPut$Plain$Response(params?: {
    body?: SimpleCostSectionItemDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerUpdateSimpleSectionItemPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerUpdateSimpleSectionItemPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateSimpleSectionItemPut$Plain(params?: {
    body?: SimpleCostSectionItemDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerUpdateSimpleSectionItemPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerUpdateSimpleSectionItemPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateSimpleSectionItemPut$Json$Response(params?: {
    body?: SimpleCostSectionItemDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerUpdateSimpleSectionItemPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerUpdateSimpleSectionItemPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateSimpleSectionItemPut$Json(params?: {
    body?: SimpleCostSectionItemDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerUpdateSimpleSectionItemPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerDeleteSimpleSectionItemDelete
   */
  static readonly ApiCostManagerDeleteSimpleSectionItemDeletePath = '/api/CostManager/delete-simple-section-item';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerDeleteSimpleSectionItemDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerDeleteSimpleSectionItemDelete$Plain$Response(params?: {
    simpleCostSectionItemId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerDeleteSimpleSectionItemDeletePath, 'delete');
    if (params) {
      rb.query('simpleCostSectionItemId', params.simpleCostSectionItemId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerDeleteSimpleSectionItemDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerDeleteSimpleSectionItemDelete$Plain(params?: {
    simpleCostSectionItemId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerDeleteSimpleSectionItemDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerDeleteSimpleSectionItemDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerDeleteSimpleSectionItemDelete$Json$Response(params?: {
    simpleCostSectionItemId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerDeleteSimpleSectionItemDeletePath, 'delete');
    if (params) {
      rb.query('simpleCostSectionItemId', params.simpleCostSectionItemId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerDeleteSimpleSectionItemDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerDeleteSimpleSectionItemDelete$Json(params?: {
    simpleCostSectionItemId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerDeleteSimpleSectionItemDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerAddProductSectionItemPost
   */
  static readonly ApiCostManagerAddProductSectionItemPostPath = '/api/CostManager/add-product-section-item';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerAddProductSectionItemPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddProductSectionItemPost$Plain$Response(params?: {
    body?: ProductCostSectionItemDto
  }): Observable<StrictHttpResponse<ProductCostSectionItemDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerAddProductSectionItemPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductCostSectionItemDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerAddProductSectionItemPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddProductSectionItemPost$Plain(params?: {
    body?: ProductCostSectionItemDto
  }): Observable<ProductCostSectionItemDtoBaseResponse> {

    return this.apiCostManagerAddProductSectionItemPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProductCostSectionItemDtoBaseResponse>) => r.body as ProductCostSectionItemDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerAddProductSectionItemPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddProductSectionItemPost$Json$Response(params?: {
    body?: ProductCostSectionItemDto
  }): Observable<StrictHttpResponse<ProductCostSectionItemDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerAddProductSectionItemPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductCostSectionItemDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerAddProductSectionItemPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddProductSectionItemPost$Json(params?: {
    body?: ProductCostSectionItemDto
  }): Observable<ProductCostSectionItemDtoBaseResponse> {

    return this.apiCostManagerAddProductSectionItemPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProductCostSectionItemDtoBaseResponse>) => r.body as ProductCostSectionItemDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerUpdateProductSectionItemPut
   */
  static readonly ApiCostManagerUpdateProductSectionItemPutPath = '/api/CostManager/update-product-section-item';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerUpdateProductSectionItemPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateProductSectionItemPut$Plain$Response(params?: {
    body?: ProductCostSectionItemDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerUpdateProductSectionItemPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerUpdateProductSectionItemPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateProductSectionItemPut$Plain(params?: {
    body?: ProductCostSectionItemDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerUpdateProductSectionItemPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerUpdateProductSectionItemPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateProductSectionItemPut$Json$Response(params?: {
    body?: ProductCostSectionItemDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerUpdateProductSectionItemPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerUpdateProductSectionItemPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateProductSectionItemPut$Json(params?: {
    body?: ProductCostSectionItemDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerUpdateProductSectionItemPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerDeleteProductSectionItemDelete
   */
  static readonly ApiCostManagerDeleteProductSectionItemDeletePath = '/api/CostManager/delete-product-section-item';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerDeleteProductSectionItemDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerDeleteProductSectionItemDelete$Plain$Response(params?: {
    productCostSectionItemId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerDeleteProductSectionItemDeletePath, 'delete');
    if (params) {
      rb.query('productCostSectionItemId', params.productCostSectionItemId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerDeleteProductSectionItemDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerDeleteProductSectionItemDelete$Plain(params?: {
    productCostSectionItemId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerDeleteProductSectionItemDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerDeleteProductSectionItemDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerDeleteProductSectionItemDelete$Json$Response(params?: {
    productCostSectionItemId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerDeleteProductSectionItemDeletePath, 'delete');
    if (params) {
      rb.query('productCostSectionItemId', params.productCostSectionItemId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerDeleteProductSectionItemDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerDeleteProductSectionItemDelete$Json(params?: {
    productCostSectionItemId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerDeleteProductSectionItemDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerAddMilestonePost
   */
  static readonly ApiCostManagerAddMilestonePostPath = '/api/CostManager/add-milestone';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerAddMilestonePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddMilestonePost$Plain$Response(params?: {
    body?: CostManagerMilestoneDto
  }): Observable<StrictHttpResponse<CostManagerMilestoneDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerAddMilestonePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CostManagerMilestoneDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerAddMilestonePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddMilestonePost$Plain(params?: {
    body?: CostManagerMilestoneDto
  }): Observable<CostManagerMilestoneDtoBaseResponse> {

    return this.apiCostManagerAddMilestonePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CostManagerMilestoneDtoBaseResponse>) => r.body as CostManagerMilestoneDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerAddMilestonePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddMilestonePost$Json$Response(params?: {
    body?: CostManagerMilestoneDto
  }): Observable<StrictHttpResponse<CostManagerMilestoneDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerAddMilestonePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CostManagerMilestoneDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerAddMilestonePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddMilestonePost$Json(params?: {
    body?: CostManagerMilestoneDto
  }): Observable<CostManagerMilestoneDtoBaseResponse> {

    return this.apiCostManagerAddMilestonePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CostManagerMilestoneDtoBaseResponse>) => r.body as CostManagerMilestoneDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerGetMilestonesPost
   */
  static readonly ApiCostManagerGetMilestonesPostPath = '/api/CostManager/get-milestones';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerGetMilestonesPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerGetMilestonesPost$Plain$Response(params?: {
    costManagerId?: number;
  }): Observable<StrictHttpResponse<CostManagerMilestoneDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerGetMilestonesPostPath, 'post');
    if (params) {
      rb.query('costManagerId', params.costManagerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CostManagerMilestoneDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerGetMilestonesPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerGetMilestonesPost$Plain(params?: {
    costManagerId?: number;
  }): Observable<CostManagerMilestoneDtoListBaseResponse> {

    return this.apiCostManagerGetMilestonesPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CostManagerMilestoneDtoListBaseResponse>) => r.body as CostManagerMilestoneDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerGetMilestonesPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerGetMilestonesPost$Json$Response(params?: {
    costManagerId?: number;
  }): Observable<StrictHttpResponse<CostManagerMilestoneDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerGetMilestonesPostPath, 'post');
    if (params) {
      rb.query('costManagerId', params.costManagerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CostManagerMilestoneDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerGetMilestonesPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerGetMilestonesPost$Json(params?: {
    costManagerId?: number;
  }): Observable<CostManagerMilestoneDtoListBaseResponse> {

    return this.apiCostManagerGetMilestonesPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CostManagerMilestoneDtoListBaseResponse>) => r.body as CostManagerMilestoneDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerUpdateMilestonePost
   */
  static readonly ApiCostManagerUpdateMilestonePostPath = '/api/CostManager/update-milestone';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerUpdateMilestonePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateMilestonePost$Plain$Response(params?: {
    body?: CostManagerMilestoneDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerUpdateMilestonePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerUpdateMilestonePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateMilestonePost$Plain(params?: {
    body?: CostManagerMilestoneDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerUpdateMilestonePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerUpdateMilestonePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateMilestonePost$Json$Response(params?: {
    body?: CostManagerMilestoneDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerUpdateMilestonePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerUpdateMilestonePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateMilestonePost$Json(params?: {
    body?: CostManagerMilestoneDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerUpdateMilestonePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerArchiveMilestoneDelete
   */
  static readonly ApiCostManagerArchiveMilestoneDeletePath = '/api/CostManager/archive-milestone';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerArchiveMilestoneDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerArchiveMilestoneDelete$Plain$Response(params?: {
    costManagerMilestoneId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerArchiveMilestoneDeletePath, 'delete');
    if (params) {
      rb.query('costManagerMilestoneId', params.costManagerMilestoneId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerArchiveMilestoneDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerArchiveMilestoneDelete$Plain(params?: {
    costManagerMilestoneId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerArchiveMilestoneDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerArchiveMilestoneDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerArchiveMilestoneDelete$Json$Response(params?: {
    costManagerMilestoneId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerArchiveMilestoneDeletePath, 'delete');
    if (params) {
      rb.query('costManagerMilestoneId', params.costManagerMilestoneId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerArchiveMilestoneDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerArchiveMilestoneDelete$Json(params?: {
    costManagerMilestoneId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerArchiveMilestoneDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerLongTermForecastDelete
   */
  static readonly ApiCostManagerLongTermForecastDeletePath = '/api/CostManager/long-term-forecast';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerLongTermForecastDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerLongTermForecastDelete$Plain$Response(params?: {
    startDate?: string;
  }): Observable<StrictHttpResponse<LongTermForecastDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerLongTermForecastDeletePath, 'delete');
    if (params) {
      rb.query('startDate', params.startDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LongTermForecastDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerLongTermForecastDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerLongTermForecastDelete$Plain(params?: {
    startDate?: string;
  }): Observable<LongTermForecastDtoBaseResponse> {

    return this.apiCostManagerLongTermForecastDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<LongTermForecastDtoBaseResponse>) => r.body as LongTermForecastDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerLongTermForecastDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerLongTermForecastDelete$Json$Response(params?: {
    startDate?: string;
  }): Observable<StrictHttpResponse<LongTermForecastDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerLongTermForecastDeletePath, 'delete');
    if (params) {
      rb.query('startDate', params.startDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LongTermForecastDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerLongTermForecastDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerLongTermForecastDelete$Json(params?: {
    startDate?: string;
  }): Observable<LongTermForecastDtoBaseResponse> {

    return this.apiCostManagerLongTermForecastDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LongTermForecastDtoBaseResponse>) => r.body as LongTermForecastDtoBaseResponse)
    );
  }

}
