/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanBaseResponse } from '../models/boolean-base-response';
import { ReleaseNotesDtoArrayBaseResponse } from '../models/release-notes-dto-array-base-response';
import { ReleaseNotesDtoBaseResponse } from '../models/release-notes-dto-base-response';
import { StringBaseResponse } from '../models/string-base-response';

@Injectable({
  providedIn: 'root',
})
export class GithubService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiGithubGetReleaseNotesForRepoGet
   */
  static readonly ApiGithubGetReleaseNotesForRepoGetPath = '/api/Github/get-release-notes-for-repo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiGithubGetReleaseNotesForRepoGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiGithubGetReleaseNotesForRepoGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<ReleaseNotesDtoArrayBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, GithubService.ApiGithubGetReleaseNotesForRepoGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReleaseNotesDtoArrayBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiGithubGetReleaseNotesForRepoGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiGithubGetReleaseNotesForRepoGet$Plain(params?: {
  }): Observable<ReleaseNotesDtoArrayBaseResponse> {

    return this.apiGithubGetReleaseNotesForRepoGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReleaseNotesDtoArrayBaseResponse>) => r.body as ReleaseNotesDtoArrayBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiGithubGetReleaseNotesForRepoGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiGithubGetReleaseNotesForRepoGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<ReleaseNotesDtoArrayBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, GithubService.ApiGithubGetReleaseNotesForRepoGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReleaseNotesDtoArrayBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiGithubGetReleaseNotesForRepoGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiGithubGetReleaseNotesForRepoGet$Json(params?: {
  }): Observable<ReleaseNotesDtoArrayBaseResponse> {

    return this.apiGithubGetReleaseNotesForRepoGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReleaseNotesDtoArrayBaseResponse>) => r.body as ReleaseNotesDtoArrayBaseResponse)
    );
  }

  /**
   * Path part for operation apiGithubGetLatestReleaseVersionGet
   */
  static readonly ApiGithubGetLatestReleaseVersionGetPath = '/api/Github/get-latest-release-version';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiGithubGetLatestReleaseVersionGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiGithubGetLatestReleaseVersionGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<StringBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, GithubService.ApiGithubGetLatestReleaseVersionGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiGithubGetLatestReleaseVersionGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiGithubGetLatestReleaseVersionGet$Plain(params?: {
  }): Observable<StringBaseResponse> {

    return this.apiGithubGetLatestReleaseVersionGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringBaseResponse>) => r.body as StringBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiGithubGetLatestReleaseVersionGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiGithubGetLatestReleaseVersionGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<StringBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, GithubService.ApiGithubGetLatestReleaseVersionGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiGithubGetLatestReleaseVersionGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiGithubGetLatestReleaseVersionGet$Json(params?: {
  }): Observable<StringBaseResponse> {

    return this.apiGithubGetLatestReleaseVersionGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringBaseResponse>) => r.body as StringBaseResponse)
    );
  }

  /**
   * Path part for operation apiGithubGetLatestReleaseNoteGet
   */
  static readonly ApiGithubGetLatestReleaseNoteGetPath = '/api/Github/get-latest-release-note';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiGithubGetLatestReleaseNoteGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiGithubGetLatestReleaseNoteGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<ReleaseNotesDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, GithubService.ApiGithubGetLatestReleaseNoteGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReleaseNotesDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiGithubGetLatestReleaseNoteGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiGithubGetLatestReleaseNoteGet$Plain(params?: {
  }): Observable<ReleaseNotesDtoBaseResponse> {

    return this.apiGithubGetLatestReleaseNoteGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReleaseNotesDtoBaseResponse>) => r.body as ReleaseNotesDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiGithubGetLatestReleaseNoteGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiGithubGetLatestReleaseNoteGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<ReleaseNotesDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, GithubService.ApiGithubGetLatestReleaseNoteGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReleaseNotesDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiGithubGetLatestReleaseNoteGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiGithubGetLatestReleaseNoteGet$Json(params?: {
  }): Observable<ReleaseNotesDtoBaseResponse> {

    return this.apiGithubGetLatestReleaseNoteGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReleaseNotesDtoBaseResponse>) => r.body as ReleaseNotesDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiGithubHasUserSeenReleaseGet
   */
  static readonly ApiGithubHasUserSeenReleaseGetPath = '/api/Github/has-user-seen-release';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiGithubHasUserSeenReleaseGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiGithubHasUserSeenReleaseGet$Plain$Response(params?: {
    userId?: number;
    latestReleaseName?: string;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, GithubService.ApiGithubHasUserSeenReleaseGetPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
      rb.query('latestReleaseName', params.latestReleaseName, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiGithubHasUserSeenReleaseGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiGithubHasUserSeenReleaseGet$Plain(params?: {
    userId?: number;
    latestReleaseName?: string;
  }): Observable<BooleanBaseResponse> {

    return this.apiGithubHasUserSeenReleaseGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiGithubHasUserSeenReleaseGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiGithubHasUserSeenReleaseGet$Json$Response(params?: {
    userId?: number;
    latestReleaseName?: string;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, GithubService.ApiGithubHasUserSeenReleaseGetPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
      rb.query('latestReleaseName', params.latestReleaseName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiGithubHasUserSeenReleaseGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiGithubHasUserSeenReleaseGet$Json(params?: {
    userId?: number;
    latestReleaseName?: string;
  }): Observable<BooleanBaseResponse> {

    return this.apiGithubHasUserSeenReleaseGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

}
