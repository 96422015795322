/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanBaseResponse } from '../models/boolean-base-response';
import { TimeLogDto } from '../models/time-log-dto';
import { TimeLogListDtoListBaseResponse } from '../models/time-log-list-dto-list-base-response';
import { TimePeriod } from '../models/time-period';
import { WorkTaskDtoBaseResponse } from '../models/work-task-dto-base-response';
import { WorkTaskListDto } from '../models/work-task-list-dto';
import { WorkTaskListDtoBaseResponse } from '../models/work-task-list-dto-base-response';
import { WorkTaskListDtoListBaseResponse } from '../models/work-task-list-dto-list-base-response';
import { WorkloadMilestoneListDtoListBaseResponse } from '../models/workload-milestone-list-dto-list-base-response';

@Injectable({
  providedIn: 'root',
})
export class WorkloadService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiWorkloadTasksInRangeGet
   */
  static readonly ApiWorkloadTasksInRangeGetPath = '/api/Workload/tasks-in-range';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkloadTasksInRangeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadTasksInRangeGet$Plain$Response(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<StrictHttpResponse<WorkTaskListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WorkloadService.ApiWorkloadTasksInRangeGetPath, 'get');
    if (params) {
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WorkTaskListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkloadTasksInRangeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadTasksInRangeGet$Plain(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<WorkTaskListDtoListBaseResponse> {

    return this.apiWorkloadTasksInRangeGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<WorkTaskListDtoListBaseResponse>) => r.body as WorkTaskListDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkloadTasksInRangeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadTasksInRangeGet$Json$Response(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<StrictHttpResponse<WorkTaskListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WorkloadService.ApiWorkloadTasksInRangeGetPath, 'get');
    if (params) {
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WorkTaskListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkloadTasksInRangeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadTasksInRangeGet$Json(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<WorkTaskListDtoListBaseResponse> {

    return this.apiWorkloadTasksInRangeGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<WorkTaskListDtoListBaseResponse>) => r.body as WorkTaskListDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiWorkloadTasksForProjectGet
   */
  static readonly ApiWorkloadTasksForProjectGetPath = '/api/Workload/tasks-for-project';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkloadTasksForProjectGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadTasksForProjectGet$Plain$Response(params?: {
    projectId?: number;
  }): Observable<StrictHttpResponse<WorkTaskListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WorkloadService.ApiWorkloadTasksForProjectGetPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WorkTaskListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkloadTasksForProjectGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadTasksForProjectGet$Plain(params?: {
    projectId?: number;
  }): Observable<WorkTaskListDtoListBaseResponse> {

    return this.apiWorkloadTasksForProjectGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<WorkTaskListDtoListBaseResponse>) => r.body as WorkTaskListDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkloadTasksForProjectGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadTasksForProjectGet$Json$Response(params?: {
    projectId?: number;
  }): Observable<StrictHttpResponse<WorkTaskListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WorkloadService.ApiWorkloadTasksForProjectGetPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WorkTaskListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkloadTasksForProjectGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadTasksForProjectGet$Json(params?: {
    projectId?: number;
  }): Observable<WorkTaskListDtoListBaseResponse> {

    return this.apiWorkloadTasksForProjectGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<WorkTaskListDtoListBaseResponse>) => r.body as WorkTaskListDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiWorkloadTasksForUserGet
   */
  static readonly ApiWorkloadTasksForUserGetPath = '/api/Workload/tasks-for-user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkloadTasksForUserGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadTasksForUserGet$Plain$Response(params?: {
    userId?: number;
    from?: string;
    to?: string;
  }): Observable<StrictHttpResponse<WorkTaskListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WorkloadService.ApiWorkloadTasksForUserGetPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
      rb.query('from', params.from, {});
      rb.query('to', params.to, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WorkTaskListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkloadTasksForUserGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadTasksForUserGet$Plain(params?: {
    userId?: number;
    from?: string;
    to?: string;
  }): Observable<WorkTaskListDtoListBaseResponse> {

    return this.apiWorkloadTasksForUserGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<WorkTaskListDtoListBaseResponse>) => r.body as WorkTaskListDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkloadTasksForUserGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadTasksForUserGet$Json$Response(params?: {
    userId?: number;
    from?: string;
    to?: string;
  }): Observable<StrictHttpResponse<WorkTaskListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WorkloadService.ApiWorkloadTasksForUserGetPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
      rb.query('from', params.from, {});
      rb.query('to', params.to, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WorkTaskListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkloadTasksForUserGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadTasksForUserGet$Json(params?: {
    userId?: number;
    from?: string;
    to?: string;
  }): Observable<WorkTaskListDtoListBaseResponse> {

    return this.apiWorkloadTasksForUserGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<WorkTaskListDtoListBaseResponse>) => r.body as WorkTaskListDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiWorkloadTaskGet
   */
  static readonly ApiWorkloadTaskGetPath = '/api/Workload/task';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkloadTaskGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadTaskGet$Plain$Response(params?: {
    workTaskId?: number;
  }): Observable<StrictHttpResponse<WorkTaskDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WorkloadService.ApiWorkloadTaskGetPath, 'get');
    if (params) {
      rb.query('workTaskId', params.workTaskId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WorkTaskDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkloadTaskGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadTaskGet$Plain(params?: {
    workTaskId?: number;
  }): Observable<WorkTaskDtoBaseResponse> {

    return this.apiWorkloadTaskGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<WorkTaskDtoBaseResponse>) => r.body as WorkTaskDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkloadTaskGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadTaskGet$Json$Response(params?: {
    workTaskId?: number;
  }): Observable<StrictHttpResponse<WorkTaskDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WorkloadService.ApiWorkloadTaskGetPath, 'get');
    if (params) {
      rb.query('workTaskId', params.workTaskId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WorkTaskDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkloadTaskGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadTaskGet$Json(params?: {
    workTaskId?: number;
  }): Observable<WorkTaskDtoBaseResponse> {

    return this.apiWorkloadTaskGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<WorkTaskDtoBaseResponse>) => r.body as WorkTaskDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiWorkloadCreateTaskPost
   */
  static readonly ApiWorkloadCreateTaskPostPath = '/api/Workload/create-task';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkloadCreateTaskPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWorkloadCreateTaskPost$Plain$Response(params?: {
    body?: WorkTaskListDto
  }): Observable<StrictHttpResponse<WorkTaskListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WorkloadService.ApiWorkloadCreateTaskPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WorkTaskListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkloadCreateTaskPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWorkloadCreateTaskPost$Plain(params?: {
    body?: WorkTaskListDto
  }): Observable<WorkTaskListDtoBaseResponse> {

    return this.apiWorkloadCreateTaskPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<WorkTaskListDtoBaseResponse>) => r.body as WorkTaskListDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkloadCreateTaskPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWorkloadCreateTaskPost$Json$Response(params?: {
    body?: WorkTaskListDto
  }): Observable<StrictHttpResponse<WorkTaskListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WorkloadService.ApiWorkloadCreateTaskPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WorkTaskListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkloadCreateTaskPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWorkloadCreateTaskPost$Json(params?: {
    body?: WorkTaskListDto
  }): Observable<WorkTaskListDtoBaseResponse> {

    return this.apiWorkloadCreateTaskPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<WorkTaskListDtoBaseResponse>) => r.body as WorkTaskListDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiWorkloadUpdateTaskPut
   */
  static readonly ApiWorkloadUpdateTaskPutPath = '/api/Workload/update-task';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkloadUpdateTaskPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWorkloadUpdateTaskPut$Plain$Response(params?: {
    body?: WorkTaskListDto
  }): Observable<StrictHttpResponse<WorkTaskListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WorkloadService.ApiWorkloadUpdateTaskPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WorkTaskListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkloadUpdateTaskPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWorkloadUpdateTaskPut$Plain(params?: {
    body?: WorkTaskListDto
  }): Observable<WorkTaskListDtoBaseResponse> {

    return this.apiWorkloadUpdateTaskPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<WorkTaskListDtoBaseResponse>) => r.body as WorkTaskListDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkloadUpdateTaskPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWorkloadUpdateTaskPut$Json$Response(params?: {
    body?: WorkTaskListDto
  }): Observable<StrictHttpResponse<WorkTaskListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WorkloadService.ApiWorkloadUpdateTaskPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WorkTaskListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkloadUpdateTaskPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWorkloadUpdateTaskPut$Json(params?: {
    body?: WorkTaskListDto
  }): Observable<WorkTaskListDtoBaseResponse> {

    return this.apiWorkloadUpdateTaskPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<WorkTaskListDtoBaseResponse>) => r.body as WorkTaskListDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiWorkloadUpdateTaskCompletedPost
   */
  static readonly ApiWorkloadUpdateTaskCompletedPostPath = '/api/Workload/update-task-completed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkloadUpdateTaskCompletedPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadUpdateTaskCompletedPost$Plain$Response(params?: {
    workTaskId?: number;
    userId?: number;
    completed?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WorkloadService.ApiWorkloadUpdateTaskCompletedPostPath, 'post');
    if (params) {
      rb.query('workTaskId', params.workTaskId, {});
      rb.query('userId', params.userId, {});
      rb.query('completed', params.completed, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkloadUpdateTaskCompletedPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadUpdateTaskCompletedPost$Plain(params?: {
    workTaskId?: number;
    userId?: number;
    completed?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiWorkloadUpdateTaskCompletedPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkloadUpdateTaskCompletedPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadUpdateTaskCompletedPost$Json$Response(params?: {
    workTaskId?: number;
    userId?: number;
    completed?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WorkloadService.ApiWorkloadUpdateTaskCompletedPostPath, 'post');
    if (params) {
      rb.query('workTaskId', params.workTaskId, {});
      rb.query('userId', params.userId, {});
      rb.query('completed', params.completed, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkloadUpdateTaskCompletedPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadUpdateTaskCompletedPost$Json(params?: {
    workTaskId?: number;
    userId?: number;
    completed?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiWorkloadUpdateTaskCompletedPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiWorkloadArchiveTaskDelete
   */
  static readonly ApiWorkloadArchiveTaskDeletePath = '/api/Workload/archive-task';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkloadArchiveTaskDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadArchiveTaskDelete$Plain$Response(params?: {
    workTaskId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WorkloadService.ApiWorkloadArchiveTaskDeletePath, 'delete');
    if (params) {
      rb.query('workTaskId', params.workTaskId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkloadArchiveTaskDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadArchiveTaskDelete$Plain(params?: {
    workTaskId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiWorkloadArchiveTaskDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkloadArchiveTaskDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadArchiveTaskDelete$Json$Response(params?: {
    workTaskId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WorkloadService.ApiWorkloadArchiveTaskDeletePath, 'delete');
    if (params) {
      rb.query('workTaskId', params.workTaskId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkloadArchiveTaskDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadArchiveTaskDelete$Json(params?: {
    workTaskId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiWorkloadArchiveTaskDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiWorkloadTimeLogsForProjectGet
   */
  static readonly ApiWorkloadTimeLogsForProjectGetPath = '/api/Workload/time-logs-for-project';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkloadTimeLogsForProjectGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadTimeLogsForProjectGet$Plain$Response(params?: {
    projectId?: number;
  }): Observable<StrictHttpResponse<TimeLogListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WorkloadService.ApiWorkloadTimeLogsForProjectGetPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TimeLogListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkloadTimeLogsForProjectGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadTimeLogsForProjectGet$Plain(params?: {
    projectId?: number;
  }): Observable<TimeLogListDtoListBaseResponse> {

    return this.apiWorkloadTimeLogsForProjectGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TimeLogListDtoListBaseResponse>) => r.body as TimeLogListDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkloadTimeLogsForProjectGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadTimeLogsForProjectGet$Json$Response(params?: {
    projectId?: number;
  }): Observable<StrictHttpResponse<TimeLogListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WorkloadService.ApiWorkloadTimeLogsForProjectGetPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TimeLogListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkloadTimeLogsForProjectGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadTimeLogsForProjectGet$Json(params?: {
    projectId?: number;
  }): Observable<TimeLogListDtoListBaseResponse> {

    return this.apiWorkloadTimeLogsForProjectGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TimeLogListDtoListBaseResponse>) => r.body as TimeLogListDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiWorkloadTimeLogsForUserGet
   */
  static readonly ApiWorkloadTimeLogsForUserGetPath = '/api/Workload/time-logs-for-user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkloadTimeLogsForUserGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadTimeLogsForUserGet$Plain$Response(params?: {
    userId?: number;
    from?: string;
    to?: string;
  }): Observable<StrictHttpResponse<TimeLogListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WorkloadService.ApiWorkloadTimeLogsForUserGetPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
      rb.query('from', params.from, {});
      rb.query('to', params.to, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TimeLogListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkloadTimeLogsForUserGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadTimeLogsForUserGet$Plain(params?: {
    userId?: number;
    from?: string;
    to?: string;
  }): Observable<TimeLogListDtoListBaseResponse> {

    return this.apiWorkloadTimeLogsForUserGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TimeLogListDtoListBaseResponse>) => r.body as TimeLogListDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkloadTimeLogsForUserGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadTimeLogsForUserGet$Json$Response(params?: {
    userId?: number;
    from?: string;
    to?: string;
  }): Observable<StrictHttpResponse<TimeLogListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WorkloadService.ApiWorkloadTimeLogsForUserGetPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
      rb.query('from', params.from, {});
      rb.query('to', params.to, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TimeLogListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkloadTimeLogsForUserGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadTimeLogsForUserGet$Json(params?: {
    userId?: number;
    from?: string;
    to?: string;
  }): Observable<TimeLogListDtoListBaseResponse> {

    return this.apiWorkloadTimeLogsForUserGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TimeLogListDtoListBaseResponse>) => r.body as TimeLogListDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiWorkloadTimeLogsForPeriodGet
   */
  static readonly ApiWorkloadTimeLogsForPeriodGetPath = '/api/Workload/time-logs-for-period';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkloadTimeLogsForPeriodGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadTimeLogsForPeriodGet$Plain$Response(params?: {

    /**
     * 0 &#x3D; Day
     * 
     * 1 &#x3D; Week
     * 
     * 2 &#x3D; Month
     */
    timePeriod?: TimePeriod;
  }): Observable<StrictHttpResponse<TimeLogListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WorkloadService.ApiWorkloadTimeLogsForPeriodGetPath, 'get');
    if (params) {
      rb.query('timePeriod', params.timePeriod, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TimeLogListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkloadTimeLogsForPeriodGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadTimeLogsForPeriodGet$Plain(params?: {

    /**
     * 0 &#x3D; Day
     * 
     * 1 &#x3D; Week
     * 
     * 2 &#x3D; Month
     */
    timePeriod?: TimePeriod;
  }): Observable<TimeLogListDtoListBaseResponse> {

    return this.apiWorkloadTimeLogsForPeriodGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TimeLogListDtoListBaseResponse>) => r.body as TimeLogListDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkloadTimeLogsForPeriodGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadTimeLogsForPeriodGet$Json$Response(params?: {

    /**
     * 0 &#x3D; Day
     * 
     * 1 &#x3D; Week
     * 
     * 2 &#x3D; Month
     */
    timePeriod?: TimePeriod;
  }): Observable<StrictHttpResponse<TimeLogListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WorkloadService.ApiWorkloadTimeLogsForPeriodGetPath, 'get');
    if (params) {
      rb.query('timePeriod', params.timePeriod, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TimeLogListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkloadTimeLogsForPeriodGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadTimeLogsForPeriodGet$Json(params?: {

    /**
     * 0 &#x3D; Day
     * 
     * 1 &#x3D; Week
     * 
     * 2 &#x3D; Month
     */
    timePeriod?: TimePeriod;
  }): Observable<TimeLogListDtoListBaseResponse> {

    return this.apiWorkloadTimeLogsForPeriodGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TimeLogListDtoListBaseResponse>) => r.body as TimeLogListDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiWorkloadTimeLogsForTasksGet
   */
  static readonly ApiWorkloadTimeLogsForTasksGetPath = '/api/Workload/time-logs-for-tasks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkloadTimeLogsForTasksGet$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWorkloadTimeLogsForTasksGet$Plain$Response(params?: {
    body?: Array<number>
  }): Observable<StrictHttpResponse<TimeLogListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WorkloadService.ApiWorkloadTimeLogsForTasksGetPath, 'get');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TimeLogListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkloadTimeLogsForTasksGet$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWorkloadTimeLogsForTasksGet$Plain(params?: {
    body?: Array<number>
  }): Observable<TimeLogListDtoListBaseResponse> {

    return this.apiWorkloadTimeLogsForTasksGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TimeLogListDtoListBaseResponse>) => r.body as TimeLogListDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkloadTimeLogsForTasksGet$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWorkloadTimeLogsForTasksGet$Json$Response(params?: {
    body?: Array<number>
  }): Observable<StrictHttpResponse<TimeLogListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WorkloadService.ApiWorkloadTimeLogsForTasksGetPath, 'get');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TimeLogListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkloadTimeLogsForTasksGet$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWorkloadTimeLogsForTasksGet$Json(params?: {
    body?: Array<number>
  }): Observable<TimeLogListDtoListBaseResponse> {

    return this.apiWorkloadTimeLogsForTasksGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TimeLogListDtoListBaseResponse>) => r.body as TimeLogListDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiWorkloadAddTimeLogPost
   */
  static readonly ApiWorkloadAddTimeLogPostPath = '/api/Workload/add-time-log';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkloadAddTimeLogPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWorkloadAddTimeLogPost$Plain$Response(params?: {
    body?: TimeLogDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WorkloadService.ApiWorkloadAddTimeLogPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkloadAddTimeLogPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWorkloadAddTimeLogPost$Plain(params?: {
    body?: TimeLogDto
  }): Observable<BooleanBaseResponse> {

    return this.apiWorkloadAddTimeLogPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkloadAddTimeLogPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWorkloadAddTimeLogPost$Json$Response(params?: {
    body?: TimeLogDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WorkloadService.ApiWorkloadAddTimeLogPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkloadAddTimeLogPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWorkloadAddTimeLogPost$Json(params?: {
    body?: TimeLogDto
  }): Observable<BooleanBaseResponse> {

    return this.apiWorkloadAddTimeLogPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiWorkloadUpdateTimeLogPut
   */
  static readonly ApiWorkloadUpdateTimeLogPutPath = '/api/Workload/update-time-log';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkloadUpdateTimeLogPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWorkloadUpdateTimeLogPut$Plain$Response(params?: {
    body?: TimeLogDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WorkloadService.ApiWorkloadUpdateTimeLogPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkloadUpdateTimeLogPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWorkloadUpdateTimeLogPut$Plain(params?: {
    body?: TimeLogDto
  }): Observable<BooleanBaseResponse> {

    return this.apiWorkloadUpdateTimeLogPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkloadUpdateTimeLogPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWorkloadUpdateTimeLogPut$Json$Response(params?: {
    body?: TimeLogDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WorkloadService.ApiWorkloadUpdateTimeLogPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkloadUpdateTimeLogPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWorkloadUpdateTimeLogPut$Json(params?: {
    body?: TimeLogDto
  }): Observable<BooleanBaseResponse> {

    return this.apiWorkloadUpdateTimeLogPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiWorkloadArchiveTimeLogDelete
   */
  static readonly ApiWorkloadArchiveTimeLogDeletePath = '/api/Workload/archive-time-log';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkloadArchiveTimeLogDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadArchiveTimeLogDelete$Plain$Response(params?: {
    timeLogId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WorkloadService.ApiWorkloadArchiveTimeLogDeletePath, 'delete');
    if (params) {
      rb.query('timeLogId', params.timeLogId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkloadArchiveTimeLogDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadArchiveTimeLogDelete$Plain(params?: {
    timeLogId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiWorkloadArchiveTimeLogDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkloadArchiveTimeLogDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadArchiveTimeLogDelete$Json$Response(params?: {
    timeLogId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WorkloadService.ApiWorkloadArchiveTimeLogDeletePath, 'delete');
    if (params) {
      rb.query('timeLogId', params.timeLogId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkloadArchiveTimeLogDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadArchiveTimeLogDelete$Json(params?: {
    timeLogId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiWorkloadArchiveTimeLogDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiWorkloadWorkloadMilestonesToShowGet
   */
  static readonly ApiWorkloadWorkloadMilestonesToShowGetPath = '/api/Workload/workload-milestones-to-show';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkloadWorkloadMilestonesToShowGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadWorkloadMilestonesToShowGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<WorkloadMilestoneListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WorkloadService.ApiWorkloadWorkloadMilestonesToShowGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WorkloadMilestoneListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkloadWorkloadMilestonesToShowGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadWorkloadMilestonesToShowGet$Plain(params?: {
  }): Observable<WorkloadMilestoneListDtoListBaseResponse> {

    return this.apiWorkloadWorkloadMilestonesToShowGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<WorkloadMilestoneListDtoListBaseResponse>) => r.body as WorkloadMilestoneListDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkloadWorkloadMilestonesToShowGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadWorkloadMilestonesToShowGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<WorkloadMilestoneListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WorkloadService.ApiWorkloadWorkloadMilestonesToShowGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WorkloadMilestoneListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkloadWorkloadMilestonesToShowGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkloadWorkloadMilestonesToShowGet$Json(params?: {
  }): Observable<WorkloadMilestoneListDtoListBaseResponse> {

    return this.apiWorkloadWorkloadMilestonesToShowGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<WorkloadMilestoneListDtoListBaseResponse>) => r.body as WorkloadMilestoneListDtoListBaseResponse)
    );
  }

}
