/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanBaseResponse } from '../models/boolean-base-response';
import { ScheduleEventDto } from '../models/schedule-event-dto';
import { ScheduleEventDtoListBaseResponse } from '../models/schedule-event-dto-list-base-response';

@Injectable({
  providedIn: 'root',
})
export class ScheduleService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiScheduleUserEventsGet
   */
  static readonly ApiScheduleUserEventsGetPath = '/api/Schedule/user-events';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScheduleUserEventsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScheduleUserEventsGet$Plain$Response(params?: {
    userId?: number;
  }): Observable<StrictHttpResponse<ScheduleEventDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScheduleService.ApiScheduleUserEventsGetPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScheduleEventDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScheduleUserEventsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScheduleUserEventsGet$Plain(params?: {
    userId?: number;
  }): Observable<ScheduleEventDtoListBaseResponse> {

    return this.apiScheduleUserEventsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ScheduleEventDtoListBaseResponse>) => r.body as ScheduleEventDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScheduleUserEventsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScheduleUserEventsGet$Json$Response(params?: {
    userId?: number;
  }): Observable<StrictHttpResponse<ScheduleEventDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScheduleService.ApiScheduleUserEventsGetPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScheduleEventDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScheduleUserEventsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScheduleUserEventsGet$Json(params?: {
    userId?: number;
  }): Observable<ScheduleEventDtoListBaseResponse> {

    return this.apiScheduleUserEventsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ScheduleEventDtoListBaseResponse>) => r.body as ScheduleEventDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiScheduleAddEventPost
   */
  static readonly ApiScheduleAddEventPostPath = '/api/Schedule/add-event';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScheduleAddEventPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScheduleAddEventPost$Plain$Response(params?: {
    body?: ScheduleEventDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScheduleService.ApiScheduleAddEventPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScheduleAddEventPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScheduleAddEventPost$Plain(params?: {
    body?: ScheduleEventDto
  }): Observable<BooleanBaseResponse> {

    return this.apiScheduleAddEventPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScheduleAddEventPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScheduleAddEventPost$Json$Response(params?: {
    body?: ScheduleEventDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScheduleService.ApiScheduleAddEventPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScheduleAddEventPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScheduleAddEventPost$Json(params?: {
    body?: ScheduleEventDto
  }): Observable<BooleanBaseResponse> {

    return this.apiScheduleAddEventPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiScheduleAddListPost
   */
  static readonly ApiScheduleAddListPostPath = '/api/Schedule/add-list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScheduleAddListPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScheduleAddListPost$Plain$Response(params?: {
    body?: Array<ScheduleEventDto>
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScheduleService.ApiScheduleAddListPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScheduleAddListPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScheduleAddListPost$Plain(params?: {
    body?: Array<ScheduleEventDto>
  }): Observable<BooleanBaseResponse> {

    return this.apiScheduleAddListPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScheduleAddListPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScheduleAddListPost$Json$Response(params?: {
    body?: Array<ScheduleEventDto>
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScheduleService.ApiScheduleAddListPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScheduleAddListPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScheduleAddListPost$Json(params?: {
    body?: Array<ScheduleEventDto>
  }): Observable<BooleanBaseResponse> {

    return this.apiScheduleAddListPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiScheduleUpdateEventPost
   */
  static readonly ApiScheduleUpdateEventPostPath = '/api/Schedule/update-event';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScheduleUpdateEventPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScheduleUpdateEventPost$Plain$Response(params?: {
    body?: ScheduleEventDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScheduleService.ApiScheduleUpdateEventPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScheduleUpdateEventPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScheduleUpdateEventPost$Plain(params?: {
    body?: ScheduleEventDto
  }): Observable<BooleanBaseResponse> {

    return this.apiScheduleUpdateEventPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScheduleUpdateEventPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScheduleUpdateEventPost$Json$Response(params?: {
    body?: ScheduleEventDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScheduleService.ApiScheduleUpdateEventPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScheduleUpdateEventPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScheduleUpdateEventPost$Json(params?: {
    body?: ScheduleEventDto
  }): Observable<BooleanBaseResponse> {

    return this.apiScheduleUpdateEventPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiScheduleUpdateListPost
   */
  static readonly ApiScheduleUpdateListPostPath = '/api/Schedule/update-list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScheduleUpdateListPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScheduleUpdateListPost$Plain$Response(params?: {
    body?: Array<ScheduleEventDto>
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScheduleService.ApiScheduleUpdateListPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScheduleUpdateListPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScheduleUpdateListPost$Plain(params?: {
    body?: Array<ScheduleEventDto>
  }): Observable<BooleanBaseResponse> {

    return this.apiScheduleUpdateListPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScheduleUpdateListPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScheduleUpdateListPost$Json$Response(params?: {
    body?: Array<ScheduleEventDto>
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScheduleService.ApiScheduleUpdateListPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScheduleUpdateListPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScheduleUpdateListPost$Json(params?: {
    body?: Array<ScheduleEventDto>
  }): Observable<BooleanBaseResponse> {

    return this.apiScheduleUpdateListPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiScheduleDeleteEventPost
   */
  static readonly ApiScheduleDeleteEventPostPath = '/api/Schedule/delete-event';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScheduleDeleteEventPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScheduleDeleteEventPost$Plain$Response(params?: {
    scheduleEventId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScheduleService.ApiScheduleDeleteEventPostPath, 'post');
    if (params) {
      rb.query('scheduleEventId', params.scheduleEventId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScheduleDeleteEventPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScheduleDeleteEventPost$Plain(params?: {
    scheduleEventId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiScheduleDeleteEventPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScheduleDeleteEventPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScheduleDeleteEventPost$Json$Response(params?: {
    scheduleEventId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScheduleService.ApiScheduleDeleteEventPostPath, 'post');
    if (params) {
      rb.query('scheduleEventId', params.scheduleEventId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScheduleDeleteEventPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScheduleDeleteEventPost$Json(params?: {
    scheduleEventId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiScheduleDeleteEventPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiScheduleDeleteListPost
   */
  static readonly ApiScheduleDeleteListPostPath = '/api/Schedule/delete-list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScheduleDeleteListPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScheduleDeleteListPost$Plain$Response(params?: {
    body?: Array<number>
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScheduleService.ApiScheduleDeleteListPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScheduleDeleteListPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScheduleDeleteListPost$Plain(params?: {
    body?: Array<number>
  }): Observable<BooleanBaseResponse> {

    return this.apiScheduleDeleteListPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScheduleDeleteListPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScheduleDeleteListPost$Json$Response(params?: {
    body?: Array<number>
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScheduleService.ApiScheduleDeleteListPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScheduleDeleteListPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScheduleDeleteListPost$Json(params?: {
    body?: Array<number>
  }): Observable<BooleanBaseResponse> {

    return this.apiScheduleDeleteListPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

}
