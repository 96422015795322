import { environment } from 'environments/environment';
import { registerLicense } from '@syncfusion/ej2-base';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from 'app/app.module';
// import { AppComponent } from 'app/app.component';
// import { appConfig } from 'app/app.config';
// import { bootstrapApplication } from '@angular/platform-browser';

// register syncfusion api key here
registerLicense(environment.syncFusionLicense);

// bootstrapApplication(AppComponent, appConfig)
//     .catch(err => console.error(err));

if (environment.production) {
    enableProdMode();
  }
  
platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));
