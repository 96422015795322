/* tslint:disable */
/* eslint-disable */

/**
 * 0 = CRM_Contacts
 * 
 * 1 = CRM_Leads
 * 
 * 2 = CRM_Pipeline
 * 
 * 3 = CRM_Emails
 * 
 * 4 = CRM_Reporting
 * 
 * 5 = PM_Dashboard
 * 
 * 6 = PM_Projects
 * 
 * 7 = PM_Workload
 * 
 * 8 = PM_Invoices
 * 
 * 9 = PM_PurchaseOrders
 * 
 * 10 = PM_Reporting
 * 
 * 11 = ERP_Contacts
 * 
 * 12 = ERP_Products
 * 
 * 13 = ERP_Sales
 * 
 * 14 = ERP_HR
 * 
 * 15 = ERP_Purchases
 * 
 * 16 = ERP_Invoicing
 * 
 * 17 = ERP_GRN
 * 
 * 18 = ERP_Production
 * 
 * 19 = ERP_Leads
 * 
 * 20 = ERP_Pipeline
 * 
 * 21 = ERP_Suppliers
 * 
 * 22 = ERP_Reporting
 * 
 * 23 = Inst_Contacts
 * 
 * 24 = Inst_Products
 * 
 * 25 = Inst_Suppliers
 * 
 * 26 = Inst_PurchaseOrders
 * 
 * 27 = Inst_Invoicing
 * 
 * 28 = Inst_Calendar
 * 
 * 29 = Inst_Documentations
 * 
 * 30 = ECom_Inventory
 * 
 * 31 = ECom_Stock
 * 
 * 32 = ECom_History
 * 
 * 33 = Misc_Calendar
 * 
 * 34 = Misc_Mailbox
 * 
 * 35 = Misc_Messaging
 * 
 * 36 = Misc_CoursesAndTraining
 * 
 * 37 = Misc_TaskList
 * 
 * 38 = Misc_FileManagement
 * 
 * 39 = Form_Quotation
 * 
 * 40 = Task_StockDates
 */
export enum Feature {
  CRM_Contacts = 0,
  CRM_Leads = 1,
  CRM_Pipeline = 2,
  CRM_Emails = 3,
  CRM_Reporting = 4,
  PM_Dashboard = 5,
  PM_Projects = 6,
  PM_Workload = 7,
  PM_Invoices = 8,
  PM_PurchaseOrders = 9,
  PM_Reporting = 10,
  ERP_Contacts = 11,
  ERP_Products = 12,
  ERP_Sales = 13,
  ERP_HR = 14,
  ERP_Purchases = 15,
  ERP_Invoicing = 16,
  ERP_GRN = 17,
  ERP_Production = 18,
  ERP_Leads = 19,
  ERP_Pipeline = 20,
  ERP_Suppliers = 21,
  ERP_Reporting = 22,
  Inst_Contacts = 23,
  Inst_Products = 24,
  Inst_Suppliers = 25,
  Inst_PurchaseOrders = 26,
  Inst_Invoicing = 27,
  Inst_Calendar = 28,
  Inst_Documentations = 29,
  ECom_Inventory = 30,
  ECom_Stock = 31,
  ECom_History = 32,
  Misc_Calendar = 33,
  Misc_Mailbox = 34,
  Misc_Messaging = 35,
  Misc_CoursesAndTraining = 36,
  Misc_TaskList = 37,
  Misc_FileManagement = 38,
  Form_Quotation = 39,
  Task_StockDates = 40
}
